import BitrixUserContext from '@context/useBitrixUser/index';
import { authService } from '@services/AuthService';
import { BitrixUser } from '@services/AuthService/loadUserQuery';
import getEnv from '@settings/getEnv';
import Cookies from 'cookies-ts';
import React, { PropsWithChildren, useEffect, useState } from 'react';

const LOCALSTORAGE_USER_KEY = '__BITRIX_USER_ID__';

/**
 * Функция открытия окна логина на встраиваемом сайте
 */
const showLogin = () => {
  window.dispatchEvent(new CustomEvent('openAuth'));

  const authLink = document.getElementById('auth_link');
  if (!authLink) {
    return;
  }

  authLink.click();
};

const BitrixAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const [bitrixUser, setUser] = useState<BitrixUser | null>(null);

  useEffect(() => {
    const cookies = new Cookies();
    const env = getEnv();
    const jwt = cookies.get(env.REACT_APP_AUTH_COOKIE);

    if (!jwt || jwt.length === 0) {
      return;
    }

    authService()
      .LoadUser(jwt)
      .then((user) => {
        const savedBitrixUserId = localStorage.getItem(LOCALSTORAGE_USER_KEY);
        const userId = user.id;

        setUser(user);
        if (savedBitrixUserId !== user.id) {
          localStorage.setItem(LOCALSTORAGE_USER_KEY, userId);
        }
      })
      .catch((error: any) => {
        setUser(null);
        console.error(error?.message);
      });
  }, []);

  return (
    <BitrixUserContext.Provider
      value={{
        user: bitrixUser,
        showLogin,
      }}
    >
      {children}
    </BitrixUserContext.Provider>
  );
};

export default BitrixAuth;
