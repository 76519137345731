import { NoteItem } from '@services/requests/customsTaxService/interface';
import React from 'react';

interface CustomsPaymentsDetailingProductHelpItemProps {
  note: NoteItem;
}

function CustomsPaymentsDetailingProductHelpItem({
  note,
}: CustomsPaymentsDetailingProductHelpItemProps) {
  const { description, title } = note;

  return (
    <div className="product-help-detailing-item">
      <span className="product-help-detailing-item__title">{title}</span>
      <p
        className="product-help-detailing-item__text"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  );
}

export { CustomsPaymentsDetailingProductHelpItem };
