import { filesService } from '@services/requests/filesService';
import { locationsBranchService } from '@services/requests/routeCalculator/locationsBranchSearchService';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { routesCalculationService } from '@services/requests/routeCalculator/routesCalculationService';
import {
  FilterData,
  SortData,
} from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import {
  AggregationData,
  RouteWidgetResult,
} from '@services/requests/routeCalculator/routesCalculationService/types';
import { terminalByLocationLoader } from '@services/requests/routeCalculator/TerminalByLocationLoader';
import { allowanceLoader } from '@services/requests/searchLoaders/allowanceLoader';
import { carriersLoader } from '@services/requests/searchLoaders/carriersLoader';
import { containersLoader } from '@services/requests/searchLoaders/containersLoader';
import { ContainersData } from '@services/requests/searchLoaders/containersLoader/ContainersLoaderQuery';
import { containerTypeLoader } from '@services/requests/searchLoaders/containerTypesLoader';
import { contractorLoader } from '@services/requests/searchLoaders/contractorLoader';
import { currencyLoader } from '@services/requests/searchLoaders/currencyLoader';
import { endTransportingConditionsLoader } from '@services/requests/searchLoaders/endTransportingConditionsLoader';
import { loadUnloadConditionsLoader } from '@services/requests/searchLoaders/LoadUnloadConditionsLoader';
import { shoulderTypesLoader } from '@services/requests/searchLoaders/shouldeTypesLoader';
import { startTransportingConditionsLoader } from '@services/requests/searchLoaders/startTransportingConditionsLoader';
import { unitGroupsLoader } from '@services/requests/searchLoaders/unitGroupsLoader';
import { unitLoader } from '@services/requests/searchLoaders/unitLoader';
import { shortLinkService } from '@services/requests/shortLinkService';
import { taxLoader } from '@services/requests/taxLoader';
import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import { $error } from '@settings/errorContext';
import { LoadCollection } from '@settings/services/helpers/LoadCollection';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';

import DELIVERY_FORM_CONSTANTS, {
  ONLY_IDS_ROUTE_PARAMS_KEYS,
  SHARE_SHORT_LINK_KEY,
} from './consts';
import { getShareCalculationParams, isShareParamsEqual } from './helpers';
import { ContainerParameters, RouteWidgetBaseParameters, RouteWidgetParams } from './types';

const LOCALSTORAGE_CONTEXT_KEY = '__ONLOG_ROUTE_WIDGET__';
const LOCALSTORAGE_UPDATE_CALCULATION_KEY = 'ONLOG_ROUTE_WIDGET';

interface Settings {
  primaryLanguageId: string;
  secondaryLanguageId: string;
  currencyId: string;
}

const $isLoading = new BehaviorSubject<boolean>(false);
const $isInit = new BehaviorSubject<boolean>(false);
const $settings = new BehaviorSubject<Settings>(DELIVERY_FORM_CONSTANTS.settings);
const $baseData = new BehaviorSubject<RouteWidgetBaseParameters | undefined>(undefined);
const $isNeedRoutesReloading = new BehaviorSubject<boolean>(false);
const $fromBranch = new BehaviorSubject<BranchItem | undefined>(undefined);
const $toBranch = new BehaviorSubject<BranchItem | undefined>(undefined);
const $routeParams = new BehaviorSubject<RouteWidgetParams>(DELIVERY_FORM_CONSTANTS.routeParams);
const $isConfigFromSearchParams = new BehaviorSubject<boolean>(false);
const $aggregationData = new BehaviorSubject<AggregationData | undefined>(undefined);
const $routes = new BehaviorSubject<RouteWidgetResult>(DELIVERY_FORM_CONSTANTS.routes);
const $terminals = new BehaviorSubject<BranchItem[]>([]);
const $startTerminals = new BehaviorSubject<BranchItem[]>([]);
const $endTerminals = new BehaviorSubject<BranchItem[]>([]);
const $filterParameters = new BehaviorSubject<FilterData>(DELIVERY_FORM_CONSTANTS.filterParameters);
const $cacheFilterParameters = new BehaviorSubject<FilterData>(
  DELIVERY_FORM_CONSTANTS.filterParameters
);
const $isArchiveLoading = new BehaviorSubject<boolean>(false);
const $isMoreRoutesLoadingAvailable = new BehaviorSubject<boolean>(false);
const $isRoutesLoading = new BehaviorSubject<boolean>(false);
const $isMoreRoutesLoading = new BehaviorSubject<boolean>(false);
const $sortData = new BehaviorSubject<SortData>(DELIVERY_FORM_CONSTANTS.sortData);
const $limit = new BehaviorSubject<number>(DELIVERY_FORM_CONSTANTS.limit);
const $offset = new BehaviorSubject<number>(DELIVERY_FORM_CONSTANTS.offset);
const $validation = new BehaviorSubject<boolean>(true);

const init = async (
  primaryLanguageId: string,
  secondaryLanguageId: string,
  currencyId: string,
  isDisableRoutesCalculation: boolean
) => {
  $isLoading.next(true);
  $settings.next({
    primaryLanguageId,
    secondaryLanguageId,
    currencyId,
  });

  const partOfBaseData = await LoadCollection<Omit<RouteWidgetBaseParameters, 'files'>>({
    currencies: currencyLoader().Load(),
    containers: containersLoader().Load(),
    containerTypes: containerTypeLoader().Load(),
    endTransportingConditions: endTransportingConditionsLoader().Load(),
    startTransportingConditions: startTransportingConditionsLoader().Load(),
    carriers: carriersLoader().Load(),
    contractors: contractorLoader().Load(),
    shoulderTypes: shoulderTypesLoader().Load(),
    allowancesData: allowanceLoader().Load(),
    units: unitLoader().Load(),
    unitGroups: unitGroupsLoader().Load(),
    loadingTransportConditions: loadUnloadConditionsLoader().getLoadingConditions(),
    unloadingTransportConditions: loadUnloadConditionsLoader().getUnloadingConditions(),
    taxes: taxLoader().Load(),
  });

  // Получаем список id файлов для перевозчиков и подрядчиков
  const filesIdsList = [...partOfBaseData.contractors, ...partOfBaseData.carriers]
    .map((item) => item.files)
    .flat();

  const baseData: RouteWidgetBaseParameters = {
    ...partOfBaseData,
    files: await filesService().LoadFilesById(filesIdsList),
  };

  const getValueRouteParams = $routeParams.getValue();

  const contextRouteParams = {
    ...getValueRouteParams,
    containerParameters: {
      ...getValueRouteParams.containerParameters,
      containerParameters: [
        (await containersLoader().Load()).map((item) => ({
          containerId: item.id,
          containerType: item.containerType.id.toString(),
          containerQuantity:
            getValueRouteParams.containerParameters.containerParameters[0].containerQuantity,
          eachContainerWeight: item.default_weight,
        }))[2],
      ],
    },
  };


  const searchParams = new URLSearchParams(window.location.search);

  const shortLink = searchParams.get(SHARE_SHORT_LINK_KEY) ?? '';

  const shortLinkData = await shortLinkService().LoadShortLink({ id: shortLink });

  let parsedShortLinkData = {};
  if (shortLinkData?.data) {
    parsedShortLinkData = JSON.parse(shortLinkData?.data ?? '{}');
  }

  const [isRouteSearchParamsValid, parsedRouteParams] =
  getShareCalculationParams(parsedShortLinkData);
  
  let routeParams = { ...contextRouteParams };

  if (isRouteSearchParamsValid) {
    $isConfigFromSearchParams.next(true);

    const {
      id: startId,
      parentId: startParentId,
      ...restStartLocationParams
    } = parsedRouteParams.startLocation;
    const {
      id: endId,
      parentId: endParentId,
      ...restEndLocationParams
    } = parsedRouteParams.endLocation;

    const startLocationId = parsedRouteParams.startLocation.id;
    const endLocationId = parsedRouteParams.endLocation.id;
    const startLocationIds = Object.values([startId, startParentId]);
    const endLocationIds = Object.values([endId, endParentId]);

    const { startLocations, endLocations } = await LoadCollection<{
      startLocations: BranchItem[];
      endLocations: BranchItem[];
    }>({
      startLocations: terminalByLocationLoader().Load(startLocationIds, [startLocationId]),
      endLocations: terminalByLocationLoader().Load(endLocationIds, [endLocationId]),
    });

    const startSearchResult = startLocations.map((l) => ({
      id: l.id,
      type: l.type,
    }));
    startSearchResult.push({ id: startLocationId, type: 'location' });

    const endSearchResult = endLocations.map((l) => ({
      id: l.id,
      type: l.type,
    }));
    endSearchResult.push({ id: endLocationId, type: 'location' });

    await Promise.all([
      locationsBranchService().getLocationWithParentTree(
        startSearchResult,
        await locationsBranchService().loadLocalization(startLocations)
      ),
      locationsBranchService().getLocationWithParentTree(
        endSearchResult,
        await locationsBranchService().loadLocalization(endLocations)
      ),
    ]);

    const foundStartLocation = startLocations.find((location) => location.id === startLocationId);
    const foundEndLocation = endLocations.find((location) => location.id === endLocationId);

    const params = {
      ...parsedRouteParams,
      startLocation: { ...foundStartLocation, ...restStartLocationParams },
      endLocation: { ...foundEndLocation, ...restEndLocationParams },
    } as RouteWidgetParams;

    routeParams = {
      ...params,
    };
  } else {
    if (!routeParams.isDangerCargo) {
      routeParams.isDangerCargo =
        routeParams.containerParameters.isDangerousCargo ||
        routeParams.customCargoParameters.isDangerousCargo ||
        false;
    }

    resetSearchParams();

    try {
      const updateCalculationParams = JSON.parse(
        localStorage.getItem(LOCALSTORAGE_UPDATE_CALCULATION_KEY) || ''
      );
      if (updateCalculationParams) {
        const { containerParameters, customCargoParameters } =
          updateCalculationParams.data.cargoParameters;

        const containerCargoParams = JSON.parse(containerParameters || 'null');
        const customCargoParams = JSON.parse(customCargoParameters || 'null');

        const { from, to } =
          containerCargoParams?.routePoints || customCargoParams?.routePoints || null;

        const {
          startTransportingConditionId,
          endTransportingConditionId,
          requiredTransportingTypes,
          isNeedPrekeridge,
          isNeedContainerRent,
          isDangerousCargo,
        } = containerCargoParams || customCargoParams || {};

        const fromLocationIds = Object.values(from)
          .filter(Boolean)
          .map((location) => String(location));
        const toLocationIds = Object.values(to)
          .filter(Boolean)
          .map((location) => String(location));

        const fromLocationType = from.location !== 0 ? 'location' : 'terminal';
        const toLocationType = to.location !== 0 ? 'location' : 'terminal';

        const fromLocation = fromLocationIds[0];
        const toLocation = toLocationIds[0];

        const { startLocations, endLocations } = await LoadCollection<{
          startLocations: BranchItem[];
          endLocations: BranchItem[];
        }>({
          startLocations: terminalByLocationLoader().Load(fromLocationIds, [fromLocation]),
          endLocations: terminalByLocationLoader().Load(toLocationIds, [toLocation]),
        });

        if (startLocations.length === 0) {
          startLocations.push();
        }

        const startLocation = startLocations.find((location) => location.id === fromLocation);
        const endLocation = endLocations.find((location) => location.id === toLocation);

        if (startLocation?.type === 'location') {
          startLocations.push({ ...startLocation, isWarehouse: false });
          startLocations.push({ ...startLocation, isWarehouse: true });
        }

        if (endLocation?.type === 'location') {
          endLocations.push({ ...endLocation, isWarehouse: false });
          endLocations.push({ ...endLocation, isWarehouse: true });
        }

        const startItem = await locationsBranchService().GetItemByValue(
          fromLocation,
          fromLocationType
        );
        if (startTransportingConditionId === 3) {
          startItem.isWarehouse = true;
        } else {
          startItem.isWarehouse = false;
        }

        const endItem = await locationsBranchService().GetItemByValue(toLocation, toLocationType);
        if (endTransportingConditionId === 3) {
          endItem.isWarehouse = true;
        } else {
          endItem.isWarehouse = false;
        }

        if (startLocations.length === 0) {
          startLocations.push(startItem);
        }

        if (endLocations.length === 0) {
          startLocations.push(endItem);
        }

        const startSearchResult = startLocations.map((l) => ({
          id: l.id,
          type: l.type,
        }));
        startSearchResult.push({ id: fromLocation, type: 'location' });

        const endSearchResult = endLocations.map((l) => ({
          id: l.id,
          type: l.type,
        }));
        endSearchResult.push({ id: toLocation, type: 'location' });

        await Promise.all([
          locationsBranchService().getLocationWithParentTree(
            startSearchResult,
            await locationsBranchService().loadLocalization(startLocations)
          ),
          locationsBranchService().getLocationWithParentTree(
            endSearchResult,
            await locationsBranchService().loadLocalization(endLocations)
          ),
        ]);

        const foundStartLocation = startLocations.find((location) =>
          location.id === fromLocation && startTransportingConditionId === 3
            ? location.isWarehouse === true
            : location.isWarehouse === false
        );
        const foundEndLocation = endLocations.find((location) =>
          location.id === toLocation && endTransportingConditionId === 3
            ? location.isWarehouse === true
            : location.isWarehouse === false
        );

        if (startTransportingConditionId === 3) {
          foundStartLocation.isWarehouse = true;
          foundStartLocation.visibleName = 'ShippersWarehouse';
          foundStartLocation.main = true;
        } else if (foundStartLocation.type === 'location' && startTransportingConditionId !== 3) {
          foundStartLocation.visibleName = 'CarrierTerminal';
          foundStartLocation.main = true;
        }

        if (endTransportingConditionId === 3) {
          foundEndLocation.isWarehouse = true;
          foundEndLocation.visibleName = 'ConsigneesWarehouse';
          foundEndLocation.main = true;
        } else if (foundEndLocation.type === 'location' && endTransportingConditionId !== 3) {
          foundEndLocation.visibleName = 'CarrierTerminal';
          foundEndLocation.main = true;
        }

        routeParams = {
          cargoType: !!containerCargoParams ? 'container' : 'custom',
          containerParameters: containerCargoParams
            ? {
                endTransportingConditionId:
                  containerCargoParams.endTransportingConditionId.toString(),
                isCustomConditionsOpen: containerCargoParams.isDangerousCargo,
                isDangerousCargo: containerCargoParams.isDangerousCargo,
                startTransportingConditionId:
                  containerCargoParams.startTransportingConditionId.toString(),
                targetCurrencyId: containerCargoParams.targetCurrencyId,
                containerParameters: [
                  {
                    containerId: containerCargoParams.containerId,
                    containerQuantity: containerCargoParams.containerQuantity,
                    containerType: '1',
                    eachContainerWeight: containerCargoParams.eachContainerWeight,
                  },
                ],
              }
            : {
                ...DELIVERY_FORM_CONSTANTS.routeParams.containerParameters,
                containerParameters:
                  DELIVERY_FORM_CONSTANTS.routeParams.containerParameters.containerParameters.map(
                    (container) => ({ ...container, containerId: '1' })
                  ),
              },
          customCargoParameters: customCargoParams
            ? {
                customCargoParameters: [
                  {
                    baseParameters: customCargoParams.baseParameters,
                    palletParameters: customCargoParams.palletParameters,
                    volumeParameters: customCargoParams.volumeParameters,
                    parametersType: customCargoParams.parametersType,
                    parametersCargoCalcType: customCargoParams.parametersType,
                  },
                ],
                endTransportingConditionId: customCargoParams.endTransportingConditionId.toString(),
                isCustomConditionsOpen: customCargoParams.isDangerousCargo,
                isDangerousCargo: customCargoParams.isDangerousCargo,
                startTransportingConditionId:
                  customCargoParams.startTransportingConditionId.toString(),
                targetCurrencyId: customCargoParams.targetCurrencyId,
              }
            : { ...DELIVERY_FORM_CONSTANTS.routeParams.customCargoParameters },
          startExpeditionDate: updateCalculationParams.date,
          isDangerCargo: isDangerousCargo,
          isNeedContainerRent: isNeedContainerRent,
          isNeedPrekeridge: isNeedPrekeridge,
          requiredTransportingTypes: requiredTransportingTypes,
          endTransportingCondition: endTransportingConditionId.toString(),
          startTransportingCondition: startTransportingConditionId.toString(),
          endLocation: foundEndLocation,
          startLocation: foundStartLocation,
        };
      } else {
        routeParams = JSON.parse(localStorage.getItem(LOCALSTORAGE_CONTEXT_KEY) || '');

        if (!routeParams) {
          routeParams = contextRouteParams;
        }
      }
    } catch (e) {}
  }

  routeParams.startExpeditionDate = new Date(routeParams.startExpeditionDate || new Date());

  const startExpeditionDate = routeParams.startExpeditionDate;
  const startOfCurrentDayDate = dayjs().startOf('day').toDate();

  if (startExpeditionDate && startExpeditionDate < startOfCurrentDayDate) {
    routeParams.startExpeditionDate = dayjs().toDate();
  }

  const defaultStartTransportingCondition = baseData.startTransportingConditions.find(
    (condition) => condition.id === '1'
  );
  const defaultEndTransportingCondition = baseData.endTransportingConditions.find(
    (condition) => condition.id === '1'
  );

  $baseData.next(baseData);
  $routeParams.next({
    ...routeParams,
    containerParameters: {
      ...routeParams.containerParameters,
      targetCurrencyId: currencyId,
    },
    customCargoParameters: {
      ...routeParams.customCargoParameters,
      targetCurrencyId: currencyId,
    },
    startTransportingCondition:
      routeParams.startTransportingCondition || defaultStartTransportingCondition.id,
    endTransportingCondition:
      routeParams.endTransportingCondition || defaultEndTransportingCondition.id,
  });
  $isLoading.next(false);

  if (!isDisableRoutesCalculation) {
    await recalculateRoutes(true);
  }

  if (currencyId) {
    $isInit.next(true);
  }
};

/** *
 * Перерасчет маршрутов
 */
const recalculateRoutes = async (isNeedFullReload: boolean) => {
  $validation.next(true);
  try {
    if (isNeedFullReload) {
      $aggregationData.next(undefined);
      $isLoading.next(true);
      $offset.next(0);
      $limit.next(10);

      await reloadFullRoute();
    } else {
      $isRoutesLoading.next(true);

      await reloadRoute();
    }
  } catch (e) {
    $isMoreRoutesLoading.next(false);
    $isLoading.next(false);
    $isRoutesLoading.next(false);
  } finally {
    $isMoreRoutesLoading.next(false);
    $isLoading.next(false);
    $isRoutesLoading.next(false);
    saveToLocalStorage();
  }
};

/**
 * Загрузка данных по обновленным параметрам фильтрации и сортировки
 */
const reloadRoute = async () => {
  const service = routesCalculationService();
  const routeParams = $routeParams.getValue();
  const isArchiveLoading = $isArchiveLoading.getValue();
  const filterParameters = $filterParameters.getValue();
  const limit = $limit.getValue();
  const offset = $offset.getValue();
  const sortData = $sortData.getValue();

  if (!routeParams) {
    return;
  }
  const mayBeCalculated =
    !!routeParams.startExpeditionDate &&
    !!routeParams.startLocation &&
    !!routeParams.endLocation &&
    !!routeParams.cargoType;

  if (!mayBeCalculated) {
    $isInit.getValue() && $validation.next(false);
    return;
  }

  const routeCalculateParams = {
    ...routeParams,
    limit,
    offset,
  };

  const calculateResult = await service.GetRoutesList(
    routeCalculateParams,
    isArchiveLoading,
    filterParameters,
    sortData,
    false
  );

  if (!calculateResult) {
    return;
  }

  $fromBranch.next(routeParams.startLocation);
  $toBranch.next(routeParams.endLocation);
  $routes.next(calculateResult.routes);
  $isMoreRoutesLoadingAvailable.next(offset + 1 + 10 < calculateResult.routes.total);
};

/**
 * Полная загрузка данных по маршруту
 */
const reloadFullRoute = async () => {
  const service = routesCalculationService();
  const routeParams = $routeParams.getValue();
  const isArchiveLoading = $isArchiveLoading.getValue();
  const limit = $limit.getValue();
  const offset = $offset.getValue();
  const sortData = $sortData.getValue();

  if (!routeParams) {
    return;
  }

  const mayBeCalculated =
    !!routeParams.startExpeditionDate &&
    !!routeParams.startLocation &&
    !!routeParams.endLocation &&
    !!routeParams.cargoType;

  if (!mayBeCalculated) {
    $isInit.getValue() && $validation.next(false);
    return;
  }

  const routeCalculateParams = {
    ...routeParams,
    limit,
    offset,
  };

  const aggregationData = await service.GetRoutesAggregation(
    routeCalculateParams,
    isArchiveLoading
  );

  const filterParameters: FilterData = {
    carriers: aggregationData?.carriers ?? [],
    contractors: aggregationData?.contractors ?? [],
    deliveryTime: {
      min: aggregationData
        ? aggregationData.deliveryTime.min > 0
          ? aggregationData.deliveryTime.min
          : 0
        : 0,
      max: aggregationData
        ? aggregationData.deliveryTime.max > 0
          ? aggregationData.deliveryTime.max
          : 0
        : 0,
    },
    price: {
      min: aggregationData ? (aggregationData.price.min > 0 ? aggregationData.price.min : 0) : 0,
      max: aggregationData ? (aggregationData.price.max > 0 ? aggregationData.price.max : 0) : 0,
    },
    transportingTypes: aggregationData?.transportingTypes ?? [],
    isSingleShoulderRoutesOnly: false,
    isNeedPrekeridgeRoutes: true,
    isNeedRegularRoutes: true,
    terminals: aggregationData?.terminals ?? [],
    startTerminals: aggregationData?.startTerminals ?? [],
    endTerminals: aggregationData?.endTerminals ?? [],
    containerRentType: aggregationData?.containerRentType ?? [],
  };

  const calculateResult = await service.GetRoutesList(
    routeCalculateParams,
    isArchiveLoading,
    filterParameters,
    sortData,
    true
  );

  if (!calculateResult) {
    return;
  }

  $isMoreRoutesLoadingAvailable.next(offset + 1 + 10 < calculateResult.routes.total);
  $fromBranch.next(routeParams.startLocation);
  $toBranch.next(routeParams.endLocation);
  $routes.next(calculateResult.routes);
  $terminals.next(calculateResult.terminals);
  $startTerminals.next(calculateResult.startTerminals);
  $endTerminals.next(calculateResult.endTerminals);
  $filterParameters.next(filterParameters);
  $cacheFilterParameters.next(filterParameters);
  $aggregationData.next(aggregationData);
};

/**
 * Обработчик сохранения параметров для расчета
 */
const saveToLocalStorage = () => {
  const routeParams = $routeParams.getValue();
  const isInit = $isInit.getValue();

  if (!isInit) {
    return;
  }

  try {
    localStorage.setItem(LOCALSTORAGE_CONTEXT_KEY, JSON.stringify(routeParams));
  } catch (e) {
    $error.next(e);
  }
};

const resetWidget = () => {
  $isLoading.next(false);
  $isInit.next(false);
  $baseData.next(undefined);
  $isNeedRoutesReloading.next(false);
  $routeParams.next(DELIVERY_FORM_CONSTANTS.routeParams);
  $aggregationData.next(undefined);
  $routes.next(DELIVERY_FORM_CONSTANTS.routes);
  $terminals.next([]);
  $startTerminals.next([]);
  $endTerminals.next([]);
  $filterParameters.next(DELIVERY_FORM_CONSTANTS.filterParameters);
  $cacheFilterParameters.next(DELIVERY_FORM_CONSTANTS.filterParameters);
  $isArchiveLoading.next(false);
  $sortData.next(DELIVERY_FORM_CONSTANTS.sortData);
  $limit.next(DELIVERY_FORM_CONSTANTS.limit);
  $offset.next(DELIVERY_FORM_CONSTANTS.offset);

  const { primaryLanguageId, secondaryLanguageId, currencyId } = $settings.getValue();
  $isLoading.next(true);
  init(primaryLanguageId, secondaryLanguageId, currencyId, false);
  $isLoading.next(false);
  localStorage.removeItem(LOCALSTORAGE_CONTEXT_KEY);
  localStorage.removeItem(LOCALSTORAGE_UPDATE_CALCULATION_KEY);
  resetSearchParams();
};

const saveToSearchParams = async () => {
  const routeParams = $routeParams.getValue();

  const searchParams = new URLSearchParams(window.location.search);

  const shortLink = searchParams.get(SHARE_SHORT_LINK_KEY) ?? '';

  const formattedRouteSearchParams = Object.entries(routeParams);
  const shareData: Record<string, string> = {};

  for (const [key, value] of formattedRouteSearchParams) {
    if (ONLY_IDS_ROUTE_PARAMS_KEYS.includes(key)) {
      const branchItem = value as BranchItem;
      const { id, parentId, type, main, isWarehouse, symbolCode, visibleName } = branchItem;

      shareData[key] = JSON.stringify({
        id,
        parentId,
        type,
        main,
        isWarehouse,
        symbolCode,
        visibleName,
      });
    } else if (value instanceof Date) {
      shareData[key] = value.toISOString();
    } else {
      shareData[key] = JSON.stringify(value);
    }
  }
  const serializedShareData = JSON.stringify(shareData);

  const shortLinkData = await shortLinkService().LoadShortLink({ id: shortLink });
  const isParamsEqual = isShareParamsEqual(serializedShareData, shortLinkData?.data || '{}');

  if (isParamsEqual) {
    return;
  }

  const generatedShortLink = await shortLinkService().GenerateShortLink({
    page: 'widget',
    data: serializedShareData,
  });

  history.pushState(null, '', `?${SHARE_SHORT_LINK_KEY}=` + generatedShortLink);
};

const resetSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const shareParamsKeys = [SHARE_SHORT_LINK_KEY];

  for (const key of shareParamsKeys) {
    searchParams.delete(key);
  }

  const isSearchParamsEmpty = !searchParams.size;
  let searchParamsString = window.location.pathname;

  if (!isSearchParamsEmpty) {
    searchParamsString = '?' + searchParams.toString();
  }

  history.pushState(null, '', searchParamsString);
};

const actions = {
  saveToLocalStorage,
  resetWidget,
  recalculateRoutes,
  resetSearchParams,
  saveToSearchParams,
  init,
};

const useDelivery = () => {
  const useIsLoading = () => useStateSubscriber<boolean>($isLoading);
  const useBaseDate = () => useStateSubscriber<RouteWidgetBaseParameters | undefined>($baseData);
  const useRouteParams = () =>
    useStateSubscriber<RouteWidgetParams>($routeParams, () => $validation.next(true));
  const useIsConfigFromSearchParams = () => useStateSubscriber<boolean>($isConfigFromSearchParams);
  const useIsInit = () => useStateSubscriber<boolean>($isInit);
  const useIsNeedReloading = () => useStateSubscriber<boolean>($isNeedRoutesReloading);
  const useSettings = () => useStateSubscriber<Settings>($settings);
  const useAggregationData = () =>
    useStateSubscriber<AggregationData | undefined>($aggregationData);
  const useRoutes = () => useStateSubscriber<RouteWidgetResult>($routes);
  const useTerminals = () => useStateSubscriber<BranchItem[]>($terminals);
  const useStartTerminals = () => useStateSubscriber<BranchItem[]>($startTerminals);
  const useEndTerminals = () => useStateSubscriber<BranchItem[]>($endTerminals);
  const useFilterParameters = () => useStateSubscriber<FilterData>($filterParameters);
  const useCacheFilterParameters = () => useStateSubscriber<FilterData>($cacheFilterParameters);
  const useIsArchiveLoading = () => useStateSubscriber<boolean>($isArchiveLoading);
  const useIsMoreRoutesLoadingAvailable = () =>
    useStateSubscriber<boolean>($isMoreRoutesLoadingAvailable);
  const useIsRoutesLoading = () => useStateSubscriber<boolean>($isRoutesLoading);
  const useIsMoreRoutesLoading = () => useStateSubscriber<boolean>($isMoreRoutesLoading);
  const useSortData = () => useStateSubscriber<SortData>($sortData);
  const useLimit = () => useStateSubscriber<number>($limit);
  const useOffset = () => useStateSubscriber<number>($offset);
  const useValidation = () => useStateSubscriber<boolean>($validation);
  const useToBranch = () => useStateSubscriber<BranchItem | undefined>($toBranch);
  const useFromBranch = () => useStateSubscriber<BranchItem | undefined>($fromBranch);

  return {
    useIsLoading,
    useValidation,
    useBaseDate,
    useRouteParams,
    useIsConfigFromSearchParams,
    useIsInit,
    useIsNeedReloading,
    useSettings,
    useAggregationData,
    useFromBranch,
    useToBranch,
    useRoutes,
    useTerminals,
    useStartTerminals,
    useEndTerminals,
    useFilterParameters,
    useCacheFilterParameters,
    useIsArchiveLoading,
    useIsMoreRoutesLoadingAvailable,
    useIsRoutesLoading,
    useIsMoreRoutesLoading,
    useSortData,
    useLimit,
    useOffset,
    actions,
  };
};

export default useDelivery;
