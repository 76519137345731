import { styled } from '@mui/material/styles';

const CustomsPaymentsStyledComponent = styled('div', {
  name: 'CustomsPaymentsStyledComponent',
})`
  .customs-payments {
    display: flex;
    flex-direction: column;

    &__wrap {
      display: flex;
      justify-content: space-between;
    }

    &__country {
      margin-bottom: 20px;
    }
  }
`;

export default CustomsPaymentsStyledComponent;
