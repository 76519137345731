import React from 'react';

import { CustomsPaymentProducts } from '../../context/types';
import { CustomsPaymentsProductItem } from '../CustomsPaymentsProductItem';

interface CustomsPaymentsProductListProps {
  products: CustomsPaymentProducts;
  isLastProduct: boolean;
}

function CustomsPaymentsProductList({ products, isLastProduct }: CustomsPaymentsProductListProps) {
  return (
    <>
      {products.map((product, index) => (
        <CustomsPaymentsProductItem
          key={'product-' + index}
          productIndex={index}
          product={product}
          isLastProduct={isLastProduct}
        />
      ))}
    </>
  );
}

export { CustomsPaymentsProductList };
