import {
  ServicesProps,
  ServicesServicePriceCalculationMutationProps,
} from '@services/requests/servicesService/servicesServicesService/interface';

export const getRowsForTemplate4 = (
  s: ServicesProps,
  serviceData?: ServicesServicePriceCalculationMutationProps
) => {
  let item = {
    id: s.id,
    name: s.name,
    cost: 0,
    count: 1,
    sum: 0,
    course: 0,
    conversePayment: 0,
    sumConversePayment: 0,
    taxRate: 0,
    sumTax: 0,
    total: 0,
    calculationAccordingToApplication: true,
  };

  if (serviceData?.result?.result) {
    const {
      basePrice,
      fullPriceInTargetCurrency,
      taxInTargetCurrency,
      innerCurrency,
      conversionFee,
    } = serviceData.result.result;

    item = {
      ...item,
      course: +innerCurrency,
      cost: basePrice,
      total: fullPriceInTargetCurrency,
      conversePayment: conversionFee,
      sumConversePayment: basePrice + conversionFee,
      sumTax: taxInTargetCurrency,
      calculationAccordingToApplication: basePrice < 0,
    };
  }

  const temp = s.fields
    .filter((f) => f.type !== 'hidden')
    .reduce((acc, f) => {
      acc = {
        ...acc,
        [f.code]: f,
      };
      return acc;
    }, item);

  return [temp];
};
