import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import GetField from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/GetFields/components/GetFields';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React from 'react';

const FieldItem = (props: GridRenderCellParams<any, any, any>) => {
  const { useAdditionalInfo, useFieldStateServices, actions } = useWidget();
  const [{ currencies, taxes, contractors }] = useAdditionalInfo().useState();
  const [states, setStates] = useFieldStateServices().useState();
  const { recalculateService } = actions;

  const state = states[props.id];

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [props.id]: value,
      }));

      recalculateService(String(props.id));
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;

    setStates((prevState) => ({
      ...prevState,
      [props.id]: stateUpdater(states[props.id]),
    }));

    recalculateService(String(props.id));
  };

  const currentField = props.row[props.field];

  if (!currentField) {
    return <></>;
  }

  return (
    <GetField
      withLabel={false}
      size={'small'}
      realTimeValidation={true}
      field={currentField}
      state={state}
      setState={setState}
      currencies={currencies}
      taxes={taxes}
      contractorsOptions={contractors}
    />
  );
};

export default FieldItem;
