import MIconButton from '@assets/mui/@material-extend/MIconButton';
import PlusIcon from '@components/icons/PlusIcon';
import SettingIcon from '@components/icons/SettingIcon';
import BreadCrumbsHOC, { BreadCrumbProps } from '@components/UI/breadcrumbs';
import { useMediaQuery } from '@material-ui/core';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomsPaymentsFormWrapper } from './components/CustomsPaymentsForm';
import { CustomsPaymentsAdditionalTerms } from './components/CustomsPaymentsForm/components/CustomsPaymentsAdditionalTerms';
import { CustomsPaymentsControl } from './components/CustomsPaymentsForm/components/CustomsPaymentsControl';
import { CustomsPaymentsDetailing } from './components/CustomsPaymentsForm/components/CustomsPaymentsDetailing';
import { CustomsPaymentsProductList } from './components/CustomsPaymentsForm/components/CustomsPaymentsProductList';
import { CustomsPaymentTotal } from './components/CustomsPaymentsForm/components/CustomsPaymentTotal';
import { useCustomsPayments } from './components/CustomsPaymentsForm/context';
import { Container } from './StyledComponents';

const CustomsPayments = () => {
  const [isSearchParamsOpened, setIsSearchParamsOpened] = useState(false);
  const [isCustomsPaymentDetailsOpen, setIsCustomsPaymentDetailsOpen] = useState(false);
  const { actions, useCustomsPaymentProducts } = useCustomsPayments();
  const { init, onAddCustomsPaymentProduct } = actions;
  const [products] = useCustomsPaymentProducts().useState();

  const { t } = useTranslation(['PageNames', 'TubsContainer', 'CustomsPayments']);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLastProduct = products.length === 1;

  useEffect(() => {
    init();
  }, []);

  const openCustomsPaymentDetailsHandler = () => {
    setIsCustomsPaymentDetailsOpen(true);
  };

  const closeCustomsPaymentDetailsHandler = () => {
    setIsCustomsPaymentDetailsOpen(false);
  };

  const openSearchParamsHandler = () => {
    setIsSearchParamsOpened(true);
  };

  const closeSearchParamsHandler = () => {
    setIsSearchParamsOpened(false);
  };

  const breadCrumbs: BreadCrumbProps[] = [
    {
      id: 1,
      name: t('PageNames:MainPage'),
      url: 'widget',
    },
    {
      id: 1,
      name: t('TubsContainer:CustomsPayments'),
    },
  ];

  return (
    <>
      <Container>
        {isMobile && <BreadCrumbsHOC crumbs={breadCrumbs} />}
        {isMobile && (
          <div className={'params__order'} onClick={openSearchParamsHandler}>
            <div className={'mobile__title'}>
              <SettingIcon />
              <Typography variant="h3">{t('CustomsPayments:SearchOptions')}</Typography>
            </div>
            <MIconButton size={'small'} edge="end" color="inherit" aria-label="close">
              <KeyboardArrowUpIcon sx={{ color: '#707070', fontSize: '25px' }} />
            </MIconButton>
          </div>
        )}
        <CustomsPaymentsFormWrapper
          isOpened={isSearchParamsOpened}
          onCollapse={closeSearchParamsHandler}
        />
        {!isMobile && <BreadCrumbsHOC crumbs={breadCrumbs} />}
        <CustomsPaymentsProductList isLastProduct={isLastProduct} products={products} />
        <IconButton
          title={`+ Добавить товар`}
          className={'add-new-product'}
          onClick={onAddCustomsPaymentProduct}
        >
          <PlusIcon fontSize={isMobile ? 'small' : 'medium'} style={{ marginRight: '10px' }} />{' '}
          <span className="add-product-text">{t('CustomsPayments:AddProductBtn')}</span>
        </IconButton>
        <CustomsPaymentTotal />
        <CustomsPaymentsControl onOpenDetails={openCustomsPaymentDetailsHandler} />
        <CustomsPaymentsAdditionalTerms />
      </Container>

      <CustomsPaymentsDetailing
        isOpen={isCustomsPaymentDetailsOpen}
        onCloseDetailing={closeCustomsPaymentDetailsHandler}
      />
    </>
  );
};

export default CustomsPayments;
