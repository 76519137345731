import SearchIcon from '@components/icons/SearchIcon';
import CardWrapper from '@components/UI/CardWrapper';
import { styled } from '@mui/material';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { LevelInner, TabsItemProps } from '@pages/AdditionalServices/context/types';
import AdditionalFormItem from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalForm';
import FieldsMap from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/FieldsMap';
import { getAddServiceItems } from '@pages/MainWidget/helpers';
import { ServicesFieldProps } from '@services/requests/servicesService/interface';
import React, { useEffect, useMemo } from 'react';

import useWidget from '../../../../context';

interface Props {
  service: TabsItemProps;
}

const Container = styled('div')`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
`;

const NotFound = styled('div')`
  color: #707070;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const ContainerAddServices = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`;

const AdditionalServiceForm = ({ service }: Props) => {
  const {
    useAdditionalFormTrees,
    useAdditionalInfo,
    useEndSearch,
    useFieldStateMainForm,
    useProcessLoadingDataForConstructor,
    useAdditionalServices,
  } = useWidget();

  const [trees, setTrees] = useAdditionalFormTrees().useState();
  const [{ taxes, currencies, contractors }] = useAdditionalInfo().useState();
  const [endSearch, setEndSearch] = useEndSearch().useState();
  const [services] = useAdditionalServices().useState();
  const [state, setState] = useFieldStateMainForm().useState();
  const setIsLoadDataForConstructor = useProcessLoadingDataForConstructor().setState;
  const goToSearch = () => setIsLoadDataForConstructor((prev) => !prev);

  const fields = trees.reduce(
    (acc: ServicesFieldProps[], i: LevelInner) => {
      if (i.entityId) {
        const current = i.options.find((o) => o.value === i.entityId);
        current?.entity?.fields.forEach((f) => acc.push(f));
      }
      return acc;
    },
    [...service.fields]
  );

  useEffect(() => {
    if (trees.find((i) => i.entityId)) {
      return;
    }

    if (trees.length !== 0) {
      return;
    }

    const options = service.childs.map((s) => ({
      value: s.id,
      label: s.name,
      entity: s,
    }));

    if (options.length !== 0) {
      setTrees([{ options, level: 0, entityId: null }]);
    }
    if (service.childs.length === 0) {
      if (service.fields.filter((f) => f.type !== 'hidden').length !== 0) {
        setEndSearch(true);
      } else {
        getAddServiceItems(service.id).then((s) => {
          if (s.length !== 0) {
            setEndSearch(true);

            goToSearch();
          }
        });
      }
    }
  }, [service]);

  const validateTempState =
    fields.filter((f) => f.type !== 'hidden').length === Object.keys(state).length;

  const readyToSearch = endSearch && validateTempState;

  const messageError = useMemo(() => {
    if (!endSearch) {
      return 'Вложенность директорий не является конечной';
    }

    if (!validateTempState) {
      return 'Заполните поля для поиска';
    }

    return '';
  }, [endSearch, validateTempState]);

  if (services.length === 0 && trees.length === 0) {
    return (
      <CardWrapper sx={{ padding: '30px 40px' }}>
        <NotFound>Нет элементов</NotFound>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper sx={{ padding: '30px 40px', marginBottom: '50px' }}>
      <ContainerAddServices>
        <Container>
          {trees.map((trees, index) => (
            <AdditionalFormItem key={index} trees={trees} index={index} />
          ))}
        </Container>
        <Tooltip title={messageError}>
          <div>
            <Fab
              color={'primary'}
              className={'form-wrapper__search'}
              onClick={goToSearch}
              disabled={!readyToSearch}
              size={'medium'}
            >
              <SearchIcon sx={{ fontSize: 25, color: 'common.white' }} />
            </Fab>
          </div>
        </Tooltip>
      </ContainerAddServices>
      {fields.filter((f) => f.type !== 'hidden').length !== 0 && (
        <FieldsMap
          state={state}
          setState={setState as any}
          taxes={taxes}
          currencies={currencies}
          contractorsOptions={contractors}
          fields={fields.filter((f) => f.type !== 'hidden')}
        />
      )}
    </CardWrapper>
  );
};

export default AdditionalServiceForm;
