import palette from '@assets/theme/palette';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  margin-bottom: 20px;

  .hidden__content__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4px;

    .hidden__content-title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .reset {
    margin-top: 10px;
    color: ${palette.light.blue[400]};
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    transition: 400ms;
    border: 0;
    width: fit-content;
    padding: 0;
    min-width: unset;

    &:hover {
      color: #1f54b2;
    }
  }

  .point {
    height: 4px;
    width: 4px;
    border-radius: 100%;
    background: #2e9700;
    animation: pulse infinite 2s;

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  }
`;

export default Container;
