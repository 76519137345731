import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material/styles';

export const CartSummaryStyledContainer = styled('div')`
  .summary-header-wrapper {
    display: flex;
    flex-direction: column;
  }

  .summary-header-title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .summary-header-cost {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  &:not(.basket) {
    .cart-summary {
      &__sub-list {
        flex: 0 0 100%;
      }

      &__header,
      &__list-item {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: space-between;

        &.--currency-detail {
          align-items: flex-start;
          flex-wrap: wrap;
        }
      }

      .MuiTypography-root {
        color: ${({ theme }) => theme.palette.common.black};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &__list {
        list-style: none;
        display: grid;
        gap: ${({ theme }) => theme.spacing(4.5)};
        padding: 19px 20px 19px 14px;

        &.--with-border-bottom {
          border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
        }
        @media (max-width: ${breakpoints.values.laptop}px) {
          gap: ${({ theme }) => theme.spacing(2)};
        }
      }
    }
  }
`;
