import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import ArchiveIcon from '@components/icons/Archive';
import EmailIcon from '@components/icons/EmailIcon';
import FavoriteIcon from '@components/icons/FavoriteIcon';
import QuestionIcon from '@components/icons/QuestionIcon';
import { YaTargetWrapper } from '@components/YaTargetWrapper/YaTargetWrapper';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CalculatedPrice } from '@services/requests/orderService/types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CartSummary from '@components/pages/CartSummary';
import CartAsideBlockStyledContainer from './StyledComponent';
import BitrixUserContext from '@context/useBitrixUser';
import useBasket from '@pages/Basket/useBasket';

/**
 * Пропсы компонента
 */
type OrderAsideSummaryProps = {
  onSendEmailClick: () => void;
  onQuestionClick: () => void;
  onAddFavoriteClick: () => void;
  onGetPDFClick: () => void;
  summary: CalculatedPrice;
};

/**
 * Компонент вывода информации и кнопок управления корзиной
 * @param props
 */
const CartAsideBlock = (props: OrderAsideSummaryProps) => {
  const { onAddFavoriteClick, onQuestionClick, onSendEmailClick, onGetPDFClick, summary } = props;
  const { t } = useTranslation('CartAsideBlock');

  const { setPage } = useMyRouting().actions;
  const { actions } = useBasket();
  const { completeOrder } = actions;
  const { user } = useContext(BitrixUserContext);

  const makeOrderHandler = async () => {
    if (!user) {
      return;
    }

    const { id, first_name, email, phone } = user;

    await completeOrder(id, first_name, email, phone);

    setPage('success-order', true);
  };

  return (
    <CartAsideBlockStyledContainer>
      <div className="controls">
        <CartSummary {...summary} modification={'basket'} />
        <Box className={'button-group'}>
          <YaTargetWrapper target="calculator_zakaz" noAuthTarget="">
            <BitrixDisableUserGuard>
              <Button
                onClick={makeOrderHandler}
                color={'primary'}
                variant={'contained'}
                fullWidth
                size={'large'}
              >
                {t('CartAsideBlock:OrderServices')}
              </Button>
            </BitrixDisableUserGuard>
          </YaTargetWrapper>
          <YaTargetWrapper target="calculator_pdf">
            <BitrixDisableUserGuard>
              <Button
                onClick={onGetPDFClick}
                color={'secondary'}
                variant={'contained'}
                size={'large'}
                fullWidth
              >
                {t('CartAsideBlock:DownloadPDF')}
              </Button>
            </BitrixDisableUserGuard>
          </YaTargetWrapper>
        </Box>
        <div className="links">
          <Button variant="text" onClick={onSendEmailClick} className={'link'} disabled>
            <EmailIcon />
            {t('CartAsideBlock:SendByEmail')}
          </Button>
          <Button variant="text" onClick={onQuestionClick} href="#" className={'link'} disabled>
            <QuestionIcon />
            {t('CartAsideBlock:AskQuestion')}
          </Button>
          <Button variant="text" onClick={onAddFavoriteClick} href="#" className={'link'} disabled>
            <FavoriteIcon />
            {t('CartAsideBlock:SaveToFavorites')}
          </Button>
          <Button variant="text" onClick={onAddFavoriteClick} href="#" className={'link'} disabled>
            <ArchiveIcon />
            {t('CartAsideBlock:AddToArchive')}
          </Button>
        </div>
      </div>
    </CartAsideBlockStyledContainer>
  );
};

export default CartAsideBlock;
