import { CartSummaryStyledContainer } from '@components/pages/CartSummary/StyledComponents';
import useBaseDataContext from '@context/useBaseDataContext';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import Typography from '@mui/material/Typography';
import useBasket from '@pages/Basket/useBasket';
import { CalculatedPrice } from '@services/requests/orderService/types';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Пропсы компонента
 */
export type CartSummaryProps = CalculatedPrice & {
  className?: string;
  modification?: 'basket' | 'default';
};

/**
 * Компонент вывода детализации по заказываемым услугам
 */
const CartSummary = (props: CartSummaryProps) => {
  const {
    conversionFee = 0,
    priceInTargetCurrency,
    taxInTargetCurrency,
    className,
    basePriceArray,
    modification = 'default',
    currencyCode,
  } = props;
  const { useBaseData } = useBaseDataContext();
  const [baseData] = useBaseData().useState();
  const { currencies } = baseData;
  const [order] = useBasket().useOrder().useState();
  const langCode = getLocalizationById(order.language_id).countryCode;

  const { t } = useTranslation('CartSummary', { lng: langCode });
  return (
    <CartSummaryStyledContainer className={clsx('cart-summary', className, modification)}>
      {modification === 'basket' && (
        <div className="summary-header-wrapper">
          <span className="summary-header-title">{t('CartSummary:TotalInclVAT')}:</span>

          {basePriceArray.map((price) => {
            if (price.value === 0) {
              return;
            }
            let currency = price.currencyCode;

            if (currency === '') {
              currency = currencies ? currencies.find((c) => c.id === price.currencyId).code : '';
            }
            return (
              <li
                className={'cart-summary__list-item --currency-item'}
                key={`${price.value}-${price.currencyId}`}
              >
                <span className="summary-header-cost">
                  {price.value.toLocaleString()} {currency}
                </span>
              </li>
            );
          })}
        </div>
      )}
      {modification !== 'basket' && (
        <ul className={'cart-summary__list --with-border-bottom'}>
          <li className={'cart-summary__list-item --currency-detail'}>
            <Typography variant={'body1'} className={'cart-summary__title'}>
              {t('CartSummary:CurrencyDetail')}:
            </Typography>
            <ul className={'cart-summary__sub-list'}>
              {basePriceArray.map((price) => {
                if (price.value === 0) {
                  return;
                }
                let currency = price.currencyCode;

                if (currency === '') {
                  currency = currencies
                    ? currencies.find((c) => c.id === price.currencyId).code
                    : '';
                }
                return (
                  <li
                    className={'cart-summary__list-item --currency-item'}
                    key={`${price.value}-${price.currencyId}`}
                  >
                    {!modification ||
                      (modification === 'default' && (
                        <Typography variant={'body1'}>{currency}:</Typography>
                      ))}
                    <Typography variant={'body1'} whiteSpace={'nowrap'}>
                      {price.value.toLocaleString()}{' '}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </li>
          {!!conversionFee && (
            <li className={'cart-summary__list-item'}>
              <Typography variant={'body1'} className={'cart-summary__title'}>
                {t('CartSummary:ForeignExchangeExpenses')}:
              </Typography>
              <Typography variant={'body1'} whiteSpace={'nowrap'}>
                {conversionFee.toLocaleString()} {currencyCode}
              </Typography>
            </li>
          )}
        </ul>
      )}
      <ul className={'cart-summary__list'}>
        <li className={'cart-summary__list-item'}>
          <Typography variant={'body1'} className={'cart-summary__title'}>
            {t('CartSummary:VAT')}:
          </Typography>
          <Typography variant={'body1'} whiteSpace={'nowrap'}>
            {taxInTargetCurrency.toLocaleString()} {currencyCode}
          </Typography>
        </li>
        {!!priceInTargetCurrency && (
          <li className={'cart-summary__list-item'}>
            <Typography variant={'body1'} className={'cart-summary__title'}>
              {t('CartSummary:WithoutVAT')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {priceInTargetCurrency.toLocaleString()} {currencyCode}
            </Typography>
          </li>
        )}
      </ul>
    </CartSummaryStyledContainer>
  );
};

export default CartSummary;
