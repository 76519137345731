import { styled } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import React from 'react';

const CostCellContainer = styled('div')`
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

const RubbleCostCell = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;

  return <CostCellContainer>{row.rublesAmount} RUB</CostCellContainer>;
};

export { RubbleCostCell };
