import breakpoints from '@assets/theme/breakpoints';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';

export const CustomsPaymentsStyledContainer = styled(Box)`
  padding: 10px;

  @media (min-width: ${breakpoints.values.xsm}px) {
    padding: 20px;
  }

  .customs-payment-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .customs-mode-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
      width: 60%;
    }
  }

  .customs-mode {
    width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      width: 90%;
    }
  }

  .contract-currency {
    width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      width: 45%;
    }
  }

  .delivery-cost-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
      width: 60%;
      margin-top: 0;
	  gap: 20px;
    }
  }

  .delivery-cost-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${breakpoints.values.xsm}px) {
      width: 70%;
      justify-content: flex-start;
    }
  }

  .delivery-cost-fields-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
	  width: 90%;
    }
  }

  .other-cost-wrap {
	display: flex;
    width: 100%;
    flex-direction: column;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
      width: 60%;
	  gap: 20px;
    }
  }

  .cost-field-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
	margin-bottom: 20px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      width: 55%;
	  flex-direction: row;
	  margin-bottom: 0;
    }
  }

  .cost-field-label {
    text-align: center;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 3px;
    width: 100%;
  }

  .cost-field-input {
    margin: 0 !important;
  }

  .other-expenses-wrap {
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
      margin: 0 0 30px 0;
    }
  }

  .other-expenses-fields {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
      width: 60%;
    }
  }

  .add-products-btn {
    margin-top: 30px;
    color: #fff;
    margin-left: auto;
    width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      width: auto;
      margin-top: 0;
    }
  }

  .video-instruction {
    text-align: center;

    @media (min-width: ${breakpoints.values.xsm}px) {
      text-align: end;
    }
  }
`;

export const CustomsPaymentsDialogContainer = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .mobile__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
