import {
  ServicesAggregateProps,
  ServicesQueryParamsProps,
} from '@services/requests/servicesService/interface';
import { ServicesDirectoriesAggregateQuery } from '@services/requests/servicesService/servicesDirectoriesService/requests/ServicesDirectoriesAggregateQuery';
import { ServicesDirectoriesQuery } from '@services/requests/servicesService/servicesDirectoriesService/requests/ServicesDirectoriesQuery';
import { ServicesRootsDirectoriesQuery } from '@services/requests/servicesService/servicesDirectoriesService/requests/ServicesRootsDirectoriesQuery';
import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import {
  LoadAllServicesDirectoriesProps,
  ServicesDirProps,
  ServicesDirectoriesAggregateQueryResponseProps,
  ServicesDirectoriesQueryResponseProps,
  ServicesDirectoriesServiceProps,
} from './interface';

// Сервис загрузки директорий дополнительных услуг
class ServicesDirectoriesService implements ServicesDirectoriesServiceProps {
  private readonly logger: Logger;

  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   */
  constructor(token?: string) {
    this.logger = loggerFactory().make(`ServiceDirectoryLoader`);
    this.client = graphQLClient(token);
  }

  /**
   * Агрегирующий запрос сущностей директорий дополнительных услуг
   */
  async LoadAggregate(): Promise<ServicesAggregateProps> {
    try {
      const response = await this.client.Query<
        null,
        ServicesDirectoriesAggregateQueryResponseProps
      >(new ServicesDirectoriesAggregateQuery(), {});

      this.logger.Debug(`Loaded aggregate info of services directories`, response);

      return response.servicesdir_aggregate[0];
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка массива директорий по переданному фильтру params
   * @param params
   */
  async Load(params: ServicesQueryParamsProps): Promise<ServicesDirProps[]> {
    try {
      const response = await this.client.Query<
        ServicesQueryParamsProps,
        ServicesDirectoriesQueryResponseProps
      >(new ServicesDirectoriesQuery(params), {});

      this.logger.Debug(`Loaded list of services directories`, response);

      return response.servicedirectory_list;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка массива корневых директорий
   */
  async LoadRoots(): Promise<ServicesDirProps[]> {
    try {
      const response = await this.client.Query<
        ServicesQueryParamsProps,
        ServicesDirectoriesQueryResponseProps
      >(new ServicesRootsDirectoriesQuery(), {});

      this.logger.Debug(`Loaded root list of services`, response);

      return response.servicedirectory_list;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка всех доступных директорий.
   * Сначала мы получаем агрегацию, это дает нам общее количество. Затем мы должны установить лимит в запросе
   * @param limit
   * @param offset
   */
  async LoadAll(limit?: number, offset?: number): Promise<LoadAllServicesDirectoriesProps> {
    try {
      const params: ServicesQueryParamsProps = {
        limit,
        offset,
      };

      const allServicesCount = await this.LoadAggregate();
      const response = await this.Load(params);

      return {
        count: allServicesCount.count,
        items: response,
      };
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}

export default ServicesDirectoriesService;
