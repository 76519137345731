import { styled } from '@mui/material/styles';

export const CustomsPaymentsTableMobileContainer = styled('div')`
  display: flex;
  flex-direction: column;

  .customs-table-item {
    padding: 20px;
    border-bottom: 1px solid #dbe0ec;
    &:last-child {
      border-bottom: none;
    }

    &--footer {
      background-color: #dbe0ec;

      .customs-table-item__result-cost {
        font-weight: 600 !important;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      letter-spacing: 0.02em;
      color: #000;
    }

    &__body {
      margin-top: 15px;
    }

    &__result-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.12px;
      }
    }
  }
`;
