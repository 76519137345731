import { getFilesIds } from '@components/pages/Products/components/RouteProduct/useGetFilesFromRoute';
import getTaxNames, { TaxNameProps } from '@helpers/getTaxNames';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import OfferInCartContainer from '@pages/Basket/components/ServicesList/components/OrderList/components/OfferInCartContainer';
import { filesService } from '@services/requests/filesService';
import { FileData } from '@services/requests/filesService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { RouteResult } from '@services/requests/orderService/types';
import { isEqualsForMemo } from '@settings/services/types';
import React, { memo, useLayoutEffect, useState } from 'react';
import OrderListItem from 'src/pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem';

import useBasket from '../../../../useBasket';
import OrderListStyledContainer, { Container } from './StyledComponent';

// Пропсы компонента
export type OrderListProps = {
  products: OrderProduct[];
  currencyCode: string;
};

/**
 * Блок рендера
 * @param prevProps
 * @param nextProps
 */
const isEquals: isEqualsForMemo<OrderListProps> = (prevProps, nextProps) =>
  JSON.stringify([prevProps.products]) === JSON.stringify([nextProps.products]);

/**
 * Компонент вывода списка заказов
 * @param props
 */
const OrderList = (props: OrderListProps) => {
  const [baseData] = useDelivery().useBaseDate().useState();
  const { useOrderId, useOrder } = useBasket();
  const [orderId] = useOrderId().useState();
  const [order] = useOrder().useState();
  const { products, currencyCode } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogIsOpen, setDialogOpen] = useState(false);
  const [productId, setProductId] = useState('');
  const [files, setFiles] = useState<FileData[]>([]);
  const [taxNames, setTaxNames] = useState<TaxNameProps[]>([]);
  const orderDataWithProduct = () => {
    const product = order.products.find((product) => product.id === productId);

    if (!product) {
      return {
        ...order,
        date: '',
        products: [],
      };
    }

    const data: RouteResult = JSON.parse(product.data);

    return {
      ...order,
      date: data.date,
      products: [product],
    };
  };

  const orderWithProduct = orderDataWithProduct();

  useLayoutEffect(() => {
    setIsLoading(true);
    const filesToLoad = getFilesIds(orderWithProduct.products, baseData);
    filesService().LoadFilesById(filesToLoad).then(setFiles);
    getTaxNames('1').then(setTaxNames);
    setIsLoading(false);
  }, []);
  /**
   * Обработчик открытия детальной модалки
   * @param product
   */
  const detailClickHandler = (product: OrderProduct) => {
    if (!product.id) {
      return;
    }

    setDialogOpen(true);
    setProductId(product.id);
  };

  if (!baseData) {
    return <></>;
  }

  const showOfferCart = baseData && !!orderId && !!productId && !isLoading;
  return (
    <OrderListStyledContainer>
      {products.map((product, index) => (
        <OrderListItem
          additionData={baseData}
          product={product}
          currencyCode={currencyCode}
          onDetailClick={detailClickHandler}
          key={`order-item--${product.id || index}`}
          langId={order.language_id}
        />
      ))}
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={dialogIsOpen}
        onClose={() => setDialogOpen(false)}
        scroll={'body'}
      >
        <Container>
          <Box className="close-button">
            <IconButton onClick={() => setDialogOpen(false)}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          {showOfferCart && (
            <OfferInCartContainer
              files={files}
              taxNames={taxNames}
              order={order}
              baseData={baseData}
            />
          )}
        </Container>
      </Dialog>
    </OrderListStyledContainer>
  );
};

export default memo(OrderList, isEquals);
