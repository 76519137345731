import { Skeleton } from '@mui/material';
import useWidget from '@pages/AdditionalServices/context';
import SimpleAdditionalServiceItem from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceListing/components/SimpleAdditionalServiceListing/components/AdditionalItem';
import SortBlock from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceListing/components/SimpleAdditionalServiceListing/components/SortBlock';
import { useFilteringServices } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceListing/components/SimpleAdditionalServiceListing/useFilteringServices';
import React from 'react';

import Container from './StyledComponents';

const SimpleAdditionalServiceListing = () => {
  const { useAdditionalServices, useAdditionalInfo, useInit } = useWidget();
  const [{ currencies, taxes, contractors }] = useAdditionalInfo().useState();
  const [services] = useAdditionalServices().useState();
  const [ready] = useInit().useState();

  const items = useFilteringServices(services);

  if (!ready) {
    return (
      <Container>
        <Skeleton variant="rectangular" width={'100%'} height={'100px'} />
        <Skeleton variant="rectangular" width={'100%'} height={'100px'} />
        <Skeleton variant="rectangular" width={'100%'} height={'100px'} />
        <Skeleton variant="rectangular" width={'100%'} height={'100px'} />
        <Skeleton variant="rectangular" width={'100%'} height={'100px'} />
      </Container>
    );
  }

  return (
    <Container>
      <SortBlock />
      {items.map((service) => (
        <SimpleAdditionalServiceItem
          key={service.id}
          currencies={currencies}
          contractorsOptions={contractors}
          taxes={taxes}
          service={service}
        />
      ))}
    </Container>
  );
};

export default SimpleAdditionalServiceListing;
