import { styled } from '@mui/material';

export const AdditionalServiceSimpleContainer = styled('div', {
  name: 'AdditionalServiceSimpleContainer',
})`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 5px;
`;

export const AdditionalServiceIsVariantContainer = styled('div', {
  name: 'AdditionalServiceIsVariantContainer',
})`
  display: flex;
  flex-direction: column;

  .addToBasket {
    color: white;
    margin-top: 20px;
    align-self: flex-end;
  }
`;
