import palette from '@assets/theme/palette';
import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import { useMediaQuery } from '@material-ui/core';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip'; //
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ShareMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['RoutesCalculationWidget']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const href = window.location.href;
  const messageText = `${t('RoutesCalculationWidget:ShareCalculationText')} ${encodeURIComponent(
    href
  )}`;

  const openMenuHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  const telegramShareHandler = () => {
    closeMenuHandler();
    window.open(`https://t.me/share/url?text=${messageText}`);
  };

  const whatsAppShareHandler = () => {
    closeMenuHandler();
    window.open(`https://wa.me/?text=${messageText}`);
  };

  const viberShareHandler = () => {
    closeMenuHandler();
    window.open(`viber://forward?text=${messageText}`);
  };

  const emailShareHandler = () => {
    closeMenuHandler();
    window.open(
      `mailto:?subject=${t('RoutesCalculationWidget:ShareCalculationMailSubject')}&body=${t(
        'RoutesCalculationWidget:ShareCalculationMailText'
      )}: ${encodeURIComponent(href)}`
    );
  };

  return (
    <>
      <BitrixDisableUserGuard>
        <Tooltip title={t('RoutesCalculationWidget:ShareCalculationLink')}>
          <IconButton
            color="primary"
            size="small"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={openMenuHandler}
          >
            <ShareIcon style={{ color: palette.light.grey[700] }} />
          </IconButton>
        </Tooltip>
      </BitrixDisableUserGuard>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenuHandler}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={emailShareHandler}>Email</MenuItem>
        <MenuItem onClick={telegramShareHandler}>Telegram</MenuItem>
        {isMobile && <MenuItem onClick={viberShareHandler}>Viber</MenuItem>}
        <MenuItem onClick={whatsAppShareHandler}>WhatsApp</MenuItem>
      </Menu>
    </>
  );
}

export { ShareMenu };
