import { LOCALIZATION_DEPENDENCY } from '@context/useMyRouting/consts';

interface Fields {
  id: string;
  needPrint: string;
  token: string;
}

// Тип, описывающий промежуточный результат загрузки объекта коллекции
type ParsedUrl<T extends object> = {
  [K in keyof T]: string;
};

export function parseUrl(url: string): ParsedUrl<Fields> {
  const urlObject = new URL(url);
  const [_link, id] = urlObject.pathname.split('/').filter(Boolean);
  const [tokenString, needPrint] = urlObject.search.split('?needPrint=');
  const [_, token = ''] = tokenString.split('?token=');

  return {
    id,
    needPrint,
    token,
  };
}

export function getOuterLanguage() {
  const lang = document.documentElement.lang;

  return lang;
}

export function getOuterCurrencyCode() {
	const currencyCode = document.documentElement.dataset.currency;

	return currencyCode || LOCALIZATION_DEPENDENCY[0].currencyCode;
}

export function getLocalizationById(langId: string) {
  return LOCALIZATION_DEPENDENCY.find((local) => local.id === langId);
}
