import Tab from '@mui/material/Tab';
import { a11yProps } from '@pages/AdditionalServices/components/TubsContainer/helpers';
import useWidget from '@pages/AdditionalServices/context';
import { AdditionalServiceVariantsTabsContainer } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceVariantsTabs/StyledComponents';
import React, { useEffect, useState } from 'react';

const SortBlock = () => {
  const [sortItem, setSortItem] = useState<number>(0);
  const { useAdditionalServices, useLastDirectory, useBasketAdditionalService } = useWidget();
  const [_services, setServices] = useAdditionalServices().useState(false);
  const [basket] = useBasketAdditionalService().useState();
  const [lastDirectory] = useLastDirectory().useState();

  const handleChange = (_e: any, value: string) => {
    setSortItem(Number(value));
  };

  const sortable = lastDirectory.properties.reduce((acc, p, i) => {
    const jsonData = JSON.parse(p.settings);
    if (jsonData.sortable) {
      acc.push({
        value: i + 2,
        label: `Сортировка по свойству "${p.name}", от меньшего к большему.`,
        sortType: 'lower',
        sortEntityId: p.id,
      });
      acc.push({
        value: i + 3,
        label: `Сортировка по свойству "${p.name}", от большего к меньшему.`,
        sortType: 'upper',
        sortEntityId: p.id,
      });
    }

    return acc;
  }, []);

  const variants = [
    {
      value: 0,
      label: 'Сортировка по цене от меньшего к большему',
      sortType: 'upper',
      sortEntityId: 'amount',
    },
    {
      value: 1,
      label: 'Сортировка по цене от большего к меньшему',
      sortType: 'lower',
      sortEntityId: 'amount',
    },
    ...sortable,
  ];

  useEffect(() => {
    const currentItem = variants.find((v) => v.value === sortItem);

    if (currentItem.sortEntityId === 'amount') {
      setServices((prevState) => {
        const sortableServices = [...prevState].sort((a, b) => {
          const firstItem = basket[a.id]?.result?.result?.fullPriceInTargetCurrency || 0;
          const lastItem = basket[b.id]?.result?.result?.fullPriceInTargetCurrency || 0;

          if (currentItem.sortType === 'upper') {
            return firstItem - lastItem;
          } else {
            return lastItem - firstItem;
          }
        });

        return [...sortableServices];
      });
    } else {
      setServices((prevState) => {
        const sortableServices = [...prevState].reverse().sort((a, b) => {
          const firstItem = a.property_values.find(
            (i) => Number(i.property_id) === Number(currentItem.sortEntityId)
          );
          const lastItem = b.property_values.find(
            (i) => Number(i.property_id) === Number(currentItem.sortEntityId)
          );
          if (currentItem.sortType === 'upper') {
            return lastItem.value - firstItem.value;
          } else {
            return firstItem.value - lastItem.value;
          }
        });

        return [...sortableServices];
      });
    }
  }, [sortItem]);

  return (
    <AdditionalServiceVariantsTabsContainer
      variant="scrollable"
      scrollButtons="auto"
      value={sortItem}
      onChange={handleChange}
      aria-label="basic tabs example"
      className={'root-services-tab'}
    >
      {variants.map((variant, index) => (
        <Tab
          {...a11yProps(index + 1)}
          key={index + 1}
          value={variant.value}
          label={variant.label}
        />
      ))}
    </AdditionalServiceVariantsTabsContainer>
  );
};

export default SortBlock;
