import useWidget from '@pages/AdditionalServices/context';
import AdditionalServicesTable from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable';
import React from 'react';

const AdditionalServiceIsVariantListing = () => {
  const { useAdditionalServices, useAdditionalInfo } = useWidget();
  const [{ currencies, taxes, contractors }] = useAdditionalInfo().useState();
  const [services] = useAdditionalServices().useState();

  return (
    <div style={{ display: 'grid', gap: 30, gridTemplateColumns: '100%' }}>
      {services.map((service) => {
        const { id } = service;

        return (
          <AdditionalServicesTable
            key={id}
            taxes={taxes}
            currencies={currencies}
            contractorsOptions={contractors}
            service={service}
            services={services}
          />
        );
      })}
    </div>
  );
};

export default AdditionalServiceIsVariantListing;
