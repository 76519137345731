import { GridColDef } from '@mui/x-data-grid';
import AmountField from '../../AmountField';
import CountField from '../../CountField';
import ServicesName from '../../ServicesName';
import TaxAmountField from '../../TaxAmountField';
import TaxField from '../../TaxField';
import TotalField from '../../TotalField';

export const getTemplate1Columns = (fieldColumns: GridColDef[]) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      headerAlign: 'center',
      minWidth: 200,
      renderCell: ServicesName,
    },
    {
      field: 'count',
      headerName: 'Кол-во',
      headerAlign: 'center',
      minWidth: 100,
      renderCell: CountField,
    },
    ...fieldColumns,
    {
      field: 'cost',
      headerName: 'Стоимость',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      renderCell: AmountField,
    },
    // {
    //   field: 'sum',
    //   headerName: 'Сумма',
    //   headerAlign: 'center',
    //   width: 120,
    // },
    {
      field: 'course',
      headerName: 'Курс',
      headerAlign: 'center',
      width: 100,
      flex: 1,
      align: 'center',
    },
    // {
    //   field: 'conversePayment',
    //   headerName: 'Конверс. издержки',
    //   headerAlign: 'center',
    //   width: 120,
    // },
    // {
    //   field: 'sumConversePayment',
    //   headerName: 'Сумма в валюте платежа с учетом конверс. изд.',
    //   headerAlign: 'center',
    //   width: 120,
    // },
    {
      field: 'taxRate',
      headerName: 'Ставка налога',
      headerAlign: 'center',
      minWidth: 120,
      flex: 1,
      renderCell: TaxField,
    },
    {
      field: 'sumTax',
      headerName: 'Сумма налога',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      renderCell: TaxAmountField,
    },
    {
      field: 'total',
      headerName: 'Итого',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      renderCell: TotalField,
    },
  ];

  return columns;
};
