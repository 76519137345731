import { useYaMetrik } from '@context/useYaMetrik';
import { styled } from '@mui/material';
import { loggerFactory } from '@settings/services/logger';
import * as React from 'react';
import { PropsWithChildren, useContext } from 'react';
import BitrixUserContext from '@context/useBitrixUser';


interface YaTargetWrapperProps {
  target: string;
  noAuthTarget?: string;
}

const InterceptWrapper = styled('div')`
  position: relative;
`;

/*
Обертка по обработке яндекс местрики
 */
export const YaTargetWrapper: React.FC<PropsWithChildren<YaTargetWrapperProps>> = ({
  children,
  target,
  noAuthTarget,
}) => {
  const { useYaCounterId } = useYaMetrik();

  const [yaCounterId] = useYaCounterId().useState();
  const { user } = useContext(BitrixUserContext);

  const logger = loggerFactory().make(`YaTargetWrapper`);

  const isNoAuthTarget = !user && noAuthTarget;

  const clickHandler = () => {
    try {
      if (typeof yaCounterId !== 'number') {
        return;
      }

      if ('ym' in window) {
        (window.ym as any)(yaCounterId, 'reachGoal', isNoAuthTarget ? noAuthTarget : target);
      }
    } catch (e) {
      logger.Error(`Error:`, e);
    }
  };

  return <InterceptWrapper onClickCapture={clickHandler}>{children}</InterceptWrapper>;
};
