import BitrixAuth from '@context/useBitrixUser/BitrixAuth';
import { LinearProgress } from '@material-ui/core';
import OrderPage from '@pages/Order';
import PreOrderPage from '@pages/PreOrder';
import ErrorBoundary from '@settings/ErrorBoundary';
import ErrorController from '@settings/ErrorController';
import { SnackbarProvider } from 'notistack';
import React, { Suspense, useEffect } from 'react';

import Basket from '../../pages/Basket';
import Widget from '../../pages/MainWidget';
import TYOrder from '../../pages/TYOrder';
import useMyRouting from './useMyRouting';

export const pages = {
  widget: Widget,
  basket: Basket,
  'success-order': TYOrder,
  'pre-order': PreOrderPage,
  order: OrderPage,
};

export type Pages = keyof typeof pages;

const MyRouting = () => {
  const [page] = useMyRouting().usePage().useState();
  const { setPage } = useMyRouting().actions;
  const Page = pages[page];

  useEffect(() => {
    if (page === 'success-order') {
      setPage('basket');
    }
  }, []);

  useEffect(() => {

    const controllers = document.getElementById('dev-controllers');
    if (!controllers) {
      return;
    }
    if (page === 'pre-order' || page === 'order') {
      controllers.style.display = 'none';
    } else {
      controllers.style.display = 'flex';
    }
  }, [page]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <SnackbarProvider maxSnack={10} preventDuplicate={true} autoHideDuration={3000}>
        <ErrorBoundary needJSX={true}>
          <BitrixAuth>
            <Page />
          </BitrixAuth>
          <ErrorController />
        </ErrorBoundary>
      </SnackbarProvider>
    </Suspense>
  );
};

export default MyRouting;
