import 'dayjs/locale/ru';

import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CustomsPaymentsDetailingHeader() {
  const { t } = useTranslation(['CustomsPayments']);
  const dateCalculation = dayjs(new Date()).format('D MMMM YYYY ');

  return (
    <>
      <div className="header">
        <Typography variant={'h2'} className="heading">
          {t('CustomsPayments:DetailingTitle')}
        </Typography>
        <Typography variant={'subtitle1'} className="header__order" component={'div'}>
          {`№ ${1}`} {'dated'} {dateCalculation}
        </Typography>
      </div>
      <p className="customs-payments-description">
        {t('CustomsPayments:CalculationDescription.Part1')}
        <b>{t('CustomsPayments:CalculationDescription.PardBold')}</b>
        {t('CustomsPayments:CalculationDescription.Part2')}
      </p>
    </>
  );
}

export { CustomsPaymentsDetailingHeader };
