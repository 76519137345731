import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

export interface LoadHandbookPublishedList {
  id: string;
  name: string;
  handbook_id: string;
  version: string;
}

export interface LoadHandbookPublishedListResponse {
  handbookpublished_list: LoadHandbookPublishedList[];
}

/**
 * Запрос загрузки данных по валюте
 */
export class QueryHandbookPublishedList implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor() {
    this.variables = null;
    this.query = gql`
      query HandbookPublishedList {
        handbookpublished_list {
          id
          name
          handbook_id
          version
        }
      }
    `;
  }
}
