import { CustomsPaymentProduct } from './types';

export const DEFAULT_PRODUCT_DATA: CustomsPaymentProduct = {
  isCalculated: false,
  code: '',
  contractCurrencyCost: '',
  country: '156',
  weight: '0',
  originCertificateExisting: true,
  productsCount: '0',
  maximumRetailPackagePrice: '0',
  packagesCount: '0',
  total: {},
  selectedFee: null,
  selectedVat: null,
  selectedExcise: null,
  selectedExportFee: null,
  notes: [],
  customsMode: '1',
  borderDelivery: { cost: '0' },
  otherExpenses: { cost: '0' },
};

export const CHECK_EXCLUDE_KEYS = [
  'notes',
  'originCertificateExisting',
  'total',
  'borderDelivery',
  'otherExpenses',
];

export const VALIDATE_CHECKS = {
  isCalculated: true,
  code: /^\w+$/,
  contractCurrencyCost: /^\d+$/,
  country: /^\d+$/,
  weight: /^[1-9]\d*$/,
  productsCount: /^[1-9]\d*$/,
  maximumRetailPackagePrice: /^[1-9]\d*$/,
  packagesCount: /^[1-9]\d*$/,
};
