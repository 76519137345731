import CartSummary from '@components/pages/CartSummary';
import { ProductItemSummaryStyledContainer } from '@components/pages/Products/components/ProductItemSummary/StyledComponents';
import { ProductItemSummaryType } from '@components/pages/Products/components/ProductItemSummary/types';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import { formatPrice } from '@pages/OfferDetail/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ProductItemSummary: FC<ProductItemSummaryType> = ({
  summary,
  order,
  currencyCode,
  title,
}) => {
  const langCode = order?.language_id
    ? getLocalizationById(order.language_id).countryCode
    : undefined;
  const { t } = useTranslation(['OfferDetail', 'Common'], { lng: langCode });
  return (
    <ProductItemSummaryStyledContainer className={'cargo-desc'}>
      <CartSummary {...summary} currencyCode={currencyCode} />
      <div className={'cargo-desc'}>
        <span>
          {t('Common:Total')} {title}:
        </span>
        <span className={'cargo-desc__price'}>
          {formatPrice(summary.fullPriceInTargetCurrency).replace(',', '.')} {currencyCode}
        </span>
      </div>
    </ProductItemSummaryStyledContainer>
  );
};

export default ProductItemSummary;
