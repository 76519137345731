import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { styled } from '@mui/material/styles';

export const CustomsPaymentAdditionalTermsContainer = styled('section')`
  border-radius: 18px;
  border: 1px solid #dbe0ec;
  list-style: none;
  overflow: hidden;
  margin-bottom: 70px;

  .additional-terms-header {
    background-color: ${palette.light.grey[100]};
    padding: 16px 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
  }

  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.common.black};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .additional-terms-list {
    border-radius: 0 0 18px 18px;
    list-style: none;
  }

  .additional-terms-item {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    font-size: 14px;
    line-height: normal;

    &:last-child {
      border-bottom: 0;
    }
  }

  .link {
    padding: 15px 20px 13px;
    display: grid;
    gap: 25px;
    grid-template-columns: 31px 1fr;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.black};

    svg {
      width: 31px;
      height: 31px;
    }
  }
`;
