import {createContext} from "react";
import {BitrixUser} from "@services/AuthService/loadUserQuery";

export interface BitrixUserContextInterface {
  user: BitrixUser | null,

  showLogin(): void
}

const BitrixUserContext = createContext<BitrixUserContextInterface>({
  user: null,
  showLogin() {
  }
});

export default BitrixUserContext
