import TextField from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { SetterContext, StateUpdater } from '@settings/core/stateContexts/types';
import React, { useState } from 'react';

const CountField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const { useFieldStateServices, actions } = useWidget();
  const { recalculateService } = actions;
  const [states, setStates] = useFieldStateServices().useState();

  const countField = (
    Object.values(row).filter((value) => typeof value === 'object') as any[]
  ).find((prop) => prop.code.includes('countTemp4'));

  const [value, setValue] = useState<string>(countField?.value.toString());

  const setState = (value: SetterContext<FieldState>) => {
    if (typeof value !== 'function') {
      setStates((prevState) => ({
        ...prevState,
        [row.id]: value,
      }));

      recalculateService(String(row.id));
      return;
    }

    const stateUpdater = value as StateUpdater<FieldState>;
    setStates((prevState) => {
      return {
        ...prevState,
        [row.id]: stateUpdater(states[row.id]),
      };
    });

    recalculateService(String(row.id));
  };

  const onChangeCount = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (!countField) {
      return;
    }

    if (value === '0' || value === '') {
      setValue('0');
      setState((prevState: FieldState) => ({
        ...prevState,
        [countField.code]: {
          ...prevState[countField.code],
          value: '0',
        },
      }));
      return;
    }

    setValue(value);
    setState((prevState: FieldState) => {
      return {
        ...prevState,
        [countField.code]: {
          ...prevState[countField.code],
          value: value,
        },
      };
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        fullWidth
        size={'small'}
        InputLabelProps={{ shrink: true }}
        type={'number'}
        label={''}
        variant="outlined"
        value={value}
        onChange={onChangeCount}
      />
    </div>
  );
};

export default CountField;
