import useBaseDataContext from '@context/useBaseDataContext';
import BitrixUserContext from '@context/useBitrixUser';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import { useYaMetrik } from '@context/useYaMetrik';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import useBasket from '@pages/Basket/useBasket';
import React, { useContext, useEffect, useState } from 'react';

import { useCustomsPayments } from './AdditionalServices/tabs/CustomsPayments/components/CustomsPaymentsForm/context';
import { useBasketCounter } from './Basket/hooks/useBasketCounter';

const InitWidget = () => {
  const { user } = useContext(BitrixUserContext);

  const [isInit, setIsInit] = useState(false);

  const [settings] = useMyRouting().useSettings().useState();
  const initBaseData = useBaseDataContext().actions.init;
  const initBasket = useBasket().actions.init;
  const initDelivery = useDelivery().actions.init;
  const [page] = useMyRouting().usePage().useState();
  const initYaCounterId = useYaMetrik().actions.init;
  const initLocalization = useMyRouting().actions.init;
  // const { actions: customsPaymentsActions } = useCustomsPayments();
  // const { init } = customsPaymentsActions;

  useBasketCounter();

  const isDisableRoutesCalculation = page === 'basket';

  const isSettingsNotInited = Object.values(settings).some((setting) => !setting);

  useEffect(() => {
    initYaCounterId();
    initLocalization();
    // init();
  }, []);

  useEffect(() => {
    if (!isInit && !isSettingsNotInited) {
      setIsInit(true);
      initBasket(settings.primaryLanguageId, settings.currencyId, user?.id).then(() => {
        initDelivery(
          settings.primaryLanguageId,
          settings.secondaryLanguageId,
          settings.currencyId,
          isDisableRoutesCalculation
        ).then(() =>
          initBaseData(
            settings.primaryLanguageId,
            settings.secondaryLanguageId,
            settings.currencyId
          )
        );
      });
    }
  }, [settings, isSettingsNotInited, user]);

  return <></>;
};

export default InitWidget;
