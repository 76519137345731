import useWidget from '@pages/AdditionalServices/context';
import { ServicesProps } from '@services/requests/servicesService/servicesServicesService/interface';

export const useFilteringServices = (services: ServicesProps[]) => {
  const { useListingFilter, useBasketAdditionalService } = useWidget();
  const [filters] = useListingFilter().useState();
  const [basket] = useBasketAdditionalService().useState();

  if (Object.keys(filters).length === 0) {
    return services;
  }

  return services.filter((item) => {
        // Проверяем, соответствует ли каждый элемент условиям всех фильтров
        return Object.keys(filters).every((filterKey) => {
            const filter = filters[filterKey];
            if (filter.property.id === 'price') {
                const basketItem = basket[item.id];
                const value = basketItem?.result?.result?.basePrice || 0;
                if (!filter.value) {
                    return true;
                }
                // Для числового типа проверяем, входит ли значение в заданный диапазон
                return value >= filter.value.min && value <= filter.value.max;
            }
            const settings = JSON.parse(filter.property.settings);

      const value = item.property_values.find(
        (i) => Number(i.property_id) === Number(filterKey)
      )?.value;

            if (settings.multiple) {
                if (filter.handBookValues.length === 0) {
                    return true;
                }

                return filter.handBookValues.includes(value);
            }

            if (filter.typeComponent === 'switch') {
                if (filter.switchValue === null) {
                    return true;
                }

                return value === filter.switchValue;
            }

            if (!value) {
                return true;
            }

      if (filter.typeComponent === 'handbook') {
        if (!filter.handBookValue) {
          return true;
        }
        return value === filter.handBookValue;
      }

            if (filter.typeComponent === 'number') {
                if (!filter.value) {
                    return true;
                }
                // Для числового типа проверяем, входит ли значение в заданный диапазон
                return value >= filter.value.min && value <= filter.value.max;
            }

      // Если фильтр не задан, считаем условие выполненным
      return true;
    });
  });
};
