import { styled } from '@mui/material';

export const SummaryAdditionalServicesContainer = styled('div', {
  name: 'SummaryAdditionalServicesContainer',
})`
  margin-top: 40px;
  background: #eff3fb;
  border-radius: 18px;
  border: 1px solid #dbe0ec;

  .header {
    display: flex;
    flex-flow: column;
    padding: 0 14px;

    .item {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;

      .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      .value {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
  .footer {
    padding: 15px 50px;
    background: #3460b4;
    border-radius: 18px;
    display: flex;
    justify-content: space-between;

    .label {
      font-size: 20px;
      font-style: normal;
      color: white;
      font-weight: 800;
      line-height: 22px;
    }

    .value {
      font-size: 20px;
      font-style: normal;
      color: white;
      font-weight: 800;
      line-height: 22px;
    }
  }
`;
