import {FormControlLabel} from '@material-ui/core';
import useWidget from '@pages/AdditionalServices/context';
import {ContractorsOptions, FilterItem} from '@pages/AdditionalServices/context/types';
import {handbookService} from '@services/requests/handbookService';
import {CurrencyData} from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import {locationsLoader} from '@services/requests/searchLoaders/locationsLoader';
import {TaxData} from '@services/requests/taxLoader/TaxLoaderQuery';
import React, {useEffect, useState} from 'react';

import MRadio from '../../../../../../../../../../assets/mui/@material-extend/MRadio';

interface Props {
    entity: FilterItem;
    currentItem: number;
    currencies: CurrencyData[];
    taxes: TaxData[];
    contractors: ContractorsOptions;
    data:any
}

const getLabel = async (
    f: FilterItem,
    id: number,
    currencies: CurrencyData[],
    taxes: TaxData[],
    contractors: ContractorsOptions,
    data: any
) => {
    switch (f.property.type) {
        case 'hidden':
        case 'switch':
        case 'number':
            if (data.format) {
                return data.format.replace("{{VALUE}}", id);
            }
            return id;
        case 'handbook':
            const handBookValue = await loadCurrentHandbookItems(data.handbook_id, String(id)).then((r) => r);
            if (data.format) {
                return data.format.replace("{{VALUE}}", handBookValue);
            }
            return handBookValue;
        case 'location':
            const locationValue = await getDefaultLocations(String(id)).then((r) => r);
            if (data.format) {
                return data.format.replace("{{VALUE}}", locationValue);
            }
            return locationValue;
        case 'tax':
            const taxValue = taxes.find((i) => i.id === String(id)).default_name;
            if (data.format) {
                return data.format.replace("{{VALUE}}", taxValue);
            }
            return taxValue;
        case 'currency':
            const currencyValue = currencies.find((i) => i.id === String(id)).default_name;
            if (data.format) {
                return data.format.replace("{{VALUE}}", currencyValue);
            }
            return currencyValue;
        case 'certification_contractor':
        case 'consulting_contractor':
        case 'customs_contractor':
        case 'inspection_contractor':
        case 'insurance_contractor':
            const [type] = f.property.type.split('_');
            const contractorValue = contractors[type].find((i) => i.value === String(id))?.label;
            if (data.format) {
                return data.format.replace("{{VALUE}}", contractorValue)
            }
            return contractorValue;
        default:
            return '';
    }
};

const loadCurrentHandbookItems = async (handbookId: string, itemId: string) => {
    const filter = {
        where: {
            id: {
                _equals: handbookId,
            },
        },
    };
    const handBook = await handbookService().LoadPublishedListByParams(filter);

    if (handBook.length === 0) {
        return 'Пусто';
    }

    const itemName = handBook[0].items.find((i) => i.id === itemId)?.name;

    return itemName ? `${itemName}` : '';
};

const getDefaultLocations = async (_in: string) => {
    const temp = await locationsLoader().LoadByParams({where: {id: {_in: [_in]}}});
    return temp[0].default_name;
};

const GetItems = (props: Props) => {
    const {currentItem, entity, currencies, data, taxes, contractors} = props;
    const {useListingFilter} = useWidget();
    const [filterState, setFilterListing] = useListingFilter().useState();
    const [label, setLabel] = useState<string>('');
    const handleOnChange = () => {
        setFilterListing((prev) => ({
            ...prev,
            [entity.property.id]: {
                ...prev[entity.property.id],
                handBookValue: Number(props.currentItem),
            },
        }));
    };

    useEffect(() => {
        getLabel(entity, currentItem, currencies, taxes, contractors, data).then(setLabel);
    }, []);

    const chacked =
        Number(props.currentItem) === Number(filterState[props.entity.property.id].handBookValue);

    return (
        <FormControlLabel
            control={
                <MRadio
                    size={'small'}
                    className={'radio'}
                    checked={chacked}
                    onChange={handleOnChange}
                    sx={{padding: '5px 8px'}}
                />
            }
            classes={{
                label: 'radio-label',
            }}
            label={label}
        />
    );
};

export default GetItems;
