import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomsPayments } from '../../context';
import { CustomsPaymentTotalContainer } from './StyledComponents';

function CustomsPaymentTotal() {
  const { t } = useTranslation(['CustomsPayments']);
  const { useCustomsPaymentProducts } = useCustomsPayments();
  const [products] = useCustomsPaymentProducts().useState();

  const totalProducts = products.reduce(
    (prev, product) => {
      const { antiDumpingSum = 0, exciseSum = 0, feeSum = 0, vatSum = 0 } = product.total;
      return {
        feeSum: prev.feeSum + feeSum,
        exciseSum: prev.exciseSum + exciseSum,
        antiDumpingSum: prev.antiDumpingSum + antiDumpingSum,
        vatSum: prev.vatSum + vatSum,
      };
    },
    {
      feeSum: 0,
      exciseSum: 0,
      antiDumpingSum: 0,
      vatSum: 0,
    }
  );

  const isCalulationValid = Object.values(totalProducts).every((price) => isFinite(price));
  const totalResult = Object.values(totalProducts).reduce((prev, total) => prev + total, 0);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!isCalulationValid) {
    return <></>;
  }

  return (
    <>
      <Typography variant={'h2'} className="heading">
        {t('CustomsPayments:AllProductsTotalTitle')}
      </Typography>
      <CustomsPaymentTotalContainer className={'cargo-desc'}>
        <ul className="summary-list">
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsDuty')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.feeSum} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsExcise')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.exciseSum} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:AntidumpingDuty')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.antiDumpingSum} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsVAT')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.vatSum} RUB
            </Typography>
          </li>
        </ul>
        <div className={'cargo-desc'}>
          <span className="cargo-desc__text">
            {isMobile
              ? t('CustomsPayments:TotalProductsPriceMobile')
              : t('CustomsPayments:TotalProductsPriceDesktop')}
            :
          </span>
          <span className={'cargo-desc__price'}>{totalResult.toFixed(2)} RUB</span>
        </div>
      </CustomsPaymentTotalContainer>
    </>
  );
}

export { CustomsPaymentTotal };
