import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { styled } from '@mui/material/styles';

export const ProductItemSummaryStyledContainer = styled('section')`
  background-color: ${palette.light.grey[100]};
  border-radius: 18px;

  .cargo-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${palette.light.blue[400]};
    padding: 20px 50px;
    border-radius: 18px;
    font-size: 20px;
    box-sizing: border-box;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-transform: uppercase;

    &-item {
      display: flex;
      justify-content: space-between;
      padding: 19px 20px 18px 14px;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      font-size: 18px;
      flex-direction: column;
      padding: 16px;
      text-align: center;
    }
  }
`;
