import useWidget from '@pages/AdditionalServices/context';
import { FilterItem } from '@pages/AdditionalServices/context/types';
import { useEffect } from 'react';

const useFilterController = () => {
  const {
    useListingFilter,
    useDefaultListingFilter,
    useAdditionalServices,
    useBasketAdditionalService,
  } = useWidget();
  const [filterListing, setFilterListing] = useListingFilter().useState();
  const [services] = useAdditionalServices().useState();
  const [defaultFilter, setDefaultFilterListing] = useDefaultListingFilter().useState();
  const servicesIds = services.map((s) => s.id);
  const [basket] = useBasketAdditionalService().useState(servicesIds);

  const reset = () => {
    setFilterListing((prevState) =>
      Object.keys(prevState).reduce((acc, key) => {
        acc = {
          ...acc,
          [key]: {
            ...prevState[key],
            value: defaultFilter[key].value,
            handBookValue: defaultFilter[key].handBookValue,
            switchValue: defaultFilter[key].switchValue,
            handBookValues: defaultFilter[key].handBookValues,
          },
        };

        return acc;
      }, {})
    );
  };

  useEffect(() => {
    const allProperties = services.map((s) => s.allProperties).flat();
    const allPropertiesValues = services.map((s) => s.property_values).flat();

    const merge = allProperties.reduce((acc, property) => {
      const { id, type, settings } = property;
      const property_values = allPropertiesValues.filter(
        (i) => Number(i.property_id) === Number(id)
      );
      const settingsData = JSON.parse(settings);
      const simpleValues = property_values.map((v) => v.value);
      const isNumberProperty = type === 'hidden' || type === 'number';
      const isSwitch = type === 'switch';
      let typeComponent = 'handbook';

      if (isNumberProperty) {
        typeComponent = 'number';
      }

      if (isSwitch) {
        typeComponent = 'switch';
      }

      if (settingsData.multiple) {
        typeComponent = 'multiple';
      }

      const switchValue = filterListing[id]?.switchValue || null;
      const handBookValue = filterListing[id]?.handBookValue || null;
      const handBookValues = filterListing[id]?.handBookValues || [];
      const value = filterListing[id]?.value
        ? filterListing[id]?.value
        : isNumberProperty
        ? {
            min: Math.min(...simpleValues),
            max: Math.max(...simpleValues),
          }
        : null;

      acc = {
        ...acc,
        [id]: {
          property,
          property_values,
          typeComponent,
          handBookValues,
          switchValue,
          value,
          handBookValue,
        },
      };

      return acc;
    }, {});

    const mergeDefault = allProperties.reduce((acc, property) => {
      const { id, type, settings } = property;
      const property_values = allPropertiesValues.filter(
        (i) => Number(i.property_id) === Number(id)
      );
      const settingsData = JSON.parse(settings);
      const simpleValues = property_values.map((v) => v.value);
      const isNumberProperty = type === 'hidden' || type === 'number';
      const isSwitch = type === 'switch';
      let typeComponent = 'handbook';

      if (isNumberProperty) {
        typeComponent = 'number';
      }

      if (isSwitch) {
        typeComponent = 'switch';
      }

      if (settingsData.multiple) {
        typeComponent = 'multiple';
      }

      const switchValue = null;
      const handBookValue = null;
      const handBookValues = [];
      const value = isNumberProperty
        ? {
            min: Math.min(...simpleValues),
            max: Math.max(...simpleValues),
          }
        : null;

      acc = {
        ...acc,
        [id]: {
          property,
          property_values,
          typeComponent,
          handBookValues,
          switchValue,
          value,
          handBookValue,
        },
      };

      return acc;
    }, {});

    setFilterListing((prev) => ({
      ...prev,
      ...merge,
    }));
    setDefaultFilterListing((prev) => ({
      ...prev,
      ...mergeDefault,
    }));
  }, [services]);

  useEffect(() => {
    const currentServices = servicesIds.map((id) => basket[id]);

    const property_values = currentServices.reduce((acc, bs, id) => {
      if (bs?.result?.result) {
        acc.push({
          id,
          property_id: 'price',
          service_id: bs.serviceId,
          value: bs?.result?.result.basePrice,
        });
      }

      return acc;
    }, []);

    const price: FilterItem = {
      property: {
        description: '',
        id: 'price',
        localized_descriptions: [],
        localized_names: [],
        name: 'Цена',
        ordinal: 0,
        settings: JSON.stringify({ sortable: true }),
        type: 'number',
      },
      property_values,
      typeComponent: 'number',
      value: {
        min: 0,
        max: Math.max(...property_values.map((v) => v.value)),
      },
      handBookValue: null,
      handBookValues: [],
      switchValue: null,
    };

    if (property_values.length > 1) {
      setFilterListing((prev) => ({
        ...prev,
        price,
      }));

      if (!defaultFilter['price']) {
        setDefaultFilterListing((prev) => ({
          ...prev,
          price,
        }));
      }
    }
  }, [basket]);

  return { filterListing, reset };
};

export default useFilterController;
