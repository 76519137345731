import { CustomsPaymentProduct } from '@pages/AdditionalServices/tabs/CustomsPayments/components/CustomsPaymentsForm/context/types';

export const getCustomsPaymentWeight = (
  isSpecialFieldsVisible: boolean,
  isWeightFieldVisible: boolean,
  productData: CustomsPaymentProduct
) => {
  const { weight, packagesCount, productsCount, maximumRetailPackagePrice } = productData;

  if (isWeightFieldVisible) {
    return `${+weight / 1000} кг, ${productsCount} товаров`;
  } else if (isSpecialFieldsVisible) {
    return `${productsCount} товаров, ${maximumRetailPackagePrice} МРЦ упаковки, ${packagesCount} упаковок`;
  } else {
    return `${productsCount} товаров`;
  }
};
