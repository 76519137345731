import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const RowContainer = styled('div')`
  display: flex;
  width: 100%;

  .cell {
    position: relative;
    padding: 0 10px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #dbe0ec;
  }

  .no-border {
    border-color: transparent;
  }

  .cell-text {
    margin: 0 calc(-100% - 30px);
    width: calc(100% * 3);
    text-align: center;
  }
`;

export const TableContainer = styled(Box)`
  width: 100%;

  .MuiDataGrid-cell {
    &:not(.MuiDataGrid-cell--withRightBorder) {
      display: none;
    }
  }

  .MuiDataGrid {
    &-row,
    &-virtualScrollerRenderZone {
      min-width: 100%;
    }
    &-columnHeader {
      &:last-child {
        border-right: 0;
      }
    }
  }
`;

export default RowContainer;
