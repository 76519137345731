import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { Filter } from '../../../types';

/**
 * Параметры для получения дополнительной услуги по фильтрам
 */
export interface LoadPublishedHandbookParams {
  limit?: number;
  offset?: number;
  where?: {
    id: Filter<string>;
  };
}

export interface LoadPublishedHandbookList {
  id: string;
  name: string;
  items: {
    id: string;
    name: string;
  }[];
}

export interface LoadPublishedHandbookListResponse {
  handbookpublished_list: LoadPublishedHandbookList[];
}

/**
 * Запрос загрузки данных по локациям
 */
export class QueryHandbookPublishedListByParams
  implements GraphQLQuery<LoadPublishedHandbookParams>
{
  readonly query: any;

  readonly variables: LoadPublishedHandbookParams;

  constructor(params: LoadPublishedHandbookParams) {
    this.variables = {
      ...params,
    };
    this.query = gql`
      query LoadPublishedHandbookList(
        $limit: Int = 60
        $offset: Int = 0
        $where: handbookpublished_query_where_object
      ) {
        handbookpublished_list(
          limit: $limit
          offset: $offset
          where: $where
          order: [{ by: id, direction: desc, priority: 1 }]
        ) {
          id
          name
          items {
            id
            name
          }
        }
      }
    `;
  }
}
