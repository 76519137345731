import { styled } from '@mui/material/styles';

export const TaxCellStyledContainer = styled('div', { name: 'TaxCellStyledContainer' })`
  display: grid;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const TaxServiceCellStyledContainer = styled('div', { name: 'TaxCellStyledContainer' })`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 100%;

  span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .with-right-border {
    border-right: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }
`;
