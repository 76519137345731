import useWidget from '@pages/AdditionalServices/context';
import {ContractorsOptions, FilterItem} from '@pages/AdditionalServices/context/types';
import {handbookService} from '@services/requests/handbookService';
import {CurrencyData} from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import {locationsLoader} from '@services/requests/searchLoaders/locationsLoader';
import {TaxData} from '@services/requests/taxLoader/TaxLoaderQuery';
import React, {memo, useEffect, useState} from 'react';
import CheckboxGrid from "@components/UI/CheckboxGrid";
import {ServicesPropertyValue} from "@services/requests/servicesService/servicesServicesService/interface";

interface Props {
    entity: FilterItem;
    values: number[]
    options: ServicesPropertyValue[]
    currencies: CurrencyData[];
    taxes: TaxData[];
    contractors: ContractorsOptions;
    data: any;
}

const getLabel = async (
    f: FilterItem,
    id: number,
    currencies: CurrencyData[],
    taxes: TaxData[],
    contractors: ContractorsOptions
) => {
    switch (f.property.type) {
        case 'hidden':
        case 'switch':
        case 'number':
            return null;
        case 'handbook':
            const data = JSON.parse(f.property.settings);

            return await loadCurrentHandbookItems(data.handbook_id, String(id)).then((r) => r);
        case 'location':
            return await getDefaultLocations(String(id)).then((r) => r);
        case 'tax':
            return taxes.find((i) => i.id === String(id)).default_name;
        case 'currency':
            return currencies.find((i) => i.id === String(id)).default_name;
        case 'certification_contractor':
        case 'consulting_contractor':
        case 'customs_contractor':
        case 'inspection_contractor':
        case 'insurance_contractor':
            const [type] = f.property.type.split('_');
            return contractors[type].find((i) => i.value === String(id))?.label || '';
        default:
            return '';
    }
};

const loadCurrentHandbookItems = async (handbookId: string, itemId: string) => {
    const filter = {
        where: {
            id: {
                _equals: handbookId,
            },
        },
    };
    const handBook = await handbookService().LoadPublishedListByParams(filter);

    if (handBook.length === 0) {
        return 'Пусто';
    }

    const itemName = handBook[0].items.find((i) => i.id === itemId)?.name;

    return itemName ? `${itemName}` : '';
};

const getDefaultLocations = async (_in: string) => {
    const temp = await locationsLoader().LoadByParams({where: {id: {_in: [_in]}}});
    return temp[0].default_name;
};

const GetItemsForMultiple = (props: Props) => {
    const {values, options, entity,data, currencies, taxes, contractors} = props;
    const {useListingFilter} = useWidget();
    const setFilterListing = useListingFilter().setState;
    const [items, setItems] = useState<{ value: number; title: React.ReactNode; description?: React.ReactNode }[]>([]);

    useEffect(() => {
        const processItems = async () => {
            const itemsPromises = options.map(async (i) => {
                const title = await getLabel(entity, i.value, currencies, taxes, contractors);

                if (data.format) {
                    return {
                        value: i.value,
                        title: data.format.replace("{{VALUE}}", title),
                    };
                }
                return {
                    value: i.value,
                    title,
                };
            });

            return await Promise.all(itemsPromises);
        };

        processItems().then(setItems);
    }, [options, entity, currencies, taxes, contractors]);

    return (
        <CheckboxGrid<number>
            items={items}
            values={values}
            onChange={(values) => {
                setFilterListing((prev) => ({
                    ...prev,
                    [props.entity.property.id]: {...prev[props.entity.property.id], handBookValues: values},
                }))
            }}
        />
    );
};

export default memo(GetItemsForMultiple);
