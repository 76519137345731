import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { SearchResult } from '../locationsAndTerminalSearchService/types';
import { BranchItem, _LocationsBranchServiceProcessorInterface } from './interfaces';
import { LoadByLocationBranchProcessor } from './LoadByLocationBranchProcessor';
import { TerminalByIdQuery, TerminalByIdQueryResult } from './TerminalByIdQuery';

/**
 * Процессор получения ветки для терминала
 */
export class LoadByTerminalProcessor implements _LocationsBranchServiceProcessorInterface {
  private readonly client: GraphQLClient;

  private readonly loadByLocationProcessor: _LocationsBranchServiceProcessorInterface;

  /**
   * Конструктор сервиса
   * @param token
   */
  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.loadByLocationProcessor = new LoadByLocationBranchProcessor(token);
  }

  /**
   * Поиск ветки локаций процессором
   * @param items
   */
  async GetBranch(items: SearchResult[]): Promise<BranchItem[]> {
    try {
      const resp = await this.client.Query<{ id: string[] }, TerminalByIdQueryResult>(
        new TerminalByIdQuery(items.map((i) => i.id)),
        {}
      );

      if (resp.transport_terminal_list.length === 0) {
        return [];
      }

      const parentBranch = await this.loadByLocationProcessor.GetBranch(
        resp.transport_terminal_list.map(
          (term) =>
            ({
              id: term.location_id,
              type: 'location',
            } as SearchResult)
        )
      );

      const terminals = resp.transport_terminal_list.map(
        (term) =>
          ({
            id: term.id,
            type: 'terminal',
            isUserSearchable: true,
            visibleName: term.default_name,
            iso: term.iso,
            symbolCode: '',
            localizedNames: term.localized_names,
            localizedIso: term.iso_localization,
            localizedNamesArray: [],
            localizedIsoArray: [],
            parentId: term.location_id,
            subItems: [],
            files: [...term.files],
            coords: {
              lat: term.lat,
              lng: term.lng,
            },
          } as BranchItem)
      );

      return [...parentBranch, ...terminals];
    } catch {
      return [];
    }
  }

  /**
   * Проверка доступности процессора
   * @param type
   */
  isAvailable(type: 'location' | 'terminal' | 'childrenTerminal'): boolean {
    return type === 'terminal';
  }
}
