import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material/styles';

export const CustomsPaymentsControlContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${breakpoints.values.xsm}px) {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
  }

  .btn {
    height: 45px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 5px 10px;
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  .add-to-favorites {
	order: 3;
    max-width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      max-width: 150px;
	  order: 1;
    }
  }

  .open-details {
	order: 3;
    max-width: 100%;

    @media (min-width: ${breakpoints.values.xsm}px) {
      max-width: 150px;
	  order: 1;
    }
  }

  .download-pdf {
    max-width: 100%;
	order: 2;

    @media (min-width: ${breakpoints.values.xsm}px) {
      max-width: 150px;
    }
  }

  .add-to-basket {
    max-width: 100%;
    color: #fff;
	order: 1;

    @media (min-width: ${breakpoints.values.xsm}px) {
      max-width: 216px;
	  order: 3;
    }
  }
`;
