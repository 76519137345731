// @flow
import * as React from 'react';
import { PropsWithChildren,  useContext} from "react";
import {styled} from "@mui/material";
import BitrixUserContext from '@context/useBitrixUser';


const Guard = styled('div')`
  position: relative;
  
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    cursor: pointer
  }
  
  > * {
    point-events: none;
  }
`
/*
Страж по запрету взаимодействию с интерфесом для не авторизованных пользователей
 */
export const BitrixDisableUserGuard: React.FC<PropsWithChildren> =({children}) => {
  const {user, showLogin} = useContext(BitrixUserContext);

  const guardHandler = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    showLogin();
  }

  if (user) {
    return <>{children}</>
  }

  return (
    <Guard onClick={guardHandler} onClickCapture={guardHandler} >
      {children}
    </Guard>
  );
};


/*
Страж по Сокрытию UI для не авторизованных пользователей
 */
export const BitrixHiddeUserGuard: React.FC<PropsWithChildren> = ({children}) => {
  const {user} = useContext(BitrixUserContext);

  if (!user) {
    return null;
  }

  return (
    <>{children}</>
  )
}