import BitrixUserContext from '@context/useBitrixUser';
import useBasket from '@pages/Basket/useBasket';
import { useContext, useEffect } from 'react';

function useBasketCounter() {
  const [order] = useBasket().useOrder().useState();
  const { user } = useContext(BitrixUserContext);

  const items = order.products.length;

  useEffect(() => {
    const basketCounterContainerNodes = document.querySelectorAll('[data-entity="basketCount"]');

    if (!basketCounterContainerNodes.length || !user) return;

    if (!!items) {
      basketCounterContainerNodes.forEach((node) => {
        node.innerHTML = `<span class='counter'>${items}</span>`;
      });
    } else {
      basketCounterContainerNodes.forEach((node) => {
        node.innerHTML = '';
      });
    }
  }, [items, user]);
}

export { useBasketCounter };
