import { MOCK_ADDITIONAL_TERMS_FILES } from '@pages/AdditionalServices/tabs/CustomsPayments/consts';
import React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useTranslation } from 'react-i18next';

import { CustomsPaymentAdditionalTermsContainer } from './StyledComponents';

function CustomsPaymentsAdditionalTerms() {
  const { t } = useTranslation(['CustomsPayments']);
  const fileExtension: string = 'pdf';
  const fileName = 'Дополнительные тарифы OnlogSystem';

  return (
    <CustomsPaymentAdditionalTermsContainer>
      <div className="additional-terms-header">{t('CustomsPayments:AdditionalTerms')}</div>
      <ul className="additional-terms-list">
        {MOCK_ADDITIONAL_TERMS_FILES.map((file) => (
          <li key={'additional-term-' + file} className="additional-terms-item">
            <a href={'/temp-url'} target="_blank" rel="noreferrer" className={'link'}>
              <FileIcon
                id={file.toString()}
                size={48}
                extension={fileExtension}
                // @ts-ignore
                {...(defaultStyles[fileExtension] || {})}
              />
              <span>{fileName}</span>
            </a>
          </li>
        ))}
      </ul>
    </CustomsPaymentAdditionalTermsContainer>
  );
}

export { CustomsPaymentsAdditionalTerms };
