import { MButton } from '@assets/mui/@material-extend';
import palette from '@assets/theme/palette';
import { useMediaQuery } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { customsTaxService } from '@services/requests/customsTaxService';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomsPayments } from '../../context';
import { CustomsPaymentProduct } from '../../context/types';
import { ProductHelpItem } from '../ProductHelpItem';

interface ProductHelpProps {
  product: CustomsPaymentProduct;
  productIndex: number;
}

function ProductHelp({ product, productIndex }: ProductHelpProps) {
  const { t } = useTranslation(['CustomsPayments']);
  const { useBaseCustomsPaymentConfig, useCustomsPaymentProducts } = useCustomsPayments();
  const setProduct = useCustomsPaymentProducts().setState;
  const [expandedProductHelps, setExpandedProductHelps] = useState<number[]>([]);
  const [isProductHelpExpanded, setIsProductHelpExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [{ customsMode }] = useBaseCustomsPaymentConfig().useState();

  const { code, country, originCertificateExisting, notes } = product;

  useEffect(() => {
    const fetchFeatures = async () => {
      setIsLoading(true);
      setExpandedProductHelps([]);

      const formattedCustomsMode = customsMode === '1' ? 'import' : 'export';
      const formattedCertificate = originCertificateExisting ? '1' : '0';

      const features = await customsTaxService().LoadFeatures({
        mode: formattedCustomsMode,
        tncode: code,
        certificate: formattedCertificate,
        country,
      });

      setProduct((prevState) => {
        const stateCopy = prevState.slice();

        const productCopy = { ...stateCopy[productIndex] };

        productCopy.notes = features ?? [];

        stateCopy.splice(productIndex, 1, productCopy);

        return stateCopy;
      });
      setIsLoading(false);
    };

    setIsProductHelpExpanded(false);
    fetchFeatures();
  }, [customsMode, originCertificateExisting, code, country]);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExpandNotesButtonDisabled = !notes.length;

  const expandAllHandler = () => {
    setExpandedProductHelps(notes.map((_, index) => index));
  };

  const collapseAllHandler = () => {
    setExpandedProductHelps([]);
  };

  const toggleExpandItemHandler = (item: number) => {
    setExpandedProductHelps((prevState) => {
      if (!prevState.includes(item)) {
        return [...prevState, item];
      }

      return prevState.filter((i) => item !== i);
    });
  };

  const toggleProductHelpExpandHandler = () => {
    setIsProductHelpExpanded((prevState) => !prevState);
  };

  const ExpandIcon = isProductHelpExpanded ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <div className="product-help-wrap">
      <MButton
        className={'get-rates-btn product-help-expand'}
        variant={'outlined'}
        color={'secondary'}
        fullWidth={true}
        disabled={isExpandNotesButtonDisabled || isLoading}
        endIcon={
          <ExpandIcon
            fontSize="medium"
            style={{
              color:
                isExpandNotesButtonDisabled || isLoading ? 'rgba(145, 158, 171, 0.8)' : '#3460B4',
            }}
          />
        }
        onClick={toggleProductHelpExpandHandler}
      >
        {isLoading && (
          <CircularProgress
            size={20}
            style={{ color: 'rgba(145, 158, 171, 0.8)', marginRight: '20px' }}
          />
        )}
        {t('CustomsPayments:AdditionalProductInformation')}
      </MButton>
      {isProductHelpExpanded && (
        <>
          <div className="product-help-control">
            <Link
              className="product-help-control-btn"
              color={palette.light.blue[400]}
              target="_blank"
              underline={'none'}
              onClick={expandAllHandler}
            >
              {isMobile && <KeyboardDoubleArrowDownIcon fontSize="small" />}
              {t('CustomsPayments:ExpandAllBtn')}
            </Link>
            <Link
              className="product-help-control-btn"
              color={palette.light.blue[400]}
              target="_blank"
              underline={'none'}
              onClick={collapseAllHandler}
            >
              {isMobile && <KeyboardDoubleArrowUpIcon fontSize="small" />}
              {t('CustomsPayments:CollapseAllBtn')}
            </Link>
          </div>
          <div className="product-help-list">
            {notes.map((note, index) => (
              <ProductHelpItem
                key={'product-help-item-' + index}
                note={note}
                id={index}
                isExpanded={expandedProductHelps.includes(index)}
                onToggleExpandItem={toggleExpandItemHandler}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export { ProductHelp };
