import {
  FilterData,
  SortData,
} from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import { RouteWidgetResult } from '@services/requests/routeCalculator/routesCalculationService/types';

import { RouteWidgetParams } from './types';

export const FLOAT_NUMBER_REGEXP = /^\d+(,|\.)\d+$/;

//\d+(?=((,|\.)\d+))\1

const DELIVERY_FORM_CONSTANTS = {
  settings: {
    currencyId: '',
    primaryLanguageId: '',
    secondaryLanguageId: '',
  },
  routeParams: {
    cargoType: undefined,
    containerParameters: {
      containerParameters: [
        {
          containerId: null,
          containerType: '2',
          containerQuantity: 1,
          eachContainerWeight: 20000,
        },
      ],
      isCustomConditionsOpen: false,
      isDangerousCargo: false,
      targetCurrencyId: '1',
    },
    customCargoParameters: {
      customCargoParameters: [
        {
          parametersType: 'base',
          parametersCargoCalcType: 'base',
          baseParameters: {
            quantity: 1,
            length: '1',
            width: '1',
            height: '1',
            weight: '10',
          },
          palletParameters: {
            type: '0',
            quantity: 1,
            normativeHeightOfStacking: '1',
            weight: 10000,
          },
          volumeParameters: {
            volume: '1',
            weight: '10',
            height: 1,
          },
        },
      ],
      isCustomConditionsOpen: false,
      isDangerousCargo: false,
      targetCurrencyId: '1',
    },
    endLocation: undefined,
    endTransportingCondition: '',
    isNeedContainerRent: true,
    isDangerCargo: false,
    isNeedPrekeridge: false,
    requiredTransportingTypes: [],
    startExpeditionDate: new Date(),
    startLocation: undefined,
    startTransportingCondition: '',
  } as RouteWidgetParams,
  routes: {
    cargoParameters: {},
    groups: [],
    total: 0,
    minPrice: 0,
    minTime: 0,
  } as RouteWidgetResult,
  filterParameters: {
    containerRentType: ['COC', 'SOC_RENT'],
    terminals: [],
    startTerminals: [],
    endTerminals: [],
    carriers: [],
    contractors: [],
    deliveryTime: { max: 0, min: 0 },
    isSingleShoulderRoutesOnly: false,
    price: { max: 0, min: 0 },
    transportingTypes: [],
  } as FilterData,
  sortData: {
    by: 'price',
    direction: 'asc',
  } as SortData,
  limit: 10,
  offset: 0,
};

export const REST_ROUTE_PARAMS_KEYS = [
  'isStartLoadingIncluded',
  'isEndLoadingIncluded',
  'isDangerCargo',
];
export const ONLY_IDS_ROUTE_PARAMS_KEYS = ['endLocation', 'startLocation'];
export const SHARE_SHORT_LINK_KEY = 'share';
export default DELIVERY_FORM_CONSTANTS;
