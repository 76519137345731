import BitrixUserContext from '@context/useBitrixUser';
import Button from '@mui/material/Button';
import useWidget from '@pages/AdditionalServices/context';
import { SummaryAdditionalServicesContainer } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/SummaryAdditionalServices/StyledComponents';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import useBasket from '@pages/Basket/useBasket';
import { localizedMessagesService } from '@services/requests/localizedMessagesService';
import React, { useContext } from 'react';

const SummaryAdditionalServices = () => {
  const {
    useBasketAdditionalService,
    useSelectedSubAddServices,
    useServicesCount,
    useAdditionalInfo,
    useTabsActive,
    useTabs,
    useAdditionalFormTrees,
  } = useWidget();
  const { user } = useContext(BitrixUserContext);
  const [{ currencies }] = useAdditionalInfo().useState();
  const [selectedToBasket] = useSelectedSubAddServices().useState();
  const [basket] = useBasketAdditionalService().useState();
  const [count] = useServicesCount().useState();
  const [settings] = useDelivery().useSettings().useState();

  const currentCurrency = currencies.find((c) => Number(settings.currencyId) === Number(c.id));

  const [trees] = useAdditionalFormTrees().useState();
  const [tabs] = useTabs().useState();
  const [activeTabs] = useTabsActive().useState();
  //
  const { addToCartComplexAdditionalService } = useBasket().actions;

  if (Object.keys(selectedToBasket).length === 0) {
    return <></>;
  }

  const summary = Object.keys(selectedToBasket).reduce(
    (acc, key) => {
      const currentItem = basket[key];

      const countElement = count[key];
      if (currentItem?.result?.result) {
        const result = currentItem.result.result;

        acc = {
          ...acc,
          ndc: acc.ndc + result.taxInTargetCurrency * (countElement || 1),
          withOutNds: acc.withOutNds + result.basePrice * (countElement || 1),
          mainSumm: acc.mainSumm + result.fullPriceInTargetCurrency * (countElement || 1),
        };
      }

      acc = {
        ...acc,
        basketArray: [...acc.basketArray, currentItem].filter(Boolean),
      };

      return acc;
    },
    {
      ndc: 0,
      withOutNds: 0,
      mainSumm: 0,
      basketArray: [],
    }
  );

  const addToBasketAdditionalData = () => {
    if (!user) {
    }
    // console.log('trees', trees);
    // console.log('tabs', tabs);
    // console.log('activeTab', activeTabs);
    // console.log('summary', summary.basketArray);
    const { name, localized_names, id } = tabs[activeTabs - 1];
    const {
      name: serviceParentName,
      localized_names: serviceParentLocalizedName,
      id: serviceParentId,
    } = trees[0].options[0].entity;
    localizedMessagesService()
      .GetMessagesArray(serviceParentLocalizedName)
      .then((message) =>
        addToCartComplexAdditionalService(
          summary.basketArray,
          { id, name, localized_names },
          { id: serviceParentId, name: serviceParentName, localized_names: message },
          user.id
        )
      );
  };

  return (
    <>
      <SummaryAdditionalServicesContainer>
        <div className={'header'}>
          <div className={'item'}>
            <div className={'label'}>Сумма без НДС:</div>
            <div className={'value'}>
              {summary.withOutNds.toFixed(2)} {currentCurrency?.code || ''}
            </div>
          </div>
          <div className={'item'}>
            <div className={'label'}>НДС:</div>
            <div className={'value'}>
              {summary.ndc.toFixed(2)} {currentCurrency?.code || ''}
            </div>
          </div>
        </div>
        <div className={'footer'}>
          <div className={'label'}>Итого таможенные услуги:</div>
          <div className={'value'}>
            {summary.mainSumm.toFixed(2)} {currentCurrency?.code || ''}
          </div>
        </div>
      </SummaryAdditionalServicesContainer>
      <Button
        className={'addToBasket'}
        variant={'contained'}
        color={'primary'}
        onClick={addToBasketAdditionalData}
      >
        Добавить в мой заказ
      </Button>
    </>
  );
};

export default SummaryAdditionalServices;
