import { ResultTableItem } from '@pages/AdditionalServices/tabs/CustomsPayments/consts';
import clsx from 'clsx';
import React from 'react';

import { CustomsPaymentsTableMobileContainer } from './StyledComponents';

interface CustomsPaymentsTableMobileProps {
  results: ResultTableItem[];
}

function CustomsPaymentsTableMobile({ results }: CustomsPaymentsTableMobileProps) {
  return (
    <CustomsPaymentsTableMobileContainer>
      {results.map((item, index) => (
        <div
          key={'customs-result' + index}
          className={clsx('customs-table-item', {
            'customs-table-item--footer': results.length - 1 === index,
          })}
        >
          <span className="customs-table-item__title">{item.name}</span>
          <div className="customs-table-item__body">
            <div className="customs-table-item__result-item">
              <span>{item.rateLabel}</span>
              <span className="customs-table-item__result-cost">{item.rate}</span>
            </div>
            <div className="customs-table-item__result-item">
              <span>{item.rublesAmountLabel}</span>
              <span className="customs-table-item__result-cost">{item.rublesAmount}</span>
            </div>
            <div className="customs-table-item__result-item">
              <span>{item.currencyAmountLabel}</span>
              <span className="customs-table-item__result-cost">{item.currencyAmount}</span>
            </div>
          </div>
        </div>
      ))}
    </CustomsPaymentsTableMobileContainer>
  );
}

export { CustomsPaymentsTableMobile };
