import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { Order } from '../interface';

export type _OrderMutationVar = {
  order_products: {
    price: number;
    amount: number;
    data: string;
    product_type: string;
  }[];
  customer_id: string;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  currency_id: number;
  language_id: number;
};

// Мутация на создание заказа
export class CreateOrderMutation
  implements
    GraphQLQuery<{
      preOrderId: number;
      order: _OrderMutationVar;
    }>
{
  readonly query: any;

  readonly variables: {
    preOrderId: number;
    order: _OrderMutationVar;
  };

  constructor(preOrderId: string, order: Order) {
    this.variables = {
      preOrderId: Number(preOrderId),
      order: {

        currency_id: Number(order.currency_id),
        language_id: Number(order.language_id),
        order_products: order.products.map(({ id, ...otherProps }) => ({
          order_id: null,
          id: Number(id),
          product_type: otherProps.product_type,
          price: otherProps.price,
          amount: otherProps.amount,
          data: otherProps.data,
          token: otherProps.token,
        })),
        customer_id: order.customer_id,
        customer_email: order.customer_email,
        customer_name: order.customer_name,
        customer_phone: order.customer_phone,
      },
    };

    this.query = gql`
      mutation __CREATE_ORDER__($preOrderId: ID, $order: Order_Complete_Data!) {
        data: order_create(order: $order, pre_order_id: $preOrderId) {
          currency_id
          currency_nominal
          currency_rate
          customer_email
          customer_id
          customer_name
          customer_phone
          date
          id
          language_id
          order_price
          order_products {
            amount
            data
            id
            order_id
            price
            product_type
            token
          }
        }
      }
    `;
  }
}
