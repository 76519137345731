import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { PaginationItem } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import RoutesListRoutesGridStyledContainer, {
  CustomPaginationContainer,
} from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/RoutesListRoutesGrid/StyledComponents';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { SortData } from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import { RouteWidgetResult } from '@services/requests/routeCalculator/routesCalculationService/types';
import { ceil } from 'lodash';
import React, { FC, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useYaHit } from '@context/useYaHit';

import RouteListItem from '../RouteListItem';
import OfferInPreOfferRouteListContainer
  from '@pages/AdditionalServices/components/components/DeliveryForm/components/RoutesListComponent/RoutesListRoutesGrid/components/OfferInPreOfferRouteListContainer';
import {RouteWidgetBaseParameters} from '@pages/AdditionalServices/tabs/DeliveryForm/types';

type RoutesResult = { routes: RouteWidgetResult; terminals: BranchItem[] };

// Свойства компонента
export interface RoutesListRoutesGridProps {
  routes: RoutesResult | undefined;
  locations: BranchItem[]; // список локаций, участвующих в построение маршрута
  baseData: RouteWidgetBaseParameters;
  mainLanguage: string; // Текущий основной язык
  systemLanguage: string; // Системный язык
  isRoutesLoading: boolean;
  sortData: SortData;
  onChangePage: { (page: number): void };
  limit: number;
  offset: number;
}

export interface RoutesListRoutesGridState {
  dialogIsOpen: boolean;
  product: OrderProduct | undefined;
}

const RoutesListRoutesGrid: FC<RoutesListRoutesGridProps> = ({
  routes,
  baseData,
  limit,
  offset,
  sortData,
  onChangePage,
}) => {
  const { t } = useTranslation('RoutesListComponent');

  const [detailRouteGroup, setDetailRouteGroup] = useState<RoutesListRoutesGridState>({
    product: undefined,
    dialogIsOpen: false,
  });

  useYaHit({});

  if (!routes || !baseData) {
    return null;
  }

  const {
    routes: { groups, total },
  } = routes;

  if (!groups) {
    return (
      <Typography variant={'h3'} sx={{ margin: '20px 0' }}>
        {t('RoutesListComponent:RoutesNotFound')}
      </Typography>
    );
  }

  const onChangeHandler = (_: any, page: number) => {
    onChangePage(page);

    if (window) {
      window.scrollTo({
        top: 700,
        behavior: 'smooth',
      });
    }
  };

  const closeDetailDialog = () => {
    setDetailRouteGroup({
      product: detailRouteGroup.product,
      dialogIsOpen: false,
    });
  };

  return (
    <>
      <div>
        {groups.map((group, i) => (
          <RouteListItem
            by={sortData.by}
            firstItem={i === 0}
            key={`routes-list-grid-item-${i}`}
            route={group}
            onClickDetail={(product: OrderProduct) =>
              setDetailRouteGroup({ product, dialogIsOpen: true })
            }
          />
        ))}
      </div>
      {ceil(total / limit) > 1 && (
        <BitrixDisableUserGuard>
          <CustomPaginationContainer
            showFirstButton
            showLastButton
            count={ceil(total / limit)}
            page={ceil(offset / limit) + 1}
            color="primary"
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: KeyboardArrowLeftIcon,
                  next: KeyboardArrowRightIcon,
                  first: KeyboardDoubleArrowLeftIcon,
                  last: KeyboardDoubleArrowRightIcon,
                }}
                {...item}
              />
            )}
            onChange={onChangeHandler}
          />
        </BitrixDisableUserGuard>
      )}
      <RoutesListRoutesGridStyledContainer
        fullWidth
        maxWidth={'lg'}
        open={detailRouteGroup.dialogIsOpen}
        onClose={closeDetailDialog}
        scroll={'body'}
      >
        <Box className="close-button">
          <IconButton onClick={closeDetailDialog}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        {!!detailRouteGroup.product && (
          <Suspense fallback={<LinearProgress />}>
            <OfferInPreOfferRouteListContainer
              product={detailRouteGroup.product}
              baseData={baseData}
            />
          </Suspense>
        )}
      </RoutesListRoutesGridStyledContainer>
    </>
  );
};

export default RoutesListRoutesGrid;
