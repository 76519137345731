import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import CardWrapper from '@components/UI/CardWrapper';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(CardWrapper)`
  position: relative;

  .sort-indicator {
    position: absolute;
    right: 30px;
    top: -38px;
    display: inline-flex;
    padding: 13px 21px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 -12px 20px 0 rgb(0 5 115 / 3%);

    @media (max-width: ${breakpoints.values.md}px) {
      right: 0;
      width: 100%;
      top: -21px;
    }

    span {
      color: ${palette.light.blue[400]};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .switch {
    border: none !important;
    margin-top: 20px;
    padding-bottom: 10px;
    color: #383838;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 0 0 19px 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .top__content {
    display: grid;
    grid-template-columns: 1fr 110px;
    gap: 20px 60px;
    padding: 0 30px 0 30px;
    @media (max-width: ${breakpoints.values.md}px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 20px;
    }

    @media (max-width: 380px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--wrapper {
      max-width: 100%;
      overflow-x: hidden;
    }

    &--left {
      display: grid;
      grid-template-columns: 9fr 150px;
      @media (max-width: ${breakpoints.values.laptop}px) {
        display: flex;
        flex-wrap: wrap;
      }
      gap: 16px;
      max-width: 100%;

      .steps {
        display: flex;
        flex-flow: column;
        gap: 20px;
        flex: 1 1 auto;
      }

      .price-line {
        align-items: end;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media (max-width: ${breakpoints.values.laptop}px) {
          margin-left: auto;
        }
        @media (max-width: ${breakpoints.values.md}px) {
          align-items: start;
          margin-left: unset;
        }
      }

      @media (max-width: ${breakpoints.values.md}px) {
        display: flex;
        flex-direction: column;
      }

      .full__price {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        text-align: right;
        @media (max-width: ${breakpoints.values.md}px) {
          text-align: left;
        }
      }

      .taxes {
        color: ${({ theme }) => theme.palette.grey[700]};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-align: right;

        @media (max-width: ${breakpoints.values.md}px) {
          right: 0;
          line-height: 140%;
          letter-spacing: 0.28px;
        }
      }

      .taxes-wrapper {
        @media (max-width: 1380px) {
          text-align: left;
        }
        @media (max-width: ${breakpoints.values.md}px) {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }

      .taxes-price {
        color: ${({ theme }) => theme.palette.grey[700]};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: right;

        @media (max-width: ${breakpoints.values.md}px) {
          right: 0;
          line-height: normal;
        }
      }
    }

    &--right {
      display: flex;
      gap: 10px;
      flex-direction: column;

      @media (max-width: 380px) {
        max-width: 172px;
      }

      @media (max-width: ${breakpoints.values.md}px) {
        order: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
      }

      .add-to-card-action {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 5px 10px;
        border-radius: 10px;
        color: ${({ theme }) => theme.palette.secondary.main};

        @media (max-width: ${breakpoints.values.md}px) {
          min-height: 45px;
        }
      }

      .in-card {
        color: ${({ theme }) => theme.palette.primary.main};
      }

      .offer-actions {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        white-space: nowrap;
        font-weight: 400;
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey[700]};
        padding: 5px 4px;
        border-radius: 10px;

        @media (max-width: ${breakpoints.values.md}px) {
          right: 0;
          width: fit-content;
          padding: 8px 14px;
          min-height: 45px;
        }
      }
    }

    .description {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      margin-top: 16px;
      max-width: 100%;
      width: fit-content;

      @media (max-width: ${breakpoints.values.md}px) {
        margin-top: 19px;
      }

      &__text {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: ${breakpoints.values.md}px) {
          white-space: normal;
        }
      }
    }
  }
`;

export const RouteShoulderContainer = styled(Box)`
  display: grid;
  grid-template-columns: 150px 3fr 150px;
  gap: 30px;

  @media (max-width: 1300px) {
    margin-bottom: unset;
  }

  @media (max-width: ${breakpoints.values.md}px) {
    align-items: flex-start;
    gap: 10px;
  }
  @media (max-width: ${breakpoints.values.sm}px) {
    grid-template-columns: 40% 1fr 40%;
  }
  .place__wrapper {
    display: flex;
    flex-flow: column;
    gap: 7px;

    .short__name {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 0.4px;

      @media (max-width: ${breakpoints.values.miniLaptop}px) {
        font-size: 16px;
      }

      @media (max-width: ${breakpoints.values.md}px) {
        font-size: 14px;
      }
    }

    .full__place {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      @media (max-width: ${breakpoints.values.md}px) {
        font-size: 12px;
      }
    }

    .type__place {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      @media (max-width: ${breakpoints.values.md}px) {
        font-size: 12px;
      }
    }
  }

  .place__wrapper.right {
    text-align: right;
  }

  .line__wrapper {
    @media (max-width: ${breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .days {
      color: #000;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-right: 20px;
      line-height: normal;

      @media (max-width: ${breakpoints.values.md}px) {
        margin-right: unset;
      }

      @media (max-width: ${breakpoints.values.sm}px) {
        text-align: center;
      }
    }

    .line {
      width: 100%;
      height: 2px;
      background: #e5e5e5;
      position: relative;
      margin: 10px 0;

      svg {
        position: absolute;
        right: -14px;
        top: -14px;
        font-size: 30px;
        fill: #0eab70;
      }

      &::before {
        content: '';
        background: #e5e5e5;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        position: absolute;
        left: -2px;
        top: -3px;
        display: block;
      }
    }

    .types__of_transport {
      display: flex;
      justify-content: flex-end;
      gap: 7px;
      margin-right: 20px;

      @media (max-width: ${breakpoints.values.md}px) {
        margin-right: unset;
        font-size: 12px;
      }

      svg {
        fill: #b9c0ce;

        @media (max-width: ${breakpoints.values.md}px) {
          width: 18px;
        }
      }
    }
  }
`;
export default Container;
