import { AdditionItemCost } from '@components/pages/Products/components/RouteProduct/components/RouteFiles';
import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { RouteInGroup, RouteResult } from '@services/requests/orderService/types';

export const getFilesIds = (
  products: OrderProduct[],
  realTimeBaseData: RouteWidgetBaseParameters
) => {
  const allData: RouteResult[] = products.map((product) => JSON.parse(product?.data || '{}'));
  const allRoutes: RouteInGroup[] = allData.map((product) => product.route.routes).flat();

  return allRoutes.reduce((acc, route) => {
    const { containerRent, pickOn, dropOff } = route.route;
    const productData = allData.find((p) =>
      p.route.routes.find((r) => r.route.routeId === route.route.routeId)
    );

    // Собераем идентификаторы файлов условий со всех плеч маршрута
    const fileIdsForShoulders = route.route.steps
      .map((step) => {
        const contractor = realTimeBaseData.contractors?.find(
          (c) => c.id === step.shoulder.contractor_id
        );
        const carrier = realTimeBaseData.carriers.find((c) => c.id === step.shoulder.carrier_id);

        return [
          contractor?.files ?? [],
          carrier?.files ?? [],
          productData.terminals
            .filter((t) =>
              [
                step.startTerminal?.id,
                step.endTerminal?.id,
                ...step.shoulder.shoulderSteps
                  .map((step) => [step.startTerminal?.id, step.endTerminal?.id])
                  .flat(),
              ].includes(t.id)
            )
            .map((t) => t.files)
            .flat(),
        ].flat();
      })
      .flat();

    const additionalCostItems: AdditionItemCost[] = [pickOn, dropOff, containerRent];

    // Собираем идентификаторы файлов условий из доп условий перевозки
    const additionalCostItemsFiles = additionalCostItems
      .map((costItem) => {
        if (!costItem) {
          return [];
        }

        const carrier = realTimeBaseData.carriers.find((c) => c.id === costItem.carrier_id);
        const contractor = realTimeBaseData.contractors.find(
          (c) => c.id === costItem.contractor_id
        );

        return [...(carrier?.files ?? []), ...(contractor?.files ?? [])];
      })
      .flat();

    acc = [...acc, ...additionalCostItemsFiles, ...fileIdsForShoulders];
    return acc;
  }, []);
};
