import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .add-new-product {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(65, 91, 117, 0.32);
    border-radius: 8px;
    height: 100%;
    width: 100%;
    min-height: 100px;
    background-color: transparent;
    margin-bottom: 40px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      min-height: 179px;
    }
  }

  .add-product-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #000;
  }

  .params__order {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: ${breakpoints.values.md}px) {
      margin-bottom: 20px;
      width: 100%;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 0 20px 0 rgb(0 5 115 / 7%);
      padding: 15px 0;
    }

    .mobile__title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }
`;
