import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { LoaderInterface } from '../interface';
import { LoadLocationsParams, LocationsLoaderByParamsQuery } from './LocationsLoaderByParamsQuery';
import {
  LocationsData,
  LocationsLoaderQuery,
  LocationsLoaderQueryResponse,
} from './LocationsLoaderQuery';

/**
 * Загрузчик данных по подрядчикам
 */
export class LocationsLoader implements LoaderInterface<LocationsData> {
  private readonly client: GraphQLClient;

  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  async Load(primaryKeys?: any[]): Promise<LocationsData[]> {
    try {
      const resp = await this.client.Query<null, LocationsLoaderQueryResponse>(
        new LocationsLoaderQuery(primaryKeys),
        {}
      );
      return resp.items;
    } catch {
      return [];
    }
  }

  // Сохранение настроек
  async LoadByParams(params: LoadLocationsParams): Promise<any> {
    try {
      const resp = await this.client.Query<LoadLocationsParams, LocationsLoaderQueryResponse>(
        new LocationsLoaderByParamsQuery(params),
        {}
      );
      return resp.items;
    } catch (e) {
      throw e;
    }
  }
}
