import { servicesServicesService } from '@services/requests/servicesService/servicesServicesService';
import {
  ServicePriceCalculationItemsProps,
  ServicesServicePriceCalculationMutationProps,
} from '@services/requests/servicesService/servicesServicesService/interface';

export const useCalculateServices = async (
  params: ServicePriceCalculationItemsProps[]
): Promise<ServicesServicePriceCalculationMutationProps[]> => {
  return await servicesServicesService().ServicePriceCalculation(params);
};
