import getTaxNames, { TaxNameProps } from '@helpers/getTaxNames';
import OfferDetail from '@pages/OfferDetail';
import { getFilesIds } from '@components/pages/Products/components/RouteProduct/useGetFilesFromRoute';
import useBasket from '@pages/Basket/useBasket';
import { filesService } from '@services/requests/filesService';
import { FileData } from '@services/requests/filesService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {RouteWidgetBaseParameters} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { getLocalizationById } from '@context/useMyRouting/helpers';

// Пропсы компонента
export type OfferInPreOfferRouteListContainerProps = {
  product: OrderProduct;
  baseData: RouteWidgetBaseParameters;
};

/**
 * Компонент обертка вывода информации о КП в листинге предварительно расчета
 * @param props
 */
const OfferInPreOfferRouteListContainer = (props: OfferInPreOfferRouteListContainerProps) => {
  const { useOrder, useIsLoading } = useBasket();
  const [isLoading] = useIsLoading().useState();
  const [order] = useOrder().useState();
  const [files, setFiles] = useState<FileData[]>([]);
  const [taxNames, setTaxNames] = useState<TaxNameProps[]>([]);

  const { baseData, product } = props;
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation('OfferContainers', { lng: langCode });
  const orderDataWithProduct = useMemo(
    () => ({
      ...order,
      id: order.id,
      date: new Date().toDateString(),
      products: [product],
    }),
    [order, product]
  );

  useEffect(() => {
    const filesToLoad = getFilesIds(orderDataWithProduct.products, baseData);
    filesService().LoadFilesById(filesToLoad).then(setFiles);
    getTaxNames(order.language_id).then(setTaxNames);
  }, []);

  if (!baseData || isLoading || taxNames.length === 0) {
    return <div />;
  }

  return (
    <OfferDetail
      order={orderDataWithProduct}
      baseData={baseData}
      taxNames={taxNames}
      files={files}
      title={t('OfferContainers:OfferDetailTitle')}
    />
  );
};

export default OfferInPreOfferRouteListContainer;
