import { ServicesQueryParamsProps } from '@services/requests/servicesService/interface';
import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Запрос списка директорий дополнительных услуг
 */
export class ServicesServicesQuery implements GraphQLQuery<ServicesQueryParamsProps> {
  readonly query: any;

  readonly variables: ServicesQueryParamsProps;

  constructor(params: ServicesQueryParamsProps) {
    this.variables = {
      ...params,
    };
    this.query = gql`
      query ServicesServicesQuery(
        $limit: Int = 30
        $offset: Int = 0
        $where: service_query_where_object
      ) {
        service_list(
          limit: $limit
          offset: $offset
          where: $where
          order: [{ by: id, direction: asc, priority: 1 }]
        ) {
          allFields {
            code
            handbook_id
            handbook_limitations
            helper
            id
            localized_helpers
            localized_names
            localized_placeholders
            name
            placeholder
            type
            value
          }
          allProperties {
            description
            id
            localized_descriptions
            localized_names
            name
            ordinal
            settings
            type
          }
          childs {
            allFields {
              code
              handbook_id
              handbook_limitations
              helper
              id
              localized_helpers
              localized_names
              localized_placeholders
              name
              placeholder
              type
              value
            }
            fields {
              code
              handbook_id
              handbook_limitations
              helper
              id
              localized_helpers
              localized_names
              localized_placeholders
              name
              placeholder
              type
              value
            }
            childs_id
            description
            fields_id
            id
            localized_descriptions
            localized_names
            name
            parent
            template
          }
          childs_id
          description
          fields {
            code
            handbook_id
            handbook_limitations
            helper
            id
            localized_helpers
            localized_names
            localized_placeholders
            name
            placeholder
            type
            value
          }
          fields_id
          id
          localized_descriptions
          localized_names
          name
          parent
          price_offers {
            currency_id
            fields_with_limitations_ids
            formula
            id
            is_active
            is_default
            is_include_tax
            service_id
            tax_id
          }
          property_values {
            id
            property_id
            service_id
            value
          }
          template
        }
      }
    `;
  }
}
