import { z } from 'zod';

export const errorSchema = z.object({
  Error: z.object({ ErrorCode: z.string(), ErrorDescr: z.string() }),
});

const measureSchema = z.object({
  Name: z.string(),
  Razm: z.string(),
});

const valueDetailSchema = z.object({
  ValueCount: z.string(),
  ValueUnit: z.string(),
  ValueCurrency: z.string().optional(),
  ValueCount2: z.string().optional(),
  ValueUnit2: z.string().optional(),
  ValueCurrency2: z.string().optional(),
});

export const importItemSchema = z.object({
  Link: z.string(),
  Measure: measureSchema,
  Order: z.string(),
  Value: z.string(),
  ValueDetail: valueDetailSchema,
  Prim: z.string().optional(),
  Note: z.string().optional(),
});

const directorySchema = z.object({
  RuName: z.union([z.object({}), z.string()]),
  EnName: z.union([z.object({}), z.string()]),
});

export const vatSchema = z.object({
  Condition: z.union([z.object({}), z.string()]),
  Directory: directorySchema.optional(),
  Document: z.union([z.object({}), z.string()]),
  Link: z.union([z.object({}), z.string()]),
  MainCondition: z.union([z.object({}), z.string()]),
  Value: z.string(),
  ValueDetail: valueDetailSchema,
});

export const exciseSchema = z.object({
  Value: z.string(),
  ValueDetail: valueDetailSchema,
  ValueDetailAdd: valueDetailSchema.optional(),
  Condition: z.string().optional(),
  Document: z.string().optional(),
  Link: z.string().optional(),
  Measure: measureSchema.optional(),
  Prim: z.string().optional(),
});

const importCalculationRateSchema = z.object({
  Code: z.string(),
  Exciselist: z.object({
    Excise: z.union([exciseSchema, z.array(exciseSchema)]).optional(),
  }),
  Importlist: z.object({
    Import: z.union([importItemSchema, z.array(importItemSchema)]),
  }),
  Name: z.string(),
  Prim: z.object({}),
  VATlist: z.object({
    VAT: z.union([vatSchema, z.array(vatSchema)]),
  }),
});

export const noteSchema = z.object({
  title: z.string(),
  description: z.string(),
});

export const importCalculationRateResponseSchema = importCalculationRateSchema;

export const featuresResponseSchema = z.array(noteSchema);

export const exportItemSchema = z.object({
  Link: z.string().optional(),
  Measure: measureSchema,
  Order: z.string().optional(),
  Value: z.string(),
  ValueDetail: z.object({
    ValueCount: z.string(),
    ValueUnit: z.string().optional(),
    ValueCurrency: z.string().optional(),
    ValueCount2: z.string().optional(),
    ValueUnit2: z.string().optional(),
    ValueCurrency2: z.string().optional(),
  }),
  Prim: z.union([z.object({}), z.string()]).optional(),
  Note: z.string().optional(),
});

const exportCalculationRateSchema = z.object({
  Code: z.string(),
  Exciselist: z.object({
    Excise: z.union([exciseSchema, z.array(exciseSchema)]).optional(),
  }),
  Exportlist: z.object({
    Export: exportItemSchema,
  }),
  Name: z.string(),
  Prim: z.object({}),
});

export const exportCalculationRateResponseSchema = exportCalculationRateSchema;

export const currencySchema = z.object({
  cbrf: z.number(),
  cntr: z.string(),
  code: z.coerce.number(),
  counts: z.coerce.number(),
  encode: z.string(),
  name: z.string(),
  rate: z.coerce.number(),
});

export const currencyResponseSchema = z.array(currencySchema);

const sborSchema = z.object({
  CONDTN: z.string(),
  MAXCOST: z.string(),
  MINCOST: z.string(),
  NAME: z.string(),
  ORDR: z.string(),
  PRIM: z.string(),
  RATE: z.string(),
  STP_DT: z.string(),
  ST_DT: z.string(),
  uin: z.string(),
});

export const sborResponseSchema = z.array(sborSchema);

export const countrySchema = z.object({
  Alfa: z.string().or(z.object({})),
  Code: z.string(),
  Name: z.string(),
});

export const countriesResponseSchema = z.object({
  Country: z.array(countrySchema),
});

const codeSchema = z.object({
  descr: z.string(),
  descr_sh: z.string().or(z.object({})),
  g: z.string().optional(),
  tncode: z.string(),
  tnved: z.string(),
});

export const codesResponseSchema = z.object({ i: z.array(codeSchema) });
