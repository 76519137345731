import Tab from '@mui/material/Tab';
import { a11yProps } from '@pages/AdditionalServices/components/TubsContainer/helpers';
import useWidget from '@pages/AdditionalServices/context';
import { AdditionalServiceVariantsTabsContainer } from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceVariantsTabs/StyledComponents';
import React from 'react';

const AdditionalServiceVariantsTabs = () => {
  const { useVariants, useActiveVariant } = useWidget();

  const [variants] = useVariants().useState();
  const [activeVariant, onChangeVariant] = useActiveVariant().useState();

  const handleChange = (e: any, value: string) => {
    const variant = variants.find((i) => i.id === value);
    onChangeVariant(variant);
  };

  return (
    <AdditionalServiceVariantsTabsContainer
      variant="scrollable"
      scrollButtons="auto"
      value={activeVariant.id}
      onChange={handleChange}
      aria-label="basic tabs example"
      className={'root-services-tab'}
    >
      {variants.map((variant, index) => (
        <Tab {...a11yProps(index + 1)} key={index + 1} value={variant.id} label={variant.name} />
      ))}
    </AdditionalServiceVariantsTabsContainer>
  );
};

export default AdditionalServiceVariantsTabs;
