import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const SumField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [basket] = useWidget().useBasketAdditionalService().useState([row.id]);
  const [{ currencies }] = useWidget().useAdditionalInfo().useState([props.row.id]);

  const result = basket[row.id];

  const currentCurrency = result
    ? currencies.find((c) => Number(result.currencyId) === Number(c.id))?.code
    : '';

  return (
    <FieldCellContainer>
      {row.cost} {currentCurrency}
    </FieldCellContainer>
  );
};

export default SumField;
