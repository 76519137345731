import ProductItemSummary from '@components/pages/Products/components/ProductItemSummary';
import ServiceDetailProduct from '@components/pages/Products/components/ServiceDetailProduct';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import Typography from '@mui/material/Typography';
import { getPriceServicesSummary } from '@pages/Basket/helpers';
import React, { FC } from 'react';

import { ServiceSectionDetailProps } from './types';

const ServiceSectionDetail: FC<ServiceSectionDetailProps> = ({
  section: { services, sectionName, serviceParent },
  order,
  realTimeBaseData,
  taxNames,
}) => {
  const [settings] = useMyRouting().useSettings().useState();
  const { currencies } = realTimeBaseData;

  const currencyCode = currencies.find((c) => c.id === settings.currencyId).code || 'RUB';

  const summary = getPriceServicesSummary(services);

  // Выводим наименование директории услуг, следующей за корневой, для комплексной услуги в качестве заголовка таблицы
  const complexServiceName =
    serviceParent.localized_names.find((l) => l.lang_id === settings.secondaryLanguageId)
      ?.message || serviceParent.name;

  // Выводим наименование корневой директории услуг в качестве заголовка секции услуг
  const serviceSectionName =
    sectionName.localized_names.find((l) => l.lang_id === settings.secondaryLanguageId)?.message ||
    sectionName.name;

  return (
    <>
      <Typography variant={'h2'} color={'common.black'} className={'section-title'}>
        {serviceSectionName}
      </Typography>
      {services.map((service) => (
        <ServiceDetailProduct
          product={service}
          order={order}
          realTimeBaseData={realTimeBaseData}
          taxNames={taxNames}
          key={service.id}
          serviceName={service.product_type === 'complex' ? complexServiceName : undefined}
        />
      ))}
      <ProductItemSummary
        currencyCode={currencyCode}
        summary={summary}
        title={serviceSectionName}
      />
    </>
  );
};

export default ServiceSectionDetail;
