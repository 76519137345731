import { CurrencyResponse, VatItem } from '@services/requests/customsTaxService/interface';

import { CustomsPaymentProduct } from '../components/CustomsPaymentsForm/context/types';

interface GetVatCalcProps {
  selectedVat: VatItem;
  localizedMobileHeaderTableLabels: LocalizedLabels;
  contractCurrency: CurrencyResponse[number];
  product: CustomsPaymentProduct;
  feeRowData: Calculation;
  exciseRowData: Calculation;
  title: string;
}

interface LocalizedLabels {
  rateLabel: string;
  rublesAmountLabel: string;
  currencyAmountLabel: string;
}

interface Calculation {
  rateLabel: string;
  rublesAmountLabel: string;
  currencyAmountLabel: string;
  id: string;
  name: string;
  rate: string;
  rublesAmount: string;
  currencyAmount: string;
}

function getVatCalc({
  selectedVat,
  contractCurrency,
  localizedMobileHeaderTableLabels,
  product,
  feeRowData,
  exciseRowData,
  title,
}: GetVatCalcProps) {
  const { contractCurrencyCost } = product;

  if (!selectedVat || selectedVat?.Value === '0') {
    return {
      id: 'vat',
      name: title,
      rate: '0%',
      rublesAmount: '0',
      currencyAmount: '0',
      contractCurrency,
      ...localizedMobileHeaderTableLabels,
    };
  }

  const exciseSum = +exciseRowData?.rublesAmount || 0;
  const feeSum = +feeRowData.rublesAmount;
  const vatBase = +selectedVat.ValueDetail.ValueCount;
  const cost = +contractCurrencyCost;
  const currencyRate = +contractCurrency.rate.toFixed(2);
  const rubCost = cost * currencyRate;

  const vatSum = ((rubCost + feeSum + exciseSum) * vatBase) / 100;
  const sumCurrency = (vatSum / currencyRate).toFixed(2);

  return {
    id: 'vat',
    name: title,
    rate: selectedVat.Value.split('|')[0],
    rublesAmount: vatSum.toFixed(2),
    currencyAmount: sumCurrency,
    contractCurrency,
    ...localizedMobileHeaderTableLabels,
  };
}

export { getVatCalc };
