import { customsTaxService } from '@services/requests/customsTaxService';
import { createState } from '@settings/core/stateContexts/useContextSubscriber';

import { DEFAULT_PRODUCT_DATA } from './consts';
import { validateBaseCustomsPaymentConfig, validateCustomsPaymentProducts } from './helpers';
import {
  BaseCustomsPaymentConfig,
  BaseCustomsPaymentData,
  CustomsPaymentProduct,
  CustomsPaymentProducts,
} from './types';

const [useBaseCustomsPaymentConfig, $baseCustomsPaymentConfig] =
  createState<BaseCustomsPaymentConfig>({
    customsMode: '1',
    borderDelivery: { cost: '0' },
    otherExpenses: { cost: '0' },
  });

const [useInvalidFields, $invalidFields] = createState<string[][]>([]);
const [useBaseCustomsPaymentData, $baseCustomsPaymentData] = createState<BaseCustomsPaymentData>({
  countries: [],
  currencies: [],
});
const [useCustomsPaymentProducts, $customsPaymentProducts] = createState<CustomsPaymentProducts>([
  DEFAULT_PRODUCT_DATA,
]);

$baseCustomsPaymentConfig.pipe().subscribe((base) => {
  const currentProducts = $customsPaymentProducts.getValue();

  $customsPaymentProducts.next(currentProducts.map((p) => ({ ...p, ...base })));
});

const addCustomsPaymentProductHandler = () => {
  const products = $customsPaymentProducts.getValue();
  const baseData = $baseCustomsPaymentConfig.getValue();

  $customsPaymentProducts.next([...products, { ...DEFAULT_PRODUCT_DATA, ...baseData }]);
};

const removeCustomsPaymentProductHandler = (index: number) => {
  const products = $customsPaymentProducts.getValue();

  $customsPaymentProducts.next(products.filter((_, i) => i !== index));
};

const highlightInvalidFieldsHandler = () => {
  const currentProducts = $customsPaymentProducts.getValue();
  const currentBaseCustomsPaymentConfig = $baseCustomsPaymentConfig.getValue();

  const baseConfigErrors = validateBaseCustomsPaymentConfig(currentBaseCustomsPaymentConfig);
  const productErrors = validateCustomsPaymentProducts(currentProducts);

  const errors = productErrors.map((item) => [...item, ...baseConfigErrors]);

  $invalidFields.next(errors);
  return errors;
};

const init = async () => {
  const [countries, currencies] = await Promise.all([
    customsTaxService().LoadCountries(),
    customsTaxService().LoadCurrencies({ date: new Date() }),
  ]);

  $baseCustomsPaymentData.next(<BaseCustomsPaymentData>{
    countries,
    currencies,
  });
};

const actions = {
  init,
  onAddCustomsPaymentProduct: addCustomsPaymentProductHandler,
  onRemoveCustomsPaymentProduct: removeCustomsPaymentProductHandler,
  onHighlightInvlidFields: highlightInvalidFieldsHandler,
};

export function useCustomsPayments() {
  return {
    actions,
    useBaseCustomsPaymentData,
    useBaseCustomsPaymentConfig,
    useCustomsPaymentProducts,
    useInvalidFields,
  };
}
