import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const CostField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [basket] = useWidget().useBasketAdditionalService().useState([props.row.id]);
  const [{ currencies }] = useWidget().useAdditionalInfo().useState([props.row.id]);

  const result = basket[row.id];

  const paymentValue = (
    Object.values(row).filter((value) => typeof value === 'object') as any[]
  ).find((prop) => prop.code.includes('paymentvalueTemp4'));

  const currentCurrency = result
    ? currencies.find((c) => Number(result.currencyId) === Number(c.id))
    : '';

  return (
    <FieldCellContainer>
      {paymentValue?.value}
      {currentCurrency && ` ${currentCurrency.code}`}
    </FieldCellContainer>
  );
};

export default CostField;
