import { GridCell } from '@mui/x-data-grid';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const TaxField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [basket] = useWidget().useBasketAdditionalService().useState([props.row.id]);
  const [{ taxes, currencies }] = useWidget().useAdditionalInfo().useState([props.row.id]);

  const result = basket[row.id];

  const currentCurrency = result
    ? currencies.find((c) => Number(result.currencyId) === Number(c.id))
    : '';

  const currentTax = taxes.find((t) => Number(t.id) === Number(result?.result?.data.tax_id));

  if (!currentTax) {
    return <FieldCellContainer>-</FieldCellContainer>;
  }

  return (
    <FieldCellContainer>
      <FieldCellContainer className="MuiDataGrid-cell MuiDataGrid-cell--withRightBorder MuiDataGrid-withBorderColor">
        {currentTax.amount}%
      </FieldCellContainer>
      <FieldCellContainer>
        {row.sumTax.toFixed(2)}
        {currentCurrency && ` ${currentCurrency.code}`}
      </FieldCellContainer>
    </FieldCellContainer>
  );
};

export default TaxField;
