import Typography from '@mui/material/Typography';
import { useCustomsPayments } from '@pages/AdditionalServices/tabs/CustomsPayments/components/CustomsPaymentsForm/context';
import React from 'react';

import { CodeItem } from './CodeItems';
import { CustomsPaymentsProps } from './interface';
import CustomsPaymentsStyledComponent from './StyledComponent';

function CustomsPayments({ paymentData }: CustomsPaymentsProps) {
  const { country } = paymentData[0];

  const { useBaseCustomsPaymentData } = useCustomsPayments();
  const [customsBaseData] = useBaseCustomsPaymentData().useState();
  const { countries } = customsBaseData;

  const foundCountry = countries.find((c) => c.Code === country);

  return (
    <CustomsPaymentsStyledComponent>
      <div className="customs-payments">
        <Typography variant={'h4'} className="customs-payments__country">
          {foundCountry?.Name}
        </Typography>
        {paymentData.map((data) => (
          <CodeItem key={'code-item-' + data.code} paymentData={data} />
        ))}
      </div>
    </CustomsPaymentsStyledComponent>
  );
}

export default CustomsPayments;
