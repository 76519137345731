import { MButton } from '@assets/mui/@material-extend';
import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import Button from '@mui/material/Button';
import useBasket from '@pages/Basket/useBasket';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomsPayments } from '../../context';
import { CustomsPaymentsControlContainer } from './StyledComponents';

interface CustomsPaymentsControlProps {
  onOpenDetails: () => void;
}

function CustomsPaymentsControl({ onOpenDetails }: CustomsPaymentsControlProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const { actions } = useBasket();
  const { addToCartCustomsPaymentsProduct } = actions;
  const {
    useCustomsPaymentProducts,
    actions: customsPaymentsActions,
  } = useCustomsPayments();
  const { onHighlightInvlidFields } = customsPaymentsActions;
  const [products] = useCustomsPaymentProducts().useState();

  const highlightInvalidFieldsHandler = () => {
    const errors = onHighlightInvlidFields();
    const isProductsValid = errors.every((product) => product.length === 0);

    return isProductsValid;
  };

  const addToFavoritesHandler = () => {
    const isValid = highlightInvalidFieldsHandler();

    if (!isValid) {
      return;
    }
  };

  const downloadPdfHandler = () => {
    const isValid = highlightInvalidFieldsHandler();

    if (!isValid) {
      return;
    }
  };

  const addToBaskerHandler = () => {
    const isValid = highlightInvalidFieldsHandler();

    if (!isValid) {
      return;
    }

    addToCartCustomsPaymentsProduct(products);
  };

  return (
    <BitrixDisableUserGuard>
      <CustomsPaymentsControlContainer>
        <MButton
          className={'btn open-details'}
          fullWidth={true}
          variant={'outlined'}
          color={'secondary'}
          //   disabled={!isControlButtonsActive}
          onClick={onOpenDetails}
        >
          {t('CustomsPayments:DetailingBtn')}
        </MButton>
        <MButton
          className={'btn add-to-favorites'}
          variant={'outlined'}
          color={'secondary'}
          fullWidth={true}
          //   disabled={!isControlButtonsActive}
          onClick={addToFavoritesHandler}
        >
          {t('CustomsPayments:ToFavoritesBtn')}
        </MButton>
        <MButton
          className={'btn download-pdf'}
          variant={'outlined'}
          color={'secondary'}
          fullWidth={true}
          //   disabled={!isControlButtonsActive}
          onClick={downloadPdfHandler}
        >
          {t('CustomsPayments:DownloadPDFBtn')}
        </MButton>
        <Button
          className={'btn add-to-basket'}
          variant={'contained'}
          color={'primary'}
          //   disabled={!isControlButtonsActive}
          onClick={addToBaskerHandler}
        >
          {t('CustomsPayments:AddToMyOrderBtn')}
        </Button>
      </CustomsPaymentsControlContainer>{' '}
    </BitrixDisableUserGuard>
  );
}

export { CustomsPaymentsControl };
