import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  margin-bottom: 50px;
  width: 100%;
  background: transparent;

  button {
    border: unset;
  }
  .item {
    text-transform: initial;
  }

  @media (max-width: ${breakpoints.values.sm}px) {
    .item {
      padding: 0 20px;
    }
    .MuiTabs-indicator {
      height: 100%;
      border-radius: 7px;
      border: 1px solid ${palette.light.green[300]};
      background: none;
    }
  }
`;

export default Container;
