import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { RouteWidgetParams } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { Order } from '@services/requests/orderService/interface';
import {
  ContainerParameters,
  CustomCargoParameters,
  RouteResult,
  RoutesGroup,
} from '@services/requests/orderService/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';

import { ProductEntityIdList, ProductServiceInterface } from './interface';
import { RoutesEntityIdProcessor } from './processors/RoutesEntityIdProcessor';

/**
 * Сервис по генерации продуктов для корзины
 */
export class ProductService implements ProductServiceInterface {
  /**
   * Генерация данных продукта
   * @param cargoParameters
   * @param StartLocation
   * @param EndLocation
   * @param route
   * @param terminals
   * @param baseData
   * @param order
   */
  Generate(
    routeParams: RouteWidgetParams,
    cargoParameters: {
      containerParameters?: ContainerParameters[] | null;
      customCargoParameters?: CustomCargoParameters[] | null;
      endTransportingConditionId: number;
      startTransportingConditionId: number;
    },
    StartLocation: BranchItem,
    EndLocation: BranchItem,
    route: RoutesGroup,
    terminals: BranchItem[],
    baseData: RouteWidgetBaseParameters,
    order: Order
  ): RouteResult {
    /**
     * Собираем все превозчиков и контракторов, и типы плеч
     */
    // Карта с id сущностей, участвующих в построении данного маршрута
    const filterBaseDataIds: ProductEntityIdList = {
      carriers: [],
      contractors: [],
      shoulderTypes: [],
      currencies: [],
      allowancesData: [],
      containerTypes: [],
      containers: [],
      endTransportingConditions: [],
      startTransportingConditions: [],
      unitGroups: [],
      units: [],
      loadingTransportConditions: [],
      unloadingTransportConditions: [],
      files: [],
      taxes: [],
    };

    const { startTransportingConditionId, endTransportingConditionId } = cargoParameters;

    // Добавляем startTransportingConditions
    filterBaseDataIds.files.push(...StartLocation.files, ...EndLocation.files);

    // Добавляем startTransportingConditions
    filterBaseDataIds.startTransportingConditions.push(startTransportingConditionId.toString());

    // Добавляем endTransportingConditions
    filterBaseDataIds.endTransportingConditions.push(endTransportingConditionId.toString());

    // Добавляем валюту корзины
    filterBaseDataIds.currencies.push(order?.currency_id);

    new RoutesEntityIdProcessor().GetEntityIds(filterBaseDataIds, route.routes);

    for (const key in filterBaseDataIds) {
      // Оставляем только уникальные id
      if (Object.prototype.hasOwnProperty.call(filterBaseDataIds, key)) {
        filterBaseDataIds[key as keyof RouteWidgetBaseParameters] = [
          ...new Set<string>(
            filterBaseDataIds[key as keyof RouteWidgetBaseParameters].filter((v) => !!v)
          ),
        ];
      }
    }

    // Фильтруем только участвующие сушности
    const freezeBaseData: RouteWidgetBaseParameters = {
      allowancesData: baseData.allowancesData,
      carriers: baseData.carriers.filter((c) => filterBaseDataIds.carriers.includes(c.id)),
      containerTypes: [],
      containers: baseData.containers,
      contractors: baseData.contractors.filter((c) => filterBaseDataIds.contractors.includes(c.id)),
      currencies: baseData.currencies.filter((c) => filterBaseDataIds.currencies.includes(c.id)),
      endTransportingConditions: baseData.endTransportingConditions.filter((c) =>
        filterBaseDataIds.endTransportingConditions.includes(c.id)
      ),
      shoulderTypes: baseData.shoulderTypes.filter((c) =>
        filterBaseDataIds.shoulderTypes.includes(c.id)
      ),
      startTransportingConditions: baseData.startTransportingConditions.filter((c) =>
        filterBaseDataIds.startTransportingConditions.includes(c.id)
      ),
      unitGroups: baseData.unitGroups,
      units: baseData.units,
      loadingTransportConditions: baseData.loadingTransportConditions.filter((c) =>
        filterBaseDataIds.loadingTransportConditions.includes(c.id)
      ),
      unloadingTransportConditions: baseData.unloadingTransportConditions.filter((c) =>
        filterBaseDataIds.unloadingTransportConditions.includes(c.id)
      ),
      files: baseData.files.filter((c) => filterBaseDataIds.files.includes(c.id)),
      taxes: baseData.taxes.filter((c) => filterBaseDataIds.taxes.includes(c.id)),
    };

    return {
      cargoParameters: {
        ...routeParams,
        ...cargoParameters,
        containerParameters: cargoParameters.containerParameters || null,
        customCargoParameters: cargoParameters.customCargoParameters || null,
      },
      routePointsItems: {
        from: StartLocation,
        to: EndLocation,
      },
      routePoints: {
        from:
          StartLocation.type === 'terminal' && StartLocation.symbolCode !== 'ANY'
            ? { terminal: parseInt(StartLocation.id), location: 0 }
            : { location: parseInt(StartLocation.id), terminal: 0 },
        to:
          EndLocation.type === 'terminal' && EndLocation.symbolCode !== 'ANY'
            ? { terminal: parseInt(EndLocation.id), location: 0 }
            : { location: parseInt(EndLocation.id), terminal: 0 },
      },
      routeSymbolCodes: {
        to: EndLocation.symbolCode,
        from: StartLocation.symbolCode,
      },
      type: cargoParameters.containerParameters ? 'container' : 'custom',
      route,
      terminals,
      baseData: JSON.parse(JSON.stringify(freezeBaseData)),
      date: new Date().toISOString(),
    };
  }
}
