import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const ShoulderItemTableBodyStyledContainer = styled(Box)`
  overflow: auto;
  max-width: 100%;
  width: 100%;
  .default-table-container__last-cell.MuiDataGrid-cell--withRightBorder {
    border-right: 0;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
  }

  .MuiDataGrid-columnHeadersInner > div {
    width: 100%;
  }

  &:not(.default) {
    .MuiDataGrid-row,
    .MuiDataGrid-virtualScrollerRenderZone > div,
    .MuiDataGrid-columnHeadersInner > div {
      width: 100% !important;
      display: grid !important;
      grid-template-columns:
        minmax(285px, 1fr)
        minmax(130px, 1fr)
        minmax(83px, 1fr)
        minmax(130px, 1fr)
        minmax(90px, 1fr)
        minmax(110px, 1fr)
        minmax(90px, 1fr)
        minmax(128px, 1fr)
        0 !important;
    }

    .MuiDataGrid-row .MuiDataGrid-cell {
      line-height: normal;
      width: unset !important;
      max-width: unset !important;
      min-width: unset !important;

      &:nth-of-type(n + 2) {
        padding: 10px 5px;
      }
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeader {
      padding: 0 5px;
      width: unset !important;
      max-width: unset !important;
      min-width: unset !important;
      color: ${({ theme }) => theme.palette.grey[900]};
    }

    .MuiDataGrid-root {
      border-radius: 0;
      border-bottom: 0;
    }
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader,
  .MuiDataGrid-root .MuiDataGrid-cell {
    padding: 10px 15px 10px 20px;
    justify-content: center;
    align-items: center;
  }
  .MuiDataGrid-columnHeadersInner {
    width: 100%;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaders,
  .MuiDataGrid-root .MuiDataGrid-columnHeader {
    width: 100%;
    line-height: normal !important;
    border-radius: 0;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader > div {
    width: 100%;
  }
  .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child {
    border-right: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-root .MuiDataGrid-withBorderColor {
    .MuiDataGrid-cellContent {
      color: black;
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    text-overflow: initial;
    white-space: normal;
    text-align: center;
  }

  .default-table-container {
    .MuiDataGrid-cellContent {
      color: black;
    }

    &__text-left {
      .MuiDataGrid-cellContent {
        text-align: left;
        width: 100%;
      }
    }

    &__text-center {
      .MuiDataGrid-cellContent {
        text-align: center;
        width: 100%;
      }
    }

    &__number-input {
      .MuiDataGrid-cellContent,
      .MuiDataGrid-editInputCell {
        display: flex;
        width: 50px;
        padding: 7px 0 6px 0;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
      }

      .MuiInputBase-input {
        padding: 0;
        text-align: center;
      }
    }
  }
`;

export default ShoulderItemTableBodyStyledContainer;
