import Stack from '@mui/material/Stack';
import ServicesSummaryItem from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServicesSummary/components/ServicesSummaryItem';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { ProductSummaryProps } from '@services/requests/orderService/types';
import React, { FC } from 'react';

const ComplexSummary: FC<ProductSummaryProps> = ({ product, currencyCode, langId }) => {
  const productData: OrderProduct[] = JSON.parse(product.data);

  return (
    <Stack spacing={'10px'}>
      {productData.length > 0 &&
        productData.map((p) => (
          <ServicesSummaryItem
            key={`${product.id}-${p.id}`}
            product={p}
            currencyCode={currencyCode}
            langId={langId}
            orderCurrency={currencyCode}
          />
        ))}
    </Stack>
  );
};

export default ComplexSummary;
