import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';

const SummaryItemStyledContainer = styled('section')`
  display: grid;
  grid-template-columns: auto auto;
  padding: 18px 50px;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.success.main};
  border-radius: 18px;
  line-height: 30px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.values.xsm}px) {
    padding: 16px;
    text-align: center;
    flex-direction: column;
  }

  .value {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.6px;

    @media (max-width: ${breakpoints.values.laptop}px) {
      font-size: 22px;
    }
  }
`;

export default SummaryItemStyledContainer;
