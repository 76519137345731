import { Order } from '@services/requests/orderService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import {
  BasePriceItem,
  CalculatedAllowanceOffer,
  CalculatedPrice,
  Route,
  RouteResult,
} from '@services/requests/orderService/types';
import { ServicesServicePriceCalculationMutationProps } from '@services/requests/servicesService/servicesServicesService/interface';

const roundPrice = (price: number) => Math.round((price + Number.EPSILON) * 100) / 100;

/**
 * Устанавливаем новое значение в массив базовых платежей в исходной валюте
 * @param oldPrice
 * @param newPrice
 */
const setBasePrice = (oldPrice: BasePriceItem[], newPrice: BasePriceItem[]): BasePriceItem[] => {
  return [...oldPrice, ...newPrice].reduce(
    (acc: BasePriceItem[], item: BasePriceItem): BasePriceItem[] => {
      if (acc.find((i) => i.currencyId === item.currencyId)) {
        return acc.map((price) =>
          price.currencyId === item.currencyId
            ? { ...price, value: price.value + item.value }
            : price
        );
      }
      return [...acc, item];
    },
    []
  );
};

const setAllowance = (i: CalculatedAllowanceOffer) => ({
  ...i.calculatedPrice,
  basePriceArray: [
    {
      value: i.calculatedPrice.basePrice,
      currencyCode: i.allowanceOfferCondition?.currency?.code || '',
      currencyId: i.allowanceOfferCondition?.currency_id || '',
    },
  ],
});

/**
 * Собираем все цены
 * @param acc
 * @param item
 */
const reducerCalculatedPrice = (acc: CalculatedPrice, item: CalculatedPrice) => {
  const itemBasePriceArray: BasePriceItem[] = item.basePriceArray || [
    {
      value: item.basePrice,
      currencyCode: item.currencyCode,
      currencyId: '',
    },
  ];
  return {
    basePrice: acc.basePrice + item.basePrice,
    basePriceArray: setBasePrice(acc.basePriceArray, itemBasePriceArray),
    currencyCode: acc.currencyCode,
    computedAmount: acc.computedAmount + item.computedAmount,
    conversionFee: acc.conversionFee + item.conversionFee,
    fullPriceInTargetCurrency: acc.fullPriceInTargetCurrency + item.fullPriceInTargetCurrency,
    priceInTargetCurrency: acc.priceInTargetCurrency + item.priceInTargetCurrency,
    taxInTargetCurrency: acc.taxInTargetCurrency + item.taxInTargetCurrency,
  };
};

/**
 * Стоимость всех участков маршрута
 */
function getStepsCalcPrice(routes: Route[]): CalculatedPrice {
  return routes
    .map((r) => r.steps)
    .reduce((acc, steps) => [...acc, ...steps], [])
    .reduce(
      (acc, stepDetails) => {
        const {
          endTerminalAllowances, // []
          endTerminalCalculatedPrice,
          shoulderAllowances, // []
          shoulderOfferCalculatedPrice,
          shoulderOfferPriceCondition,
          startTerminalAllowances, // []
          startTerminalCalculatedPrice,
          endTerminalPrice,
        } = stepDetails;

        return [
          acc,
          ...endTerminalAllowances.map(setAllowance), // []
          {
            ...endTerminalCalculatedPrice,
            basePriceArray: [
              {
                value: endTerminalCalculatedPrice.basePrice,
                currencyCode: endTerminalPrice?.currency?.code || '',
                currencyId: endTerminalPrice?.currency_id || '',
              },
            ],
          },
          ...shoulderAllowances.map(setAllowance), // []
          {
            ...shoulderOfferCalculatedPrice,
            basePriceArray: [
              {
                value: shoulderOfferCalculatedPrice.basePrice,
                currencyCode: shoulderOfferPriceCondition?.currency?.code || '',
                currencyId: shoulderOfferPriceCondition?.currency_id || '',
              },
            ],
          },
          ...startTerminalAllowances.map(setAllowance), // []
          {
            ...startTerminalCalculatedPrice,
            basePriceArray: [
              {
                value: endTerminalCalculatedPrice.basePrice,
                currencyCode: endTerminalPrice?.currency?.code || '',
                currencyId: endTerminalPrice?.currency_id || '',
              },
            ],
          },
        ].reduce(reducerCalculatedPrice);
      },
      {
        basePrice: 0,
        basePriceArray: [],
        currencyCode: '',
        computedAmount: 0,
        conversionFee: 0,
        fullPriceInTargetCurrency: 0,
        priceInTargetCurrency: 0,
        taxInTargetCurrency: 0,
      } as CalculatedPrice
    );
}

/**
 * Получение стоимости всех маршрутов
 * @param routes
 */
function getRoutesAddPrice(routes: Route[]) {
  return routes.reduce(
    (acc, r) => {
      const {
        containerRentCalculatedPrice = {
          basePrice: 0,
          basePriceArray: [],
          currencyCode: '',
          computedAmount: 0,
          conversionFee: 0,
          fullPriceInTargetCurrency: 0,
          priceInTargetCurrency: 0,
          taxInTargetCurrency: 0,
        },
        dropOffCalculatedPrice = {
          basePrice: 0,
          basePriceArray: [],
          currencyCode: '',
          computedAmount: 0,
          conversionFee: 0,
          fullPriceInTargetCurrency: 0,
          priceInTargetCurrency: 0,
          taxInTargetCurrency: 0,
        },
        pickOnCalculatedPrice = {
          basePrice: 0,
          basePriceArray: [],
          currencyCode: '',
          computedAmount: 0,
          conversionFee: 0,
          fullPriceInTargetCurrency: 0,
          priceInTargetCurrency: 0,
          taxInTargetCurrency: 0,
        },
        containerRent,
        pickOn,
        dropOff,
      } = r;

      return [
        acc,
        {
          ...containerRentCalculatedPrice,
          basePriceArray: [
            {
              value: containerRentCalculatedPrice.basePrice,
              currencyCode: containerRent?.currency_id || '',
              currencyId: containerRent?.currency_id || '',
            },
          ],
        },
        {
          ...dropOffCalculatedPrice,
          basePriceArray: [
            {
              value: dropOffCalculatedPrice.basePrice,
              currencyCode: dropOff?.currency?.code || '',
              currencyId: dropOff?.currency_id || '',
            },
          ],
        },
        {
          ...pickOnCalculatedPrice,
          basePriceArray: [
            {
              value: pickOnCalculatedPrice.basePrice,
              currencyCode: pickOn?.currency?.code || '',
              currencyId: pickOn?.currency_id || '',
            },
          ],
        },
      ].reduce(reducerCalculatedPrice);
    },
    {
      basePrice: 0,
      basePriceArray: [],
      currencyCode: '',
      computedAmount: 0,
      conversionFee: 0,
      fullPriceInTargetCurrency: 0,
      priceInTargetCurrency: 0,
      taxInTargetCurrency: 0,
    } as CalculatedPrice
  );
}

const getServicePriceParams = (product: OrderProduct) => {
  const productParams: ServicesServicePriceCalculationMutationProps = JSON.parse(
    product.data
  ).service;
  //   const { amount } = product;

  const {
    result: {
      basePrice: base_price,
      taxInTargetCurrency,
      computedAmount,
      conversionFee,
      fullPriceInTargetCurrency,
      priceInTargetCurrency,
    },
    data: { currency_id },
  } = productParams.result;

  const basePrice = (base_price < 0 ? 0 : base_price) * computedAmount;

  const basePriceArray: BasePriceItem[] = [
    {
      value: basePrice,
      currencyCode: '',
      currencyId: String(currency_id),
    },
    {
      value: taxInTargetCurrency * computedAmount,
      currencyCode: '',
      currencyId: String(currency_id),
    },
  ];

  return {
    basePrice: basePrice,
    basePriceArray: basePriceArray,
    currencyCode: '',
    computedAmount,
    conversionFee: conversionFee * computedAmount,
    fullPriceInTargetCurrency: fullPriceInTargetCurrency * computedAmount,
    priceInTargetCurrency: priceInTargetCurrency * computedAmount,
    taxInTargetCurrency: taxInTargetCurrency * computedAmount,
  };
};

const getCartPriceServiceSummary = (services: OrderProduct) => {
  const productParams: RouteResult = JSON.parse(services.data);
  if (Array.isArray(productParams)) {
    return productParams
      .map((p) => {
        return getServicePriceParams(p);
      })
      .reduce(reducerCalculatedPrice);
  }
  return getServicePriceParams(services);
};

export const getPriceServicesSummary = (services: OrderProduct[]) => {
  return services.map((s) => getCartPriceServiceSummary(s)).reduce(reducerCalculatedPrice);
};

export const getPriceRouteSummary = (routes: Route[], amount: number) => {
  const routesAddPrice = getRoutesAddPrice(routes);
  const stepsCalcPrice = getStepsCalcPrice(routes);

  const data = [stepsCalcPrice, routesAddPrice].reduce(reducerCalculatedPrice);

  return {
    basePrice: data.basePrice * amount,
    basePriceArray: data.basePriceArray.map((i) => ({ ...i, value: i.value * amount })),
    currencyCode: data.currencyCode,
    computedAmount: data.computedAmount * amount,
    conversionFee: data.conversionFee * amount,
    fullPriceInTargetCurrency: data.fullPriceInTargetCurrency * amount,
    priceInTargetCurrency: data.priceInTargetCurrency * amount,
    taxInTargetCurrency: data.taxInTargetCurrency * amount,
  };
};

/**
 * Формирование итоговых сумм по заказу
 * @param order
 */
export const getCartPriceSummary = (order: Order): CalculatedPrice => {
  if (!order.products.length) {
    return {
      basePrice: 0,
      basePriceArray: [],
      currencyCode: '',
      computedAmount: 0,
      conversionFee: 0,
      fullPriceInTargetCurrency: 0,
      priceInTargetCurrency: 0,
      taxInTargetCurrency: 0,
    };
  }

  const data = order.products
    .map((p) => {
      const productParams: RouteResult = JSON.parse(p.data);
      const { amount } = p;

      if (['complex', 'service'].includes(p.product_type)) {
        const data = JSON.parse(p.data);

        const type = Array.isArray(data) ? data[0].type : data.type;

        if (type === 'customs-payments') {
          return {
            basePrice: 1000000,
            basePriceArray: [],
            currencyCode: '',
            computedAmount: 0,
            conversionFee: 0,
            fullPriceInTargetCurrency: 0,
            priceInTargetCurrency: 0,
            taxInTargetCurrency: 0,
          };
        }

        return getCartPriceServiceSummary(p);
      }

      const routes = productParams.route?.routes?.map((r) => r.route) || [];

      return getPriceRouteSummary(routes, amount);
    })
    .reduce(reducerCalculatedPrice);

  return {
    basePrice: roundPrice(data.basePrice),
    basePriceArray: data.basePriceArray.map((p) => ({ ...p, value: roundPrice(p.value) })),
    currencyCode: data.currencyCode,
    computedAmount: roundPrice(data.computedAmount),
    conversionFee: roundPrice(data.conversionFee),
    fullPriceInTargetCurrency: roundPrice(data.fullPriceInTargetCurrency),
    priceInTargetCurrency: roundPrice(data.priceInTargetCurrency),
    taxInTargetCurrency: roundPrice(data.taxInTargetCurrency),
  };
};
