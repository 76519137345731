import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CargoTypeInformation from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/CargoTypeInformation';
import {
  ContainerParameters,
  RouteWidgetBaseParameters,
} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
import { ContainersData } from '@services/requests/searchLoaders/containersLoader/ContainersLoaderQuery';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { checkEmptyInputValue } from '../../../helpers';
import useDelivery from '../../../useDelivery';

// Свойства компонента
export interface ContainerParametersItemComponentProps {
  Position: number;
  BaseData: RouteWidgetBaseParameters;
  ContainerParameters: ContainerParameters;
  mainLanguage: string; // Текущий основной язык
  systemLanguage: string; // Системный язык
  isDeletable: boolean;
  onChange: { (params: ContainerParameters, field: keyof ContainerParameters): void };
  onDelete: { (): void };
  onChangeCargoFieldsFilled: { (values: boolean[]): void };
}

const ContainerParametersItemComponent: FC<ContainerParametersItemComponentProps> = ({
  BaseData,
  ContainerParameters,
  onDelete,
  isDeletable,
  onChange,
  Position,
  mainLanguage,
  onChangeCargoFieldsFilled,
}) => {
  const { useRouteParams } = useDelivery();
  const setRouteParams = useRouteParams().setState;
  const { t } = useTranslation('Common');
  const { containers } = BaseData;

  const isQuantityInputInvalid = checkEmptyInputValue(ContainerParameters.containerQuantity);
  const isWeightInputInvalid = checkEmptyInputValue(ContainerParameters.eachContainerWeight);

  useEffect(() => {
    onChangeCargoFieldsFilled([isWeightInputInvalid, isQuantityInputInvalid]);
  }, [isWeightInputInvalid, isQuantityInputInvalid]);

  const formattedContainers = useMemo(() => {
    const groupedContainers: Record<number, ContainersData[]> = {};
    const uniqueContainers: ContainersData[] = [];
    const uniqueContainerNames: string[] = [];

    containers.forEach((container) => {
      const { containerType } = container;

      if (containerType.id in groupedContainers) {
        groupedContainers[containerType.id].push(container);
        return;
      }

      groupedContainers[containerType.id] = [container];
    });

    const sortedGroups = Object.entries(groupedContainers).sort(([aKey], [bKey]) =>
      aKey > bKey ? 1 : -1
    );

    const fullSortedContainers = sortedGroups.map(([_, groupContainers]) => {
      const sortedCotainers = groupContainers.sort((a) =>
        a.is_default_for_container_type === true ? -1 : 1
      );

      return sortedCotainers;
    });

    const flatContainers = fullSortedContainers.flat();

    flatContainers.forEach((container) => {
      const isExist = uniqueContainerNames.includes(container.default_name);

      if (!isExist || container.is_default_for_container_type) {
        uniqueContainerNames.push(container.default_name);
        uniqueContainers.push(container);
      }
    });

    return uniqueContainers;
  }, [containers]);

  useEffect(() => {
    const { containerId } = ContainerParameters;
    const defaultContainer = formattedContainers[0];

    if (!containerId) {
      setRouteParams((prevState) => ({
        ...prevState,
        containerParameters: {
          ...prevState.containerParameters,
          containerParameters: prevState.containerParameters.containerParameters.map((c) => ({
            ...c,
            containerId: c.containerId ?? defaultContainer.id,
          })),
        },
      }));
    }
  }, [formattedContainers]);

  /**
   * Обработка изменения одного из значений параметров контейнера
   * @param property
   * @param value
   */
  const handleChangeContainerProperty = <T extends keyof ContainerParameters>(
    property: T,
    value: ContainerParameters[T]
  ) => {
    const params = { ...ContainerParameters };
    params[property] = value;

    onChange(params, property);
  };

  /**
   * Получение локализованного названия
   * @param defaultName
   * @param localizations
   */
  const handleGenerateLocalizedName = (
    defaultName: string,
    localizations: LocalizedMessage[]
  ): string => {
    const localization = localizations.find((l) => l.lang_id === mainLanguage);
    if (!localization) {
      return defaultName;
    }

    return localization.message;
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography
        variant={'subtitle2'}
        color={'grey[700]'}
        marginBottom={'15px'}
        sx={{
          mb: {
            sx: 15,
            md: 18,
            lg: 15,
          },
        }}
        textTransform={'capitalize'}
      >
        {t('Common:Container')} #{Position}
      </Typography>
      <Box className={'cargo-inputs-block'}>
        <Box width={'100%'}>
          <TextField
            fullWidth
            variant={'standard'}
            value={ContainerParameters.containerId ?? ''}
            select
            sx={{
              '& .MuiInput-input': {
                color: '#383838!important',
                fontStyle: 'normal!important',
                fontWeight: '600!important',
                lineHeight: '22px!important',
                textAlign: 'right!important',
                fontSize: '12px!important',
              },
            }}
            disabled={containers.length === 0}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ margin: '0' }}>
                  <Typography
                    sx={{ color: 'grey[700]', position: 'absolute', pointerEvents: 'none' }}
                    component={'span'}
                    variant={'h5'}
                    className={'cargo-inputs-block__text-field'}
                  >
                    {t('Common:Volume')}
                    <Tooltip
                      title={<CargoTypeInformation containers={containers} />}
                      enterTouchDelay={0}
                      leaveTouchDelay={10000}
                      arrow
                    >
                      <span className={'cargo-inputs-block__tooltip'}>?</span>
                    </Tooltip>
                  </Typography>
                </InputAdornment>
              ),
            }}
            onChange={(event) => handleChangeContainerProperty('containerId', event.target.value)}
          >
            {formattedContainers.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {handleGenerateLocalizedName(option.default_name, option.localized_names_data)}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <TextField
          error={isQuantityInputInvalid}
          focused={isQuantityInputInvalid}
          fullWidth
          variant={'standard'}
          value={ContainerParameters.containerQuantity}
          type={'number'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment position="start">
                <Typography
                  sx={{ color: 'grey[700]', position: 'absolute', pointerEvents: 'none' }}
                  component={'span'}
                  variant={'h5'}
                >
                  {t('Common:QuantityPcs')}
                </Typography>
              </InputAdornment>
            ),
          }}
          onChange={(event) =>
            handleChangeContainerProperty('containerQuantity', parseInt(event.target.value))
          }
        />

        <TextField
          error={isWeightInputInvalid}
          focused={isWeightInputInvalid}
          fullWidth
          variant={'standard'}
          value={ContainerParameters.eachContainerWeight}
          type={'number'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment position="start">
                <Typography
                  sx={{ color: 'grey[700]', position: 'absolute', pointerEvents: 'none' }}
                  component={'span'}
                  variant={'h5'}
                >
                  {t('OrderList:EachContainerWeight')}
                </Typography>
              </InputAdornment>
            ),
          }}
          onChange={(event) =>
            handleChangeContainerProperty('eachContainerWeight', parseInt(event.target.value))
          }
        />
      </Box>
      {isDeletable && (
        <IconButton
          sx={{
            position: 'absolute',
            right: '-15px',
            top: '-15px',
          }}
          size={'small'}
          onClick={() => onDelete()}
        >
          <CloseIcon sx={{ fontSize: 22 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default ContainerParametersItemComponent;
