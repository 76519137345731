import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
import { UnitData } from '@services/requests/searchLoaders/unitLoader/UnitLoaderQuery';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { checkEmptyInputValue, getformattedFloatNumber } from '../../../helpers';
import { CustomCargoCalcType, CustomCargoPalletParameters } from '../../../types';
import { CustomCargoParametersItemProps } from '../interfaces';

function CustomCargoPallet(props: CustomCargoParametersItemProps) {
  const { customCargoParameters, onChangeCargoFieldsFilled } = props;

  const pallet = props.BaseData.units.find(
    (u) => u.id === props.customCargoParameters.palletParameters.type
  );

  const { isDeletable, onDelete } = props;
  const { t } = useTranslation('Common');

  const isHeightInputInvalid = checkEmptyInputValue(
    customCargoParameters.palletParameters.normativeHeightOfStacking
  );
  const isWeightInputInvalid = checkEmptyInputValue(customCargoParameters.palletParameters.weight);
  const isQuantityInputInvalid = checkEmptyInputValue(
    customCargoParameters.palletParameters.quantity
  );

  useEffect(() => {
    onChangeCargoFieldsFilled([isHeightInputInvalid, isWeightInputInvalid, isQuantityInputInvalid]);
  }, [isHeightInputInvalid, isWeightInputInvalid, isQuantityInputInvalid]);

  const handleChangeCustomCargoPalletParameters = <T extends keyof CustomCargoPalletParameters>(
    key: T,
    value: CustomCargoPalletParameters[T]
  ) => {
    props.onChangeCustomCargoParameters({
      ...props.customCargoParameters,
      palletParameters: {
        ...props.customCargoParameters.palletParameters,
        [key]: value,
      },
    });
  };

  const handleChangeCustomCargoCalcType = (type: CustomCargoCalcType) => {
    props.onChangeCustomCargoParameters({
      ...props.customCargoParameters,
      parametersCargoCalcType: type,
    });
  };

  const handleChangeCustomCargoType = (type: 'base' | 'pallet') => {
    props.onChangeCustomCargoParameters({
      ...props.customCargoParameters,
      parametersType: type,
    });
  };

  const handleChangePalletType = (pallet: UnitData) => {
    props.onChangeCustomCargoParameters({
      ...props.customCargoParameters,
      palletParameters: {
        ...props.customCargoParameters.palletParameters,
        type: pallet.id,
        normativeHeightOfStacking: pallet.normative_height_of_stacking,
      },
    });
  };

  const handleGenerateLocalizedName = (defaultName: string, localizations: LocalizedMessage[]) => {
    const localization = localizations.find((l) => l.lang_id === props.mainLanguage);
    if (!localization) {
      return defaultName;
    }

    return localization.message;
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Typography variant={'subtitle2'} color={'#707070'}>
        {t('Common:CargoPlace')} #{props.Position}
      </Typography>
      <Box className={'cargo-types'}>
        <Typography component={'span'} variant={'h5'} className={'cargo-types__title'}>
          {t('Common:CalculationType')}
        </Typography>
        <Button size={'small'} variant={'outlined'} sx={{ width: 118 }}>
          {t('Common:CargoUnit')}
        </Button>
        <Button
          size={'small'}
          variant={'outlined'}
          className={'not-selected'}
          color={'inherit'}
          onClick={() => handleChangeCustomCargoCalcType('volume')}
        >
          {t('Common:Consignment')}
        </Button>
        <Typography component={'span'} variant={'h5'} className={'cargo-types__title'}>
          {t('Common:InputType')}
        </Typography>
        <Button
          size={'small'}
          variant={'outlined'}
          className={'not-selected'}
          color={'inherit'}
          onClick={() => handleChangeCustomCargoType('base')}
          sx={{ width: 118, whiteSpace: 'no ' }}
        >
          {t('Common:Dimensions')}
        </Button>
        <Button size={'small'} variant={'outlined'}>
          {t('Common:Pallet')}
        </Button>
        <Box gridColumn={'span 3'} flex={'0 0 100%'}>
          <TextField
            fullWidth
            variant={'standard'}
            value={pallet?.id}
            select
            id={'pallet-type'}
            sx={{
              '& .MuiInput-input': {
                color: '#383838!important',
                fontStyle: 'normal!important',
                fontWeight: '600!important',
                lineHeight: '22px!important',
                textAlign: 'right!important',
                fontSize: '12px!important',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                  position="start"
                >
                  <Typography component={'span'} variant={'h5'}>
                    {t('Common:PalletType')}
                  </Typography>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              const newValue = props.BaseData.units.find((p) => p.id === event.target.value);
              handleChangePalletType(newValue as UnitData);
            }}
          >
            {props.BaseData.units
              .filter((u) => u.unit_group === '4')
              .map((option) => (
                <MenuItem value={option.id} key={option.id}>
                  {handleGenerateLocalizedName(
                    option.unit_symbol,
                    option.localized_unit_symbols_data
                  )}
                </MenuItem>
              ))}
          </TextField>
        </Box>
      </Box>
      <Box className={'cargo-inputs-block-grid'}>
        <TextField
          className={'cargo-inputs-block__half-mobile width'}
          variant={'standard'}
          value={pallet ? pallet.width : 0}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:WidthM')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={'cargo-inputs-block__half-mobile length'}
          variant={'standard'}
          value={pallet ? pallet.length : 0}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:LengthM')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={isHeightInputInvalid}
          focused={isHeightInputInvalid}
          fullWidth
          variant={'standard'}
          className={'height'}
          value={props.customCargoParameters.palletParameters.normativeHeightOfStacking}
          onChange={(event) =>
            handleChangeCustomCargoPalletParameters(
              'normativeHeightOfStacking',
              getformattedFloatNumber(event.target.value)
            )
          }
          type={'text'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:HeightM')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={isWeightInputInvalid}
          focused={isWeightInputInvalid}
          fullWidth
          variant={'standard'}
          className={'weight'}
          value={props.customCargoParameters.palletParameters.weight}
          onChange={(event) => {
            handleChangeCustomCargoPalletParameters('weight', parseInt(event.target.value));
          }}
          type={'number'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:PalletWeightKg')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          error={isQuantityInputInvalid}
          focused={isQuantityInputInvalid}
          fullWidth
          variant={'standard'}
          className={'seats'}
          value={props.customCargoParameters.palletParameters.quantity}
          onChange={(event) =>
            handleChangeCustomCargoPalletParameters('quantity', parseInt(event.target.value))
          }
          type={'number'}
          InputProps={{
            inputMode: 'numeric',
            startAdornment: (
              <InputAdornment
                sx={{ color: '#707070', position: 'absolute', pointerEvents: 'none' }}
                position="start"
              >
                <Typography component={'span'} variant={'h5'}>
                  {t('Common:PalletSeatsPcs')}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {isDeletable && (
        <IconButton
          sx={{
            position: 'absolute',
            right: '-15px',
            top: '-15px',
          }}
          size={'small'}
          onClick={onDelete}
        >
          <CloseIcon sx={{ fontSize: 22 }} />
        </IconButton>
      )}
    </Box>
  );
}

export default CustomCargoPallet;
