import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';

const AdditionalServicesTableHeaderContainer = styled('div')`
  border-radius: 20px 20px 0 0;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);
  padding: 25px;
  width: 100%;
  gap: 20px 60px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media (max-width: ${breakpoints.values.sm}px) {
      flex-direction: column;
      gap: 20px;
    }

    .leftPath {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 20px;
      width: 100%;
      justify-content: space-between;

      .name {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .fields {
        justify-content: flex-start;
        gap: 20px;
        flex-flow: row wrap;
        display: flex;

        @media (max-width: ${breakpoints.values.sm}px) {
          .field-wrapper {
            width: 100%;
          }
        }
      }
    }

    .rightPath {
      gap: 20px;
      display: flex;
      align-items: center;
      @media (max-width: ${breakpoints.values.sm}px) {
        width: 100%;
      }
    }
  }

  .add-to-card-action {
    min-width: 130px;
    text-align: center;
    height: 56px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 5px 10px;
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.secondary.main};

        @media (max-width: ${breakpoints.values.md}px) {
          min-height: 45px;
          width: 100%;
        }
      }

      .in-card {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    .item {
      font-size: 16px;
      white-space: nowrap;
    }
  }

  .fields-wrapper {
    padding-top: 20px;

    @media (max-width: ${breakpoints.values.xsm}px) {
      div {
        width: 100%;
      }
    }
  }

  .trigger {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 0;
    border: none !important;
    margin-top: 20px;
    background-color: white;
    padding-bottom: 10px;
    color: #383838;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 0 0 19px 19px;
    box-shadow: 0 14px 24px -3px rgba(0, 5, 115, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: ${breakpoints.values.md}px) {
      bottom: -16px;
    }

    svg {
      fill: white;
    }

    &:hover {
      background-color: #4db28e;
      color: white;
    }
  }
`;

export default AdditionalServicesTableHeaderContainer;
