import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import FieldCellContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceIsVariantListing/components/AdditionalServicesTable/components/StyledComponents';
import React from 'react';

const CoursePurchaseField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [basket] = useWidget().useBasketAdditionalService().useState([props.row.id]);
  const [{ currencies }] = useWidget().useAdditionalInfo().useState([props.row.id]);

  const coursePurchaseId = row.coursePurchaseField;

  const result = basket[coursePurchaseId];

  const value = result?.result?.result.basePrice || '-';

  const currentCurrency =
    currencies.find(
      (c) => Number(result?.currencyId || row.coursePurchaseCurrency) === Number(c.id)
    ) || '';

  if (row.type === 'result') {
    return (
      <FieldCellContainer>
        {row.coursePurchase.toFixed(2)}
        {currentCurrency && ` ${currentCurrency.code}`}
      </FieldCellContainer>
    );
  }

  return (
    <FieldCellContainer>
      {value === '-' && value}
      {value !== '-' && value.toFixed(2)}
      {currentCurrency && ` ${currentCurrency.code}`}
    </FieldCellContainer>
  );
};

export default CoursePurchaseField;
