import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';

import { HandbookStoreServiceInterface } from './interfaces';
import {
  LoadHandbookPublishedList,
  LoadHandbookPublishedListResponse,
  QueryHandbookPublishedList,
} from './queries/QueryHandbookPublishedList';
import {
  LoadPublishedHandbookList,
  LoadPublishedHandbookListResponse,
  LoadPublishedHandbookParams,
  QueryHandbookPublishedListByParams,
} from './queries/QueryHandbookPublishedListByParams';

/**
 * Сервис сохранения данных сущности
 */
export class HandbookStoreService implements HandbookStoreServiceInterface {
  private readonly logger = loggerFactory().make(`EntityStoreService`);
  private readonly client: GraphQLClient;

  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  async LoadPublishedList(): Promise<LoadHandbookPublishedList[]> {
    try {
      const resp = await this.client.Query<null, LoadHandbookPublishedListResponse>(
        new QueryHandbookPublishedList(),
        {}
      );
      this.logger.Debug(`Load published handbook list`, resp);
      return resp.handbookpublished_list;
    } catch (e) {
      throw e;
    }
  }

  async LoadPublishedListByParams(
    params: LoadPublishedHandbookParams
  ): Promise<LoadPublishedHandbookList[]> {
    try {
      const resp = await this.client.Query<
        LoadPublishedHandbookParams,
        LoadPublishedHandbookListResponse
      >(new QueryHandbookPublishedListByParams(params), {});
      this.logger.Debug(`Load published handbook list by params`, resp);
      return resp.handbookpublished_list;
    } catch (e) {
      throw e;
    }
  }
}
