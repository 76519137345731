import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { locationsLoader } from '@services/requests/searchLoaders/locationsLoader';
import { OptionSelect } from '@services/types';
import React, { useEffect, useState } from 'react';

import { PropsGetField } from './GetFields';

const getOptionLabel = (o: OptionSelect) => o.label;

const getDefaultLocations = async (_in: string[]) => {
  const temp = await locationsLoader().LoadByParams({ where: { id: { _in } } });
  return temp.map((i) => ({
    value: i.id,
    label: i.default_name,
  }));
};

const LocationField = (props: PropsGetField) => {
  const {
    field,
    setState,
    state,
    size = 'medium',
    withLabel = true,
    realTimeValidation = false,
  } = props;

  const tempLimitations = field.handbook_limitations;
  const [defaultOptions, setDefaultOptions] = useState<OptionSelect[]>([]);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  useEffect(() => {
    getDefaultLocations(tempLimitations || []).then((r) => {
      setDefaultOptions(r);
      setIsFirstRender(false);
    });
  }, []);

  const onChange = (_: any, option: OptionSelect) => {
    const value = option?.value || null;

    if (!value) {
      setState((prevState: FieldState) => ({
        ...Object.keys(prevState).reduce((acc, i) => {
          if (i === field.code) {
          } else {
            acc = { ...acc, [i]: prevState[i] };
          }

          return acc;
        }, {}),
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      [field.code]: {
        ...prevState[field.code],
        value: null,
        handBookValue: option?.value || null,
      },
    }));
  };

  const value =
    defaultOptions.find((i) => Number(i.value) === Number(state[field.code]?.handBookValue)) ||
    null;
  const error = isFirstRender ? false : realTimeValidation ? !!!value : false;

  return (
    <Autocomplete
      fullWidth
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          error={error}
          label={withLabel ? `Name: ${field.name}` : ''}
        />
      )}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      options={defaultOptions}
    />
  );
};

export default LocationField;
