import { TabsItemProps } from '@pages/AdditionalServices/context/types';
import AdditionalServiceContainer from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer';
import AdditionalServiceForm from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceForm';
import React from 'react';

interface Props {
  service: TabsItemProps;
}

const AdditionalForm = ({ service }: Props) => {
  return (
    <>
      <AdditionalServiceForm service={service} />
      <AdditionalServiceContainer service={service} />
    </>
  );
};

export default AdditionalForm;
