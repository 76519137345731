import useWidget from '@pages/AdditionalServices/context';
import { getServiceRoots } from '@pages/MainWidget/helpers';
import React, { useEffect } from 'react';
import TubsContainer from 'src/pages/AdditionalServices/components/TubsContainer';

import FormWidget from '../AdditionalServices';
import RootContainer from './StyledComponents';

const Widget = () => {
//   const setTabsArray = useWidget().useTabs().setState;

  useEffect(() => {
    // getServiceRoots().then(setTabsArray);
  }, []);

  return (
    <RootContainer>
      <TubsContainer />
      <FormWidget />
    </RootContainer>
  );
};

export default Widget;
