import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Запрос загрузки данных по перевозчикам
 */
export class DivisionContractorLoaderQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(division: string) {
    this.variables = null;
    this.query = gql`
            query LoadContractors {
                items: ${division}_contractor_list(
                    limit: 1000000
                ) {
                    id
                    default_name
                }
            }
        `;
  }
}
