import {
  ServicesAggregateProps,
  ServicesQueryParamsProps,
} from '@services/requests/servicesService/interface';
import {
  LoadAllServicesProps,
  ServicePriceCalculationItemsProps,
  ServicePriceCalculationParametersProps,
  ServicesAggregateQueryResponseProps,
  ServicesProps,
  ServicesServicePriceCalculationMutationProps,
  ServicesServicePriceCalculationMutationResponse,
  ServicesServicesQueryResponseProps,
  ServicesServicesServiceProps,
} from '@services/requests/servicesService/servicesServicesService/interface';
import { ServicesServicePriceCalculationMutation } from '@services/requests/servicesService/servicesServicesService/muitation/ServicesServicePriceCalculationQuery';
import { ServicesServicesAggregateQuery } from '@services/requests/servicesService/servicesServicesService/requests/ServicesServicesAggregateQuery';
import { ServicesServicesQuery } from '@services/requests/servicesService/servicesServicesService/requests/ServicesServicesQuery';
import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

// Сервис загрузки дополнительных услуг
class ServicesServicesService implements ServicesServicesServiceProps {
  private readonly logger: Logger;

  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   */
  constructor(token?: string) {
    this.logger = loggerFactory().make(`ServicesServicesLoader`);
    this.client = graphQLClient(token);
  }

  /**
   * Агрегирующий запрос сущностей дополнительных услуг
   */
  async LoadAggregate(): Promise<ServicesAggregateProps> {
    try {
      const response = await this.client.Query<null, ServicesAggregateQueryResponseProps>(
        new ServicesServicesAggregateQuery(),
        {}
      );

      this.logger.Debug(`Loaded aggregate info of services`, response);

      return response.servicesservice_aggregate[0];
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка массива услуг по переданному фильтру params
   * @param params
   */
  async Load(params: ServicesQueryParamsProps): Promise<ServicesProps[]> {
    try {
      const response = await this.client.Query<
        ServicesQueryParamsProps,
        ServicesServicesQueryResponseProps
      >(new ServicesServicesQuery(params), {});

      this.logger.Debug(`Loaded list of services`, response);

      return response.service_list;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка всех доступных услуг.
   * Сначала мы получаем агрегацию, это дает нам общее количество. Затем мы должны установить лимит в запросе
   * @param limit
   * @param offset
   */
  async LoadAll(limit?: number, offset?: number): Promise<LoadAllServicesProps> {
    try {
      const params: ServicesQueryParamsProps = {
        limit,
        offset,
      };

      const allServicesCount = await this.LoadAggregate();
      const response = await this.Load(params);

      return {
        count: allServicesCount.count,
        items: response,
      };
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Запрос на расчет стоимости доп. услуг.
   * @param params
   */
  async ServicePriceCalculation(
    params: ServicePriceCalculationItemsProps[]
  ): Promise<ServicesServicePriceCalculationMutationProps[]> {
    try {
      const response = await this.client.Query<
        ServicePriceCalculationParametersProps,
        ServicesServicePriceCalculationMutationResponse
      >(new ServicesServicePriceCalculationMutation({ services: params }), {});

      return response.servicesServicePriceCalculation;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}

export default ServicesServicesService;
