import { AutocompleteInputChangeReason } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { customsTaxService } from '@services/requests/customsTaxService';
import { CodeItem } from '@services/requests/customsTaxService/interface';
import { useDebounceStream } from '@settings/core/stateContexts/useDebounceStream';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { checkErrorExisting } from '../../context/helpers';
import { CustomsPaymentsCodeSelectProps } from './types';

function CustomsPaymentsCodeSelect({
  errors,
  onChangeCode,
  onResetFields,
  onResetTaxParams,
}: CustomsPaymentsCodeSelectProps) {
  const { t } = useTranslation(['CustomsPayments']);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CodeItem[]>([]);
  const [debounceValue, value, setDebounceValue] = useDebounceStream<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const openDropdownHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDropdownHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (debounceValue.length < 3) {
      closeDropdownHandler();
      return;
    }

    const isCode = isFinite(+debounceValue);

    if (debounceValue.length === 10 && isCode) {
      closeDropdownHandler();
      return;
    }

    const fetchCodes = async (searchTerm: string) => {
      const result = await customsTaxService().LoadCodesBySearch(searchTerm);

      if (!result) {
        setOptions([]);
        setIsLoading(false);
        return;
      }

      setOptions(result);
      setIsLoading(false);
    };

    setIsLoading(true);
    fetchCodes(debounceValue);
  }, [debounceValue, closeDropdownHandler]);

  const selectCodeHandler = async (newValue: CodeItem) => {
    if (!newValue) {
      onResetFields();
      onResetTaxParams();
      onChangeCode('code', '');
      return;
    }

    const { tncode, g } = newValue;

    const isCategory = tncode.includes('...');

    if (isCategory) {
      setIsLoading(true);
      const [searchCode] = g.split(':');

      const result = await customsTaxService().LoadCodesBySearchCode(searchCode);

      if (!result) {
        setOptions([]);
        setIsLoading(false);
        return;
      }

      setOptions(result);
      setIsLoading(false);
      return;
    }

    const formattedCode = tncode.replaceAll(/\s/g, '');
    setDebounceValue(formattedCode);
    onResetFields();
    onResetTaxParams();
    onChangeCode('code', formattedCode);
    closeDropdownHandler();
  };

  const changeInputHandler = (value: string, reason: AutocompleteInputChangeReason) => {
    if (value === '' && reason === 'reset') {
      return;
    }

    const isSelectValue = value.includes('-');

    let formattedValue = value.match(/\d+/g)?.join() || value;

    if (isSelectValue) {
      formattedValue = value
        .match(/\d+.*(?=-)/g)
        .join()
        .replaceAll(' ', '');
    }

    onResetFields();
    onResetTaxParams();
    onChangeCode('code', formattedValue);
    if (reason !== 'reset') {
      setDebounceValue(formattedValue);
    }
  };

  return (
    <Autocomplete
      id="code-tnved"
      className="cost-field-input"
      fullWidth
      open={open}
      onOpen={openDropdownHandler}
      onClose={closeDropdownHandler}
      loadingText="Загрузка..."
      noOptionsText="Ничего не найдено"
      inputValue={value}
      onInputChange={(_, value, reason) => changeInputHandler(value, reason)}
      filterOptions={(x) => x}
      openOnFocus={false}
      getOptionLabel={(option) => `${option.tncode} - ${option.descr}`}
      onChange={(_, newValue) => selectCodeHandler(newValue)}
      disableCloseOnSelect
      options={options}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={checkErrorExisting(['code'], errors)}
          label={t('CustomsPayments:HSCodeOrProductNamePlaceholder')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export { CustomsPaymentsCodeSelect };
