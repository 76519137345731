import { handbookService } from '@services/requests/handbookService';
import { OptionSelect } from '@services/types';
import { useEffect, useState } from 'react';

const loadCurrentHandbookItems = async (handbookId: string) => {
  const filter = {
    where: {
      id: {
        _equals: handbookId,
      },
    },
  };
  const handBook = await handbookService().LoadPublishedListByParams(filter);
  if (handBook.length === 0) {
    return [];
  }
  return handBook[0].items.map((i) => ({
    value: i.id,
    label: i.name,
  }));
};

type ReturnMethod = [boolean, OptionSelect[]];

const useLoadHandbookItemsForSelect = (handbookId?: string): ReturnMethod => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionSelect[]>([]);
  useEffect(() => {
    if (!handbookId) {
      return;
    }
    setIsLoading(true);
    loadCurrentHandbookItems(handbookId).then((r) => {
      setOptions(r);
      setIsLoading(false);
    });
  }, [handbookId]);

  return [isLoading, options];
};

export default useLoadHandbookItemsForSelect;
