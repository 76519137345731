import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';

const AdditionalServicesTableStyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  font-size: 14px;
  max-width: 100%;
  position: relative;

  .trigger {
    cursor: pointer;
    border: none !important;
    background-color: white;
    color: #383838;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 0 0 19px 19px;
    box-shadow: 0 14px 24px -3px rgba(0, 5, 115, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: ${breakpoints.values.md}px) {
      bottom: -16px;
    }

    svg {
      fill: #383838;
    }

    &:hover {
      background-color: #4db28e;
      color: white;

      svg {
        fill: white;
      }
    }
  }

  .MuiDataGrid-root .MuiDataGrid-row {
    border-bottom: 1px solid #dbe0ec !important;
  }

  .MuiDataGrid-root .MuiDataGrid-row:last-child {
    border-bottom: 1px solid transparent !important;
  }
  .cell {
  }

  .MuiDataGrid-root {
    border-radius: 0;
  }

  .MuiDataGrid-columnHeaders {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .MuiDataGrid-root {
    border-radius: 0;
  }

  .default-table-container {
    &--full-width {
      max-width: 100%;
    }

    &__header {
      width: 100%;

      .visibility__path {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: white;
        padding: 29px 25px 25px 20px;
        border: 1px solid #dbe0ec;
        border-radius: 20px 20px 0 0;
        transition: 400ms;
        position: relative;

        .trigger {
          position: absolute;
          bottom: -15px;
          z-index: 10;
          left: calc(50% - 50px);
          background-color: #0eab70;
          width: 100px;
          height: 30px;
          align-items: center;
          justify-content: center;
          display: flex;
          transition: 400ms;
          border-radius: 7px;

          svg {
            fill: white;
          }

          &:hover {
            background-color: #4db28e;
          }
        }

        &.--open {
          background: #f6f6f6;
        }
      }

      .fields-wrapper {
        padding: 29px 25px 25px 20px;
        border: 1px solid #dbe0ec;
        border-top: none;
      }
    }

    &__number-input {
      .MuiDataGrid-cellContent,
      .MuiDataGrid-editInputCell {
        display: flex;
        width: 50px;
        padding: 7px 0 6px 0;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #dbe0ec;
      }

      .MuiInputBase-input {
        padding: 0;
        text-align: center;
      }
    }
  }

  .MuiDataGrid-columnHeaders {
    background: #eff3fb;
  }

  .MuiDataGrid-cell {
    border-bottom: none !important;
  }
`;

export default AdditionalServicesTableStyledContainer;
