import { AdditionalInfo } from '@pages/AdditionalServices/context/types';

const ADDITIONAL_INFO: AdditionalInfo = {
  taxes: [],
  currencies: [],
  contractors: {
    consulting: [],
    inspection: [],
    certification: [],
    customs: [],
    insurance: [],
  },
};

export default ADDITIONAL_INFO;
