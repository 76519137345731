import { TaxServiceCellStyledContainer } from '@components/pages/Products/components/RouteProduct/StyledComponents';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

const GetTaxValue = (
  props: GridRenderCellParams<
    Date & {
      tax: {
        vat: string;
        value: string;
      };
    }
  >
) => {
  return (
    <TaxServiceCellStyledContainer>
      {props.row.tax.vat !== '' && <span className={'with-right-border'}>{props.row.tax.vat}</span>}
      {props.row.tax.value !== '' && <span>{props.row.tax.value}</span>}
    </TaxServiceCellStyledContainer>
  );
};

const getAdditionalServiceTableHeadForDetail = () => {
  const { t } = useTranslation(['OfferDetail', 'Common']);
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      headerAlign: 'center',
      minWidth: 285,
      cellClassName: 'default-table-container__text-left',
    },
    {
      field: 'cost',
      headerName: t('OfferDetail:Cost'),
      headerAlign: 'center',
      minWidth: 130,
      flex: 1,
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'count',
      headerName: t('Common:Qty'),
      headerAlign: 'center',
      minWidth: 83,
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'unit',
      headerName: t('OfferDetail:Unit'),
      headerAlign: 'center',
      width: 130,
      flex: 1,
      align: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'sum',
      headerName: t('Common:Sum'),
      headerAlign: 'center',
      minWidth: 130,
      flex: 1,
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'tax',
      headerName: t('Common:Tax'),
      headerAlign: 'center',
      flex: 1,
      minWidth: 240,
      renderCell: GetTaxValue,
      cellClassName: 'default-table-container__text-center default-table-container--no-padding',
    },
    {
      field: 'total',
      headerName: t('Common:Total'),
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      cellClassName: 'default-table-container__text-center default-table-container__last-cell',
    },
  ];

  return columns;
};

export default getAdditionalServiceTableHeadForDetail;
