import { ContractorLoaderInterface } from '@services/requests/searchLoaders/contractorLoader/interface';
import {
  ContractorData,
  ContractorLoaderQuery,
  ContractorLoaderQueryResponse,
} from '@services/requests/searchLoaders/contractorLoader/queries/ContractorLoaderQuery';
import { DivisionContractorLoaderQuery } from '@services/requests/searchLoaders/contractorLoader/queries/DivisionContractorLoaderQuery';
import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import { localizedMessagesService } from '../../localizedMessagesService';
import { LocalizedMessagesServiceInterfaces } from '../../localizedMessagesService/interfaces';

/**
 * Загрузчик данных по подрядчикам
 */
export class ContractorLoader implements ContractorLoaderInterface {
  private readonly client: GraphQLClient;
  private readonly logger: Logger;
  private readonly localizationLoader: LocalizedMessagesServiceInterfaces;

  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.logger = loggerFactory().make(`ContractorLoader`);
    this.localizationLoader = localizedMessagesService(token);
  }

  async Load(primaryKeys?: any[]): Promise<ContractorData[]> {
    try {
      const resp = await this.client.Query<null, ContractorLoaderQueryResponse>(
        new ContractorLoaderQuery(primaryKeys),
        {}
      );

      // Формируем общий список локализаций (ID)
      const localizedMessageIds = resp.items.reduce(
        (result: string[], item: ContractorData): string[] => [...result, ...item.localized_names],
        []
      );

      // Загружаем все локализованные тексты
      const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);
      resp.items.map((item) => {
        item.localized_names_data = messages.filter(
          (m) => item.localized_names.indexOf(m.id) !== -1
        );
      });

      return resp.items;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  async LoadDivisionContractors(division: string): Promise<ContractorData[]> {
    try {
      const resp = await this.client.Query<null, ContractorLoaderQueryResponse>(
        new DivisionContractorLoaderQuery(division),
        {}
      );
      return resp.items;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
