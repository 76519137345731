import RoutePathString from '@components/pages/RoutePathString';
import Typography from '@mui/material/Typography';
import {
  calculateCargoForContainers,
  calculateCargoForCustom,
} from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/helpers';
import { ProductSummaryProps, RouteResult } from '@services/requests/orderService/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Компонент вывода сводной информации по маршруту
const RouteSummary = (props: ProductSummaryProps) => {
  const {
    product: { data },
  } = props;
  const { t } = useTranslation(['OrderList', 'Common']);

  // Получаем данные маршрута
  const route: RouteResult = JSON.parse(data);

  const {
    baseData: { containers, units },
    terminals,
    routePointsItems: { from, to },
  } = route;

  // Получаем описание типа груза
  const cargo =
    route.type === 'container'
      ? calculateCargoForContainers(containers, route.cargoParameters.containerParameters)
      : calculateCargoForCustom(units, route.cargoParameters.customCargoParameters);

  let points: BranchItem[] = [
    ...(route.route.routes[0].route.steps
      .map((s) => terminals.find((term) => term.id === s.startTerminal?.id))
      .filter((s) => !!s) as BranchItem[]),
    to,
  ];

  // Массив 'terminals' может не содержать начального терминала
  if (points[0].id !== from.id && from.type === 'terminal') {
    points = [from, ...points];
  }

  // если отправка идет не из терминала
  if (from.type !== 'terminal') {
    points.unshift(from);
  }

  return (
    <div className={'order-product-component'}>
      <Typography variant={'h4'}>
        <RoutePathString value={points} />
        <span>
          {', '} {route.route.deliveryTime}{' '}
          {route.route.deliveryTime <= 1 ? t('Common:Day') : t('Common:Days')}
        </span>
      </Typography>
      <Typography variant={'body1'}>{cargo}</Typography>
    </div>
  );
};

// Экспортируем компонент
export default RouteSummary;
