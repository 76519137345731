import { RouteStep, RoutesGroup } from '@services/requests/orderService/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { LocationTerminalOption } from '@services/requests/routeCalculator/locationsBranchSearchService/types';

import { RouteWidgetBaseParameters } from '../../../types';
import { generateLocalizedName } from '../helpers';
import { RouteShoulderProps } from './RouteShoulder';

export const getContainerRentDescription = (route: RoutesGroup, rentName: string) => {
  const isCOC = !!route.routes.find(
    (route) => !!route.route.steps.find((step) => step.shoulderOffer.container_affiliation_id === 1)
  );

  const isContainerRent = !!route.routes.find((r) => !!r.route.containerRent);

  return `${isCOC ? 'COC' : 'SOC'}${isContainerRent ? ' / ' + rentName : ''}`;
};

/**
 * Генерация данных по плечам маршрутов
 * @param steps
 * @param startLocation
 * @param endLocation
 * @param terminals
 * @param baseData
 * @param primaryLanguageId
 */
export const generateStepsData = (
  steps: RouteStep[],
  startLocation: BranchItem,
  endLocation: BranchItem,
  terminals: BranchItem[],
  baseData: RouteWidgetBaseParameters,
  primaryLanguageId = ''
): RouteShoulderProps[] =>
  steps
    .map((step) => {
      const isStartFromTerminal = !!step.startTerminal;

      let startPoint = { ...startLocation };
      if (isStartFromTerminal) {
        const terminal = terminals.find((t) => t.id === step.startTerminal.id);
        if (terminal) {
          startPoint = { ...terminal };
        }
      }

      const isEndFromTerminal = !!step.endTerminal;

      let endPoint = { ...endLocation };
      if (isEndFromTerminal) {
        const terminal = terminals.find((t) => t.id === step.endTerminal.id);
        if (terminal) {
          endPoint = { ...terminal };
        }
      }

      const stepShoulderType = baseData.shoulderTypes.find(
        (s) => s.id === step.shoulder.shoulder_type
      );

      const contractor = baseData.contractors.find((c) => c.id === step.shoulder.contractor_id);

      const contractorName =
        (contractor &&
          generateLocalizedName(
            contractor.default_name,
            contractor.localized_names_data,
            primaryLanguageId
          )) ||
        '';

      const baseStepData = {
        deliveryDays: step.shoulderOffer.delivery_time,
        endContractorName: contractorName,
        endLocation: endPoint,
        routeStepTypes: [`${stepShoulderType?.transport_type}`],
        startContractorName: contractorName,
        startLocation: startPoint,
      };

      if (stepShoulderType?.transport_type) {
        return [baseStepData];
      }

      return step.shoulder.shoulderSteps.map((shoulderStep, idx) => {
        switch (idx) {
          case 0:
            return {
              ...baseStepData,
              routeStepTypes: [shoulderStep.transportType.id],
              endLocation:
                terminals.find((t) => t.id === shoulderStep.endTerminal?.id) ??
                new LocationTerminalOption(),
            };
          case step.shoulder.shoulderSteps.length - 1:
            return {
              ...baseStepData,
              routeStepTypes: [shoulderStep.transportType.id],
              deliveryDays: 0,
              startLocation:
                terminals.find((t) => t.id === shoulderStep.startTerminal?.id) ??
                new LocationTerminalOption(),
            };
        }

        return {
          ...baseStepData,
          routeStepTypes: [shoulderStep.transportType.id],
          deliveryDays: 0,
          startLocation:
            terminals.find((t) => t.id === shoulderStep.startTerminal?.id) ??
            new LocationTerminalOption(),
          endLocation:
            terminals.find((t) => t.id === shoulderStep.endTerminal?.id) ??
            new LocationTerminalOption(),
        };
      });
    })
    .flat();
