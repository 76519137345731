import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { styled } from '@mui/material';

const RouteSummaryStyledContainer = styled('section')`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 27px 23px;
  background-color: ${palette.light.blue[200]};
  border-radius: 18px;

  @media (max-width: ${breakpoints.values.xsm}px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
  }

  .aside {
    flex: 0 0 max-content;
  }

  .cargo-params {
    color: ${({ theme }) => theme.palette.common.black};
    display: grid;
    gap: 2px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
      padding: 0;
      margin: 0;
      display: flex;
      gap: 3px;
      line-height: normal;
    }
  }

  .delivery-time {
    color: ${({ theme }) => theme.palette.grey[300]};
    font-weight: 400;
  }

  .full-cost,
  .route {
    color: ${({ theme }) => theme.palette.common.black};
  }

  .tax,
  .currency .MuiTypography-subtitle1 {
    font-weight: 400;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.palette.grey[800]};
  }

  .tax {
    margin-top: 10px;
  }

  .currency {
    margin-top: 15px;
    div {
      font-weight: 400;
      color: ${({ theme }) => theme.palette.grey[800]};
    }
  }
`;

export default RouteSummaryStyledContainer;
