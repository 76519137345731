// import { ServicesCount } from '@pages/AdditionalServices/context/types';

export const getTotalCost = (
  childrenGridData: any,
  template: string,
//   dynamicServices: ServicesCount
) => {
  let totalServiceCost = 0;
  let totalServiceTax = 0;

  if (template === '1' || template === '4' || template === '5') {
    [totalServiceCost, totalServiceTax] = childrenGridData[0]?.rows.reduce(
      (prev, row) => {
        return [prev[0] + row.total, prev[1] + row.sumTax];
      },
      [0, 0]
    ) || [0, 0];
  } 
//   else if (template === '3') {
//     [totalServiceCost, totalServiceTax] = childrenGridData[0]?.rows.reduce(
//       (prev, row) => {
//         const percentAgentServices = 2;
//         const sum = (dynamicServices[row.id] || row.cost) * row.course;
//         const agentServicesSum = Math.round((sum / 100) * percentAgentServices);

//         return [prev[0] + Math.round(sum + agentServicesSum) + row.sumTax, prev[1] + row.sumTax];
//       },
//       [0, 0]
//     ) || [0, 0];
//   }

  return [totalServiceCost, totalServiceTax];
};
