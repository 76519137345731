import { styled } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import React from 'react';

const CurrencyCostCellContainer = styled('div')`
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

const CurrencyCostCell = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;

  const currency = row?.contractCurrency?.encode || '';

  return (
    <CurrencyCostCellContainer>
      {row.currencyAmount} {currency}
    </CurrencyCostCellContainer>
  );
};

export { CurrencyCostCell };
