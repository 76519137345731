import { ServicesProps } from '../../../../../../../../../../../services/requests/servicesService/servicesServicesService/interface';
import { BasketAdditionalService } from '../../../../../../../../../context/types';
import { getColumnsForAdditionalService } from './DataForTableGenerator';
import { getHeaderRowForTemplate3 } from './HeaderRowGeneratorTemplate3';
import { getResultRowForTemplate3 } from './ResultRowGeneratorTemplate3';
import { getRowsForTemplate3 } from './RowGeneratorTemplate3';

export const getChildrenGridDataTemplate3 = (
  serivces: ServicesProps[],
  basket: BasketAdditionalService,
  template: string
) => {
  const result = serivces
    .reduce((acc, s) => {
      const columns = getColumnsForAdditionalService(s, template); // Получаем колонки для текущего элемента
      const rows = getRowsForTemplate3(s, basket[s.id]); // Получаем строки для текущего элемента

      const columnsString = JSON.stringify(columns);

      // Проверяем, существует ли уже объект с такими же колонками
      const existingEntry = acc.find((entry) => JSON.stringify(entry.columns) === columnsString);

      if (existingEntry) {
        // Если такой объект существует, добавляем к нему строки
        existingEntry.rows.push(...rows);
      } else {
        // Если такого объекта нет, создаем новый
        acc.push({
          rows: rows,
          columns: columns,
        });
      }

      return acc;
    }, [])
    .filter(Boolean);

  const resultRow = getResultRowForTemplate3(result[0].rows);
  const headerRow = getHeaderRowForTemplate3();

  result[0].rows.push(resultRow);
  result[0].rows.unshift(headerRow);

  return result;
};
