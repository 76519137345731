import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const Container = styled('div')`
  display: grid;
  gap: 20px;

  .first__lvl {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 62px;
    gap: 20px;
    align-items: center;

    .delivery__selectors {
      display: grid;
      grid-template-columns: 1fr 40px 1fr;
      gap: 10px;
      align-items: center;
    }
  }

  .card-wrapper {
    padding: 30px 40px;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.values.miniLaptop}px) {
      padding: 20px;
    }
  }
`;

export const DrawerButton = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1299;
  background: white;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.1);
  border-radius: 25px 25px 0 0;

  svg {
    fill: black;
  }

  .header {
    .from-to {
      margin-bottom: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 18.2px */
      letter-spacing: 0.28px;
      text-decoration-line: underline;
    }
  }
`;

export const DrawerButtonInner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 30px;

  .MuiIconButton-root {
    margin-left: auto;
  }

  .header {
    .from-to {
      margin-bottom: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 18.2px */
      letter-spacing: 0.28px;
      text-decoration-line: underline;
    }
  }
`;

export const SwipeableDrawerStyledContainer = styled(SwipeableDrawer)`
  &.MuiModal-hidden {
    .card-wrapper {
      overflow: inherit;
    }
  }

  .card-wrapper {
    border-radius: 20px 20px 0 0;
    padding: 20px;
    max-height: 90vh;
    position: relative;
    overflow: auto;
    box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.1);
  }

  .MuiDrawer-paper {
    background: transparent;
    height: max-content;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    border-radius: 20px 20px 0 0;
  }

  .range-date {
    .terminal-location-paper,
    .location-selector__list-wrapper {
      max-height: 225px;
    }
  }
`;

export default Container;
