import { $error } from '@settings/errorContext';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';

import { Logger, LoggerFactory } from '../logger/Logger';
import { Collection } from '../types';
import { GraphQLClient, GraphQLQuery } from './GraphQLClient';

import { ERROR_KEYS_TO_HANDLE } from './consts';

/**
 * Реализация клиента GraphQL сервера на основе Apollo
 */
export class Client implements GraphQLClient {
  private readonly defaultHeaders: Collection<string>;

  private readonly client: ApolloClient<NormalizedCacheObject>;

  private readonly logger: Logger;

  /**
   * Конструктор клиента
   *
   * @param loggerFactory
   * @param client
   * @param defaultHeaders
   */
  constructor(
    loggerFactory: LoggerFactory,
    client: ApolloClient<NormalizedCacheObject>,
    defaultHeaders: Collection<string> = {}
  ) {
    this.logger = loggerFactory.make('GraphQLClient');
    this.client = client;
    this.defaultHeaders = defaultHeaders;
  }

  /**
   * Выполнение запроса к серверу
   *
   * @param query
   * @param headers
   * @param currentTry
   */
  async Query<V, Response>(
    query: GraphQLQuery<V>,
    headers: Collection<string>,
	isThrowError: boolean = true,
    currentTry?: number,
  ): Promise<Response> {
    if (!currentTry) {
      currentTry = 1;
    }

    try {
      const response = await this.client.query<Response, V>({
        query: query.query,
        variables: query.variables,
        context: {
          headers: {
            ...this.defaultHeaders,
            ...headers,
          },
        },
      });

      if (response.errors !== undefined) {
        this.logger.Error('Request failed', query, response.errors);
		if (isThrowError) {
			$error.next('GraphQL request failed');
		}
      }

	  const errorKey = ERROR_KEYS_TO_HANDLE.find(key => {
		return response.errors?.find(error => error.message.includes(key));
	  });

	  const error = response.errors?.find( error => error.message.includes(errorKey) );

	  if (!!error) {
      const requestCount = +error.message.split('::').at(-1);
      return { data: { errorType: errorKey, requestCount } } as Response;
    }

      return response.data;
    } catch (e) {
      this.logger.Error(
        'Request failed',
        e,
        'Query:',
        query.query.loc.source.body,
        'variables:',
        query.variables
      );
    }
  }

  /**
   * Выполнение запроса обновления к серверу
   *
   * @param query
   * @param headers
   */
  async Mutation<V, Response>(
    query: GraphQLQuery<V>,
    headers: Collection<string>
  ): Promise<Response> {
    try {
      const response = await this.client.mutate<Response, V>({
        mutation: query.query,
        variables: query.variables,
        context: {
          headers: {
            ...this.defaultHeaders,
            ...headers,
          },
        },
      });

      if (response.errors !== undefined) {
        this.logger.Error('Request failed', query, response.errors);
        $error.next('GraphQL request failed');
      }

      if (!response.data) {
        $error.next('Undefined data returns by GraphQL request');
      }

      return response.data;
    } catch (e) {
      this.logger.Error(
        'Request failed',
        e,
        'Query:',
        query.query.loc.source.body,
        'variables:',
        query.variables
      );

      $error.next(`${e}`);
    }
  }
}
