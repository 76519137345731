import CardWrapper from '@components/UI/CardWrapper';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import useWidget from '@pages/AdditionalServices/context';
import FilterComponent from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceFilter/components/FilterComponent';
import useFilterController from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceFilter/useFilterController';
import Container from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/Filter/StyledComponents';
import React from 'react';

const AdditionalServiceFilter = () => {
  const { useAdditionalInfo, useInit } = useWidget();
  const [ready] = useInit().useState();
  const [{ currencies, taxes, contractors }] = useAdditionalInfo().useState();
  const { filterListing, reset } = useFilterController();

  if (!ready) {
    return (
      <Box sx={{ marginTop: '20px' }}>
        <Skeleton variant="rectangular" width={'100%'} height={'620px'} />
      </Box>
    );
  }

  return (
    <CardWrapper sx={{ padding: '20px', marginTop: '20px' }}>
      <Container>
        <div className={'title'}>{'Параметры поиска'}</div>
        {Object.keys(filterListing)
          .sort((a, b) => filterListing[a].property.ordinal - filterListing[b].property.ordinal)
          .map((key) => (
            <FilterComponent
              key={key}
              taxes={taxes}
              currencies={currencies}
              contractors={contractors}
              entity={filterListing[key]}
            />
          ))}
        <div className={'reset-all'} onClick={reset}>
          {'Сбросить все фильтры'}
        </div>
      </Container>
    </CardWrapper>
  );
};

export default AdditionalServiceFilter;
