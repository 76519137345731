import getEnv from '@settings/getEnv';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link'

import { loggerFactory } from '../logger';
import { Client } from './Client';
import { GraphQLClient } from './GraphQLClient';
import { MainClient } from './MainClient';
import { Link } from './Link';
import { RetryLink } from './RetryLink';

export const graphQLClient: { (token?: string): GraphQLClient } = (token?: string) => {
  return new MainClient(
    new Client(
      loggerFactory(),
      new ApolloClient<NormalizedCacheObject>({
		link: ApolloLink.from([RetryLink, Link]),
        cache: new InMemoryCache({
          addTypename: false,
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
      })
    ),
    token
  );
};
