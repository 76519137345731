import { CHECK_EXCLUDE_KEYS, VALIDATE_CHECKS } from './consts';
import { BaseCustomsPaymentConfig, CustomsPaymentProducts } from './types';

export const checkErrorExisting = (keys: string[], errors: string[]) => {
  return keys.every((key) => errors.includes(key));
};

export const validateBaseCustomsPaymentConfig = (baseConfig: BaseCustomsPaymentConfig) => {
  const { borderDelivery, otherExpenses } = baseConfig;

  const errorFieldKeys = Object.entries({ borderDelivery, otherExpenses }).map(([key, value]) => {
    const { cost, currency } = value;
    const isCostError = !cost || cost.length === 0;
    const isCurrencyError = !currency;

    return [isCostError && `${key}.cost`, isCurrencyError && `${key}.currency`].filter(Boolean);
  });

  return errorFieldKeys.flat();
};

export const validateCustomsPaymentProducts = (products: CustomsPaymentProducts) => {
  return products.map((product) =>
    Object.entries(product)
      .map(([key, value]) => {
        if (CHECK_EXCLUDE_KEYS.includes(key)) {
          return null;
        }

        const check = VALIDATE_CHECKS[key];

        if (check instanceof RegExp) {
          return !check.test(value) && key;
        } else if (typeof check === 'boolean') {
          return check !== value && key;
        }

        return null;
      })

      .filter(Boolean)
  );
};
