import { styled } from '@mui/material';

const TerminalsGroupFilterStyledContainer = styled('div')`
  margin-bottom: 12px;
  .checkbox-grid {
    margin-left: 12px;
  }

  .location-checkbox {
    padding: 0 5px 0 0;
    border-color: #cfd5e2;
  }

  .location-filter {
    margin-left: 0;
    & .MuiTypography-root {
      font-size: 12px;
      font-weight: 500;
      color: #22262d;
    }
    & .MuiCheckbox--description {
      font-size: 10px;
      font-weight: 500;
      color: #6c7484;
    }
  }
`;

export default TerminalsGroupFilterStyledContainer;
