export default function getEnv() {
  // @ts-ignore
  if (__TRANSPORT_WIDGET_GLOBAL_CONFIGURATION_DATA) {
    // @ts-ignore
    return __TRANSPORT_WIDGET_GLOBAL_CONFIGURATION_DATA;
  }

  return {
    REACT_APP_DOMAIN: process.env.REACT_APP_DOMAIN,
    REACT_APP_PATH: process.env.REACT_APP_PATH,
    REACT_APP_GRAPHQL_SERVER: process.env.REACT_APP_GRAPHQL_SERVER,
    REACT_APP_PDF_VIEW_ENDPOINT: process.env.REACT_APP_PDF_VIEW_ENDPOINT,
    REACT_APP_FILE_UPLOADING_ENDPOINT: process.env.REACT_APP_FILE_UPLOADING_ENDPOINT,
    REACT_APP_FILE_VIEW_ENDPOINT: process.env.REACT_APP_FILE_VIEW_ENDPOINT,
    REACT_APP_DEBUG: process.env.REACT_APP_DEBUG,
    REACT_APP_TOKEN_REFRESH_TIMEOUT: process.env.REACT_APP_TOKEN_REFRESH_TIMEOUT,
    REACT_APP_YANDEX_MAP_API_KEY: process.env.REACT_APP_YANDEX_MAP_API_KEY,
    REACT_APP_PDF_SIZE_PX: process.env.REACT_APP_PDF_SIZE_PX,
    REACT_APP_AUTH_COOKIE: process.env.REACT_APP_AUTH_COOKIE,
    REACT_APP_MAX_REFETCH_CALCULATION_NUMBER: process.env.REACT_APP_MAX_REFETCH_CALCULATION_NUMBER,
    REACT_APP_ALTA_PROXY_ENDPOINT: process.env.REACT_APP_ALTA_PROXY_ENDPOINT,
  };
}
