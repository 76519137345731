import { styled } from '@mui/material';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { OverridableStringUnion } from '@mui/types';
import { ContractorsOptions, FieldState } from '@pages/AdditionalServices/context/types';
import GetField from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/GetFields/components/GetFields';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import { ServicesFieldProps } from '@services/requests/servicesService/interface';
import { TaxData } from '@services/requests/taxLoader/TaxLoaderQuery';
import { SetterContext } from '@settings/core/stateContexts/types';
import React from 'react';

interface Props {
  fields: ServicesFieldProps[];
  contractorsOptions: ContractorsOptions;
  taxes: TaxData[];
  size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
  currencies: CurrencyData[];
  state: FieldState;
  realTimeValidation?: boolean;
  setState(value: SetterContext<FieldState>): void;
}

const Container = styled('div')`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
`;

const ContainerItem = styled('div')`
  width: 200px;
`;

const FieldsMap = (props: Props) => {
  return (
    <Container className={'field-wrapper'}>
      {props.fields.map((t, index) => (
        <ContainerItem className={'field-wrapper'} key={`GetField-${index}`}>
          <GetField withLabel={true} field={t} {...props} />
        </ContainerItem>
      ))}
    </Container>
  );
};

export default FieldsMap;
