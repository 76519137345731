import { ServicesProps } from '../../../../../../../../../../../services/requests/servicesService/servicesServicesService/interface';
import { BasketAdditionalService } from '../../../../../../../../../context/types';
import {
  getColumnsForAdditionalService,
  getRowsForAdditionalService,
} from './DataForTableGenerator';

export const getChildrenGridDataRegularTemplate = (
  childs: ServicesProps[],
  basket: BasketAdditionalService,
  template: string
) => {
  return childs
    .reduce((acc, s) => {
      const columns = getColumnsForAdditionalService(s, template); // Получаем колонки для текущего элемента
      const rows = getRowsForAdditionalService(s, basket[s.id]); // Получаем строки для текущего элемента

      const columnsString = JSON.stringify(columns);

      // Проверяем, существует ли уже объект с такими же колонками
      const existingEntry = acc.find((entry) => JSON.stringify(entry.columns) === columnsString);

      if (existingEntry) {
        // Если такой объект существует, добавляем к нему строки
        existingEntry.rows.push(...rows);
      } else {
        // Если такого объекта нет, создаем новый
        acc.push({
          rows: rows,
          columns: columns,
        });
      }

      return acc;
    }, [])
    .filter(Boolean);
};
