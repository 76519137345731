import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { customShadows } from '@assets/theme/shadows';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const OrderListItemStyledContainer = styled(Paper)`
  position: relative;
  padding: 25px 20px 25px 30px;
  border-radius: 18px;
  box-shadow: ${customShadows.light.z20};
  display: grid;
  grid-template-columns: 40px 1fr min-content 20px;
  gap: 20px 17px;
  align-items: flex-start;
  min-height: 107px;

  input[type='checkbox'] {
    display: block;
  }
  .service-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
  }

  .route-select {
    align-self: center;
  }

  @media (max-width: ${breakpoints.values.sm}px) {
    width: 100%;
    grid-auto-flow: row;
    grid-template-columns: 1fr;

    .mobile-actions {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      gap: 30px;
      justify-content: end;
    }
  }

  .right-column {
    display: grid;
    gap: 10px;
    text-align: right;

    @media (max-width: ${breakpoints.values.xsm}px) {
      order: -1;
      display: flex;
      gap: 17px;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .price {
    white-space: nowrap;
    margin-right: 3px;
    font-weight: 600;
    font-size: 16px;
    color: black;
    padding-left: 40px;

    @media (max-width: ${breakpoints.values.xsm}px) {
      text-align: right;
      width: 100%;
      padding-left: 0;
    }
  }

  .order-product-component {
    display: grid;
    gap: 7px;
    margin-bottom: 17px;
    color: ${palette.light.common.black};
  }

  .order-item {
    &__service-content {
      grid-column: span 2;
    }

    &__service-actions {
      position: absolute;
      right: 55px;
      bottom: 24px;

      .icon-button-block {
        margin-top: 0;
      }

      &.--top {
        bottom: auto;
        top: 23px;
      }
    }
  }

  .calc-dates {
    color: ${palette.light.grey[300]};

    @media (max-width: ${breakpoints.values.xsm}px) {
      line-height: 1.3;
    }
  }

  .close {
    width: 20px;
    height: 20px;
    background-color: ${palette.light.grey[100]};
    color: ${palette.light.grey[400]};
    padding: 0;

    &.service-close {
      margin-top: ${({ theme }) => theme.spacing(1.25)};
    }

    @media (hover: hover) {
      &:hover {
        color: ${palette.light.grey[500]};
      }
    }

    svg {
      font-size: 16px;
      color: inherit;
      border-radius: 50%;
    }
  }

  .icon-button {
    padding: 0;
    width: ${({ theme }) => theme.spacing(5)};
    height: ${({ theme }) => theme.spacing(5)};

    &-block {
      display: flex;
      gap: 5px;
      justify-content: flex-end;

      @media (max-width: ${breakpoints.values.xsm}px) {
        gap: 8px;
        margin-top: 0;
      }
    }

    svg {
      font-size: 24px;

      @media (max-width: ${breakpoints.values.xsm}px) {
        font-size: 26px;
      }
    }
  }
`;

export default OrderListItemStyledContainer;
