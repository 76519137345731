import MButton from '@assets/mui/@material-extend/MButton';
import MIconButton from '@assets/mui/@material-extend/MIconButton';
import palette from '@assets/theme/palette';
import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import ArchiveIcon from '@components/icons/ArchiveIcon';
import SettingIcon from '@components/icons/SettingIcon';
import BreadCrumbsHOC, { BreadCrumbProps } from '@components/UI/breadcrumbs';
import { YaTargetWrapper } from '@components/YaTargetWrapper/YaTargetWrapper';
import { LinearProgress, Typography, useMediaQuery } from '@material-ui/core';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from '@mui/material/styles';
import { SortData } from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useDelivery from '../../useDelivery';
import DeliveryFilterContainer from './DeliveryFilterContainer';
import RouteListOrderTabs from './DeliveryFilterContainer/SortTubs/RouteListOrderTabs';
import { RoutesCalculationLoader } from './RoutesCalculationLoader';
import RoutesListRoutesGrid from './RoutesListRoutesGrid';
import Container from './StyledComponents';

// Компонент вывода листинга маршрутов
const RoutesListComponent = () => {
  const {
    useIsArchiveLoading,
    useIsLoading,
    useIsRoutesLoading,
    useAggregationData,
    useRoutes,
    useTerminals,
    useSortData,
    useBaseDate,
    useRouteParams,
    useIsMoreRoutesLoading,
    useLimit,
    useOffset,
    useSettings,
    actions: { recalculateRoutes },
  } = useDelivery();

  const [isArchiveLoading, setArchiveMode] = useIsArchiveLoading().useState();
  const [isLoading] = useIsLoading().useState();
  const [isRoutesLoading] = useIsRoutesLoading().useState();
  const [aggregationData] = useAggregationData().useState();
  const [routes] = useRoutes().useState();
  const [terminals] = useTerminals().useState();
  const [sortData, setSort] = useSortData().useState();
  const [baseData] = useBaseDate().useState();
  const [routeParams] = useRouteParams().useState();
  const { t } = useTranslation(['RoutesListComponent', 'PageNames']);
  const [limit, setLimit] = useLimit().useState();
  const [offset, setOffset] = useOffset().useState();
  const [{ primaryLanguageId, secondaryLanguageId }] = useSettings().useState([
    'primaryLanguageId',
    'secondaryLanguageId',
  ]);
  const setIsMoreRoutesLoading = useIsMoreRoutesLoading().setState;
  const onCalculate = () => recalculateRoutes(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(false);
  const handleCloseFilter = () => {
    setOpen(false);
  };

  const handleClickOpenFilter = () => {
    setOpen(true);
  };

  const setSortData = (sort: SortData) => {
    setLimit(10);
    setOffset(0);
    setSort((current) => ({
      ...current,
      ...sort,
    }));
    onCalculate();
  };

  const setPage = (page: number) => {
    setLimit(limit);
    setOffset((page - 1) * limit);
    setIsMoreRoutesLoading(true);
    onCalculate();
  };

  /**
   * Рендеринг кнопки архивных маршрутов
   */
  const renderArchiveButton = () => {
    if (isArchiveLoading) {
      return;
    }

    return (
      <YaTargetWrapper target="calculator_arch_routes" noAuthTarget="calculator_arch_routes_noreg">
        <BitrixDisableUserGuard>
          <MButton
            className={'routes-list__archive'}
            onClick={() => {
              setArchiveMode(true);
              recalculateRoutes(true);
            }}
            fullWidth={true}
            variant={'outlined'}
            startIcon={<ArchiveIcon />}
            size={'large'}
            color={'inherit'}
            sx={{
              color: palette.light.blue[400],
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            <span>{t('RoutesListComponent:ShowArchivedRoutes')}</span>
          </MButton>
        </BitrixDisableUserGuard>
      </YaTargetWrapper>
    );
  };

  if (isLoading && baseData) {
    return <RoutesCalculationLoader />;
  }

  if (!aggregationData) {
    return <></>;
  }

  if (aggregationData.deliveryTime.min <= 0 && aggregationData.price.max <= 0) {
    return (
      <>
        <Typography variant={'h3'} sx={{ margin: '20px 0' }}>
          {t('RoutesListComponent:RoutesNotFound')}
        </Typography>
        {renderArchiveButton()}
      </>
    );
  }

  const noRoutes = !routes || routes.total === 0;

  if (!routeParams || !baseData) {
    return (
      <>
        <Typography variant={'h3'} sx={{ margin: '20px 0' }}>
          {t('RoutesListComponent:RoutesNotFound')}
        </Typography>
        {renderArchiveButton()}
      </>
    );
  }

  const breadCrumbs: BreadCrumbProps[] = [
    {
      id: 1,
      name: t('PageNames:MainPage'),
      url: 'widget',
    },
    {
      id: 1,
      name: t('PageNames:Delivery'),
    },
  ];

  return (
    <>
      <BreadCrumbsHOC crumbs={breadCrumbs} />
      <Container>
        {routeParams && (
          <DeliveryFilterContainer open={open} handleCloseFilter={handleCloseFilter} />
        )}
        <div className={'main-content-wrapper'}>
          <div className={'wrapper__order'}>
            {isMobile && (
              <div className={'params__order'} onClick={handleClickOpenFilter}>
                <div className={'mobile__title'}>
                  <SettingIcon />
                  <Typography variant="h3">{t('RoutesListComponent:ByParameters')}</Typography>
                </div>
                <MIconButton size={'small'} edge="end" color="inherit" aria-label="close">
                  <KeyboardArrowUpIcon sx={{ color: '#707070', fontSize: '25px' }} />
                </MIconButton>
              </div>
            )}
            {!noRoutes && (
              <RouteListOrderTabs
                sortData={sortData}
                disabled={isRoutesLoading}
                baseFilterData={aggregationData}
                routes={routes}
                onChangeSortData={setSortData}
              />
            )}
          </div>
          <>
            {isRoutesLoading && <LinearProgress />}
            {!isRoutesLoading && noRoutes && (
              <Typography variant={'h3'} sx={{ margin: '20px 0' }}>
                {t('RoutesListComponent:RoutesNotFound')}
              </Typography>
            )}
            {!isRoutesLoading && !noRoutes && (
              <RoutesListRoutesGrid
                isRoutesLoading={isRoutesLoading}
                routes={{
                  routes,
                  terminals,
                }}
                locations={[]}
                sortData={sortData}
                limit={limit}
                offset={offset}
                baseData={baseData}
                onChangePage={setPage}
                mainLanguage={secondaryLanguageId}
                systemLanguage={primaryLanguageId}
              />
            )}
            {!isRoutesLoading && renderArchiveButton()}
          </>
        </div>
      </Container>
    </>
  );
};

// Экспортируем компонент
export default RoutesListComponent;
