import ShoulderItemTable from '@components/pages/Products/components/RouteProduct/components/ShoulderItemTable';
import AdditionServiceCaption from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/AdditionServiceCaption';
import { ProductComponentProps } from '@components/pages/Products/types';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import getAdditionalServiceTableHeadForDetail from '@helpers/getAdditionalServiceTableHeadForDetail';
import getAdditionalServiceTableRowsForDetail from '@helpers/getAdditionalServiceTableRowsForDetail';
import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
import { ServicesServicePriceCalculationMutationProps } from '@services/requests/servicesService/servicesServicesService/interface';
import React, { FC } from 'react';

const ServiceDetailProduct: FC<ProductComponentProps & { serviceName: string | undefined }> = ({
  product,
  realTimeBaseData: { currencies },
  taxNames,
  serviceName,
}) => {
  const [settings] = useMyRouting().useSettings().useState();

  const amount = product.amount;

  const data = JSON.parse(product.data);

  // Услуга может содержать дочерние услуги и в этом случае выводится несколько строк в таблице
  let service: ServicesServicePriceCalculationMutationProps[] = [data.service];

  if (Array.isArray(data)) {
    service = data.map((s) => JSON.parse(s.data).service);
  }

  const title = serviceName
    ? serviceName
    : service[0].service.localized_names_data.find(
        (l: LocalizedMessage) => l.lang_id === settings?.secondaryLanguageId
      )?.message || service[0].service.name;

  const currencyService = currencies.find(
    (c) => String(c.id) === String(service[0].result.result.innerCurrency)
  );

  const targetCurrency = currencies.find((c) => c.id === settings.currencyId);

  const columns = getAdditionalServiceTableHeadForDetail();
  const rows = getAdditionalServiceTableRowsForDetail({
    service,
    targetCurrency: targetCurrency.code,
    currencyService: currencyService.code,
    langId: settings?.secondaryLanguageId || '1',
    amount,
    taxNames,
  });

  return (
    <ShoulderItemTable
      variant={'default'}
      header={
        <AdditionServiceCaption
          cost={product.price}
          targetCurrency={targetCurrency}
          title={title}
        />
      }
      rows={rows}
      columns={columns}
    />
  );
};

export default ServiceDetailProduct;
