import TextField from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import useWidget from '@pages/AdditionalServices/context';
import React, { useState } from 'react';

const CountField = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;
  const [simpleValue, setValue] = useState<string>(row.count);
  const [count, setCount] = useWidget().useServicesCount().useState([row.id]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (value === '0' || value === '') {
      setValue('');
      setCount((prevState) => ({
        ...prevState,
        [row.id]: 1,
      }));
      return;
    }

    setValue(value);
    setCount((prevState) => ({
      ...prevState,
      [row.id]: Number(value),
    }));
  };

  const reset = () => {
    !simpleValue && setValue(`${count[row.id]}`);
  };

  return (
    <TextField
      onBlur={reset}
      fullWidth
      size={'small'}
      InputLabelProps={{ shrink: true }}
      type={'number'}
      label={''}
      variant="outlined"
      value={simpleValue}
      onChange={onChange}
    />
  );
};

export default CountField;
