import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
`;

export default Container;
