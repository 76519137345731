import { styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import React from 'react';

const ServiceNameContainer = styled('div')`
  width: 100%;
  overflow: hidden;
`;

const ServicesName = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;

  if (row.type === 'result') {
    return (
      <Tooltip title={'Итог'} style={{ width: '300px' }} placement={'top-end'}>
        <ServiceNameContainer>{'Итог'}</ServiceNameContainer>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={row.name} style={{ width: '300px' }} placement={'top-end'}>
      <ServiceNameContainer>{row.name}</ServiceNameContainer>
    </Tooltip>
  );
};

export default ServicesName;
