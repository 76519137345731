import { MButton } from '@assets/mui/@material-extend';
import MRadio from '@assets/mui/@material-extend/MRadio';
import palette from '@assets/theme/palette';
import { useMediaQuery } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { getLocalizedMobileResultTableLabels } from '@pages/AdditionalServices/tabs/CustomsPayments/consts';
import { getAntiDumpingCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getAntiDumpingCalc';
import { getExciseCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getExciseCalc';
import { getFeeCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getFeeCalc';
import { getTotalCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getTotalCalc';
import { getVatCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getVatCalc';
import { customsTaxService } from '@services/requests/customsTaxService';
import {
  ExportCalculationRateResponse,
  ImportCalculationRateResponse,
} from '@services/requests/customsTaxService/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomsPayments } from '../../context';
import { checkErrorExisting } from '../../context/helpers';
import { CustomsPaymentProduct, ProductTotal } from '../../context/types';
import { CustomsPaymentsCodeSelect } from '../CustomsPaymentsCodeSelect';
import { CustomsPaymentsTableMobile } from '../CustomsPaymentsTableMobile';
import { ProductHelp } from '../ProductHelp';
import { Container } from './StyledComponents';
import { Row, getResultTableColumns } from './tableColumns';
import { AdditionalTax, isResponseValidateErrorPredicate } from './types';

interface CustomsPaymentsProductItemProps {
  product: CustomsPaymentProduct;
  productIndex: number;
  isLastProduct: boolean;
}

let vatDirectory = '';

function CustomsPaymentsProductItem({
  product,
  productIndex,
  isLastProduct,
}: CustomsPaymentsProductItemProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const [additionalTax, setAdditonalTax] = useState<AdditionalTax | null>(null);
  const [isCalculating, setIsCalculating] = useState(false);

  const { useBaseCustomsPaymentData, useCustomsPaymentProducts, useInvalidFields, actions } =
    useCustomsPayments();
  const setProducts = useCustomsPaymentProducts().setState;
  const [{ countries, currencies }] = useBaseCustomsPaymentData().useState();
  const [invalidFields, setInvalidFields] = useInvalidFields().useState();
  const { customsMode, borderDelivery, otherExpenses, contractCurrency } = product;
  const { onRemoveCustomsPaymentProduct } = actions;

  const isExport = customsMode === '2';

  const {
    code,
    contractCurrencyCost,
    country,
    originCertificateExisting,
    weight,
    maximumRetailPackagePrice,
    packagesCount,
    productsCount,
    selectedFee,
    selectedExcise,
    selectedExportFee,
    selectedVat,
    isCalculated,
  } = product;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const autocompleteFormattedCountries = countries.map((country) => ({
    value: country.Code,
    label: country.Name[0] + country.Name.slice(1).toLowerCase(),
  }));

  const selectedCountry = autocompleteFormattedCountries.find((c) => c.value === country);

  const isCode = isFinite(+code);

  const isGetRatesBtnDisabled =
    !code || !contractCurrencyCost || !country || !weight || code.length !== 10 || !isCode;
  const countryTypeText =
    customsMode === '1'
      ? t('CustomsPayments:OriginCountryPlaceholder')
      : t('CustomsPayments:DestinationCountryPlaceholder');

  const isSpecialFieldsVisible = typeof selectedExcise?.Prim === 'string';
  const isAdditionalExpensesVisible =
    borderDelivery.cost &&
    borderDelivery.currency &&
    otherExpenses.cost &&
    otherExpenses.currency &&
    isCalculated;
  const isWeightFieldVisible =
    selectedFee && selectedExcise && selectedFee.Measure?.Razm !== selectedExcise.Measure?.Razm;
  const isResultVisible =
    !!additionalTax?.excises.length ||
    !!additionalTax?.fees.length ||
    !!additionalTax?.vats.length ||
    !!additionalTax?.exportFees.length;

  const errors = useMemo(() => {
    const errors = invalidFields[productIndex] ?? [];
    const filteredErrors = errors.filter((key) => {
      if (!isWeightFieldVisible && key === 'weight') {
        return false;
      }
      if (
        !isSpecialFieldsVisible &&
        (key === 'packagesCount' || key === 'maximumRetailPackagePrice')
      ) {
        return false;
      }
      return true;
    });

    return filteredErrors;
  }, [invalidFields, productIndex, isWeightFieldVisible, isSpecialFieldsVisible]);

  let getRatesBtnTextColor = checkErrorExisting(['isCalculated'], errors)
    ? palette.light.error.main
    : palette.light.secondary.main;

  if (isGetRatesBtnDisabled) {
    getRatesBtnTextColor = 'rgba(145, 158, 171, 0.8)';
  }

  const localizedMobileHeaderTableLabels = getLocalizedMobileResultTableLabels(
    t('CustomsPayments:RateColumnTitle'),
    t('CustomsPayments:RubPriceColumnTitle'),
    t('CustomsPayments:CurrencyPriceColumnTitle')
  );

  const getCellClassName: DataGridProps<Row>['getCellClassName'] = ({ row }) => {
    if (row.id === 'total') {
      return 'total-text';
    }
    return '';
  };

  const getRowClassName: DataGridProps<Row>['getRowClassName'] = ({ row }) => {
    if (row.id === 'total') {
      return 'wide-row';
    }
    return '';
  };

  const changeProductValueHandler = <T extends keyof CustomsPaymentProduct>(
    key: T,
    value: CustomsPaymentProduct[T]
  ) => {
    setInvalidFields((prevState) =>
      prevState.map((product, index) => {
        if (index !== productIndex) {
          return product;
        }
        return product.filter((errorKey) => errorKey !== key);
      })
    );
    setProducts((prevState) => {
      const changedProduct = { ...prevState[productIndex] };
      changedProduct[key] = value;

      const stateCopy = prevState.slice();
      stateCopy.splice(productIndex, 1, changedProduct);

      return stateCopy;
    });
  };

  const changeOriginCountryHandler = (value: typeof selectedCountry) => {
    setInvalidFields((prevState) =>
      prevState.map((product, index) => {
        if (index !== productIndex) {
          return product;
        }
        return product.filter((errorKey) => errorKey !== 'country');
      })
    );
    setProducts((prevState) => {
      const changedProduct = { ...prevState[productIndex] };
      changedProduct.country = value?.value;

      const stateCopy = prevState.slice();
      stateCopy.splice(productIndex, 1, changedProduct);

      return stateCopy;
    });
  };

  const changeProductTotalHandler = (key: keyof ProductTotal, value: number) => {
    setProducts((prevState) => {
      const changedProduct = { ...prevState[productIndex] };

      changedProduct.total = {
        ...changedProduct.total,
        [key]: value,
      };

      const stateCopy = prevState.slice();
      stateCopy.splice(productIndex, 1, changedProduct);

      return stateCopy;
    });
  };

  const resetFieldsHandler = () => {
    changeProductValueHandler('weight', '0');
    changeProductValueHandler('productsCount', '0');
    changeProductValueHandler('maximumRetailPackagePrice', '0');
    changeProductValueHandler('packagesCount', '0');
  };

  const resetTaxParamsHandler = () => {
    changeProductValueHandler('selectedFee', null);
    changeProductValueHandler('selectedExcise', null);
    changeProductValueHandler('selectedVat', null);
    changeProductValueHandler('selectedExportFee', null);
    setAdditonalTax(null);
    changeProductValueHandler('isCalculated', false);
  };

  useEffect(() => {
    if (customsMode && contractCurrency) {
      resetFieldsHandler();
      resetTaxParamsHandler();
    }
  }, [customsMode, contractCurrency]);

  const calculateAdditionalTaxHandler = async () => {
    if (isCalculated) {
      return;
    }

    setIsCalculating(true);
    const formattedCustomsMode = customsMode === '1' ? 'import' : 'export';
    const formattedCertificate = originCertificateExisting ? '1' : '0';

    try {
      const result = await customsTaxService().CalculateRates(formattedCustomsMode, {
        mode: formattedCustomsMode,
        tncode: code,
        certificate: formattedCertificate,
        country,
      });

      if (isResponseValidateErrorPredicate(result)) {
        setIsCalculating(false);
        return;
      }

      const fees =
        formattedCustomsMode === 'import'
          ? (result as ImportCalculationRateResponse).Importlist.Import
          : [];
      const formattedFees = Array.isArray(fees) ? fees : [fees];

      const exportFees =
        formattedCustomsMode === 'export'
          ? (result as ExportCalculationRateResponse).Exportlist.Export
          : [];
      const formattedExportFees = Array.isArray(exportFees) ? exportFees : [exportFees];

      const vats =
        formattedCustomsMode === 'import'
          ? (result as ImportCalculationRateResponse).VATlist.VAT
          : [];
      const formattedVats = Array.isArray(vats) ? vats : [vats];
      const formattedUniqueVats = formattedVats.map((vat, index) => ({
        ...vat,
        Value: vat.Value + '|' + index,
      }));

      const excises = result.Exciselist?.Excise;
      let formattedUniqueExices = [];
      if (excises) {
        const formattedExices = Array.isArray(excises) ? excises : [excises];
        formattedUniqueExices = formattedExices.map((vat, index) => ({
          ...vat,
          Value: vat.Value + '|' + index,
        }));
      }

      const defaultSelectedFee = formattedFees?.[0] || null;
      const defaultSelectedExportFee = formattedExportFees?.[0] || null;
      const defaultSelectedVat = formattedUniqueVats?.[0] || null;
      const defaultSelectedExcise = formattedUniqueExices?.[0] || null;

      setAdditonalTax({
        excises: formattedUniqueExices,
        fees: formattedFees,
        vats: formattedUniqueVats,
        exportFees: formattedExportFees,
      });

      changeProductValueHandler('selectedFee', defaultSelectedFee);
      changeProductValueHandler('selectedExcise', defaultSelectedExcise);
      changeProductValueHandler('selectedVat', defaultSelectedVat);
      changeProductValueHandler('selectedExportFee', defaultSelectedExportFee);
      changeProductValueHandler('isCalculated', true);
      setIsCalculating(false);
    } catch (e) {
      setIsCalculating(false);
      return;
    }
  };

  const [feeRowData, exportFeeRowData] = getFeeCalc({
    contractCurrency,
    currencies,
    localizedMobileHeaderTableLabels,
    product,
    selectedExportFee,
    selectedFee,
    title: t('CustomsPayments:DutyGroupTitle'),
  });

  useEffect(() => {
    changeProductTotalHandler(
      'feeSum',
      isExport ? +exportFeeRowData.rublesAmount : +feeRowData.rublesAmount
    );
  }, [feeRowData]);

  const exciseRowData = getExciseCalc({
    contractCurrency,
    localizedMobileHeaderTableLabels,
    product,
    selectedExcise,
    title: t('CustomsPayments:ExciseGroupTitle'),
  });

  useEffect(() => {
    changeProductTotalHandler('exciseSum', +exciseRowData?.rublesAmount || 0);
  }, [exciseRowData]);

  const vatRowData = getVatCalc({
    contractCurrency,
    exciseRowData,
    feeRowData,
    localizedMobileHeaderTableLabels,
    product,
    selectedVat,
    title: t('CustomsPayments:VatGroupTitle'),
  });

  useEffect(() => {
    changeProductTotalHandler('vatSum', +vatRowData.rublesAmount);
  }, [vatRowData]);

  const antiDumpingRowData = getAntiDumpingCalc();

  useEffect(() => {
    changeProductTotalHandler('antiDumpingSum', +antiDumpingRowData?.rublesAmount || 0);
  }, [antiDumpingRowData]);

  const result = getTotalCalc({
    antiDumpingRowData,
    exciseRowData,
    exportFeeRowData,
    feeRowData,
    vatRowData,
    localizedMobileHeaderTableLabels,
    isExport,
    contractCurrency,
    title: t('CustomsPayments:TotalCustomsPaymentsRowTitle'),
  });

  return (
    <Container>
      <div className="product-header-wrap">
        <div className="title">
          {t('CustomsPayments:ProductTitle')} #{productIndex + 1}
        </div>
        {!isLastProduct && (
          <IconButton size={'small'} onClick={() => onRemoveCustomsPaymentProduct(productIndex)}>
            <CloseIcon sx={{ fontSize: 25 }} />
          </IconButton>
        )}
      </div>
      <div className="main-config-wrap">
        <Autocomplete
          fullWidth
          disablePortal
          id="origin-country"
          className="origin-country"
          value={selectedCountry || null}
          onChange={(_, value) => {
            changeOriginCountryHandler(value);
            resetFieldsHandler();
            resetTaxParamsHandler();
          }}
          options={autocompleteFormattedCountries}
          renderInput={(params) => (
            <TextField
              {...params}
              error={checkErrorExisting(['country'], errors)}
              label={countryTypeText}
            />
          )}
        />
        <CustomsPaymentsCodeSelect
          onChangeCode={changeProductValueHandler}
          onResetTaxParams={resetTaxParamsHandler}
          onResetFields={resetFieldsHandler}
          errors={errors}
        />
        {isWeightFieldVisible && (
          <TextField
            fullWidth
            size={'medium'}
            type={'number'}
            className="cost-field-input"
            error={checkErrorExisting(['weight'], errors)}
            label={t('CustomsPayments:GrossWeightPlaceholder')}
            variant="outlined"
            value={weight}
            onChange={(event) => {
              changeProductValueHandler('weight', event.target.value);
            }}
          />
        )}
        <TextField
          fullWidth
          size={'medium'}
          type={'number'}
          className="cost-field-input"
          error={checkErrorExisting(['contractCurrencyCost'], errors)}
          label={t('CustomsPayments:PricePlaceholder')}
          variant="outlined"
          value={contractCurrencyCost}
          onChange={(event) =>
            changeProductValueHandler('contractCurrencyCost', event.target.value)
          }
        />
      </div>
      <div>
        <FormControlLabel
          className="origin-certificate"
          label={t('CustomsPayments:CertificateOriginAvailable')}
          control={
            <Checkbox
              checked={originCertificateExisting}
              onChange={(event) =>
                changeProductValueHandler('originCertificateExisting', event.target.checked)
              }
              size={'small'}
            />
          }
        />
      </div>
      <div>
        <MButton
          className={'get-rates-btn'}
          variant={'outlined'}
          color={checkErrorExisting(['isCalculated'], errors) ? 'error' : 'secondary'}
          fullWidth={true}
          disabled={isGetRatesBtnDisabled}
          onClick={calculateAdditionalTaxHandler}
        >
          {isCalculating && (
            <CircularProgress
              size={20}
              style={{ color: palette.light.secondary.main, marginRight: '20px' }}
            />
          )}
          <Typography color={getRatesBtnTextColor} fontSize={14}>
            {t('CustomsPayments:CalculatePaymentsBtn')}
          </Typography>
        </MButton>
      </div>
      {additionalTax && (
        <div className="additional-tax-wrap">
          {!!additionalTax.fees.length && (
            <div className="additional-tax-item fee-wrap">
              <span className="additional-tax-item__title">
                {t('CustomsPayments:DutyGroupTitle')}
              </span>
              <div className="additional-tax-item__body">
                <RadioGroup aria-label={'НДС'} name={'vat'}>
                  {additionalTax.fees.map((fee, index) => (
                    <FormControlLabel
                      key={'fee-' + fee.ValueDetail + '-' + index}
                      control={
                        <MRadio
                          size={'small'}
                          className={'radio'}
                          checked={selectedFee.Value === fee.Value}
                          onChange={() => {
                            changeProductValueHandler('selectedFee', fee);
                            resetFieldsHandler();
                          }}
                          sx={{
                            padding: '5px 8px',
                          }}
                        />
                      }
                      classes={{
                        label: 'radio-label',
                      }}
                      label={
                        <span key={'fee-text-' + index} className="additional-tax-text">
                          {fee.Value}
                          <span dangerouslySetInnerHTML={{ __html: fee.Prim }}></span>
                          {fee.Order && fee.Link && (
                            <>
                              {' '}
                              (
                              <a href={fee.Link} target="_blank" rel="noopener noreferrer">
                                {fee.Order}
                              </a>
                              )
                            </>
                          )}
                        </span>
                      }
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>
          )}
          {!!additionalTax.vats.length && (
            <div className="additional-tax-item vat-wrap">
              <span className="additional-tax-item__title">
                {t('CustomsPayments:VatGroupTitle')}
              </span>
              <div className="additional-tax-item__body">
                <RadioGroup aria-label={'НДС'} name={'vat'}>
                  {additionalTax.vats.map((vat) => {
                    let directoryContent = <></>;

                    if (
                      vat?.Directory &&
                      vatDirectory !== vat?.Directory.RuName &&
                      typeof vat.Directory.RuName === 'string'
                    ) {
                      directoryContent = <span>{vat.Directory.RuName}</span>;
                      vatDirectory = vat.Directory.RuName;
                    }

                    return (
                      <React.Fragment key={'vat-' + vat.Value}>
                        {directoryContent}
                        <FormControlLabel
                          control={
                            <MRadio
                              size={'small'}
                              className={'radio'}
                              checked={selectedVat.Value === vat.Value}
                              onChange={() => {
                                changeProductValueHandler('selectedVat', vat);
                                resetFieldsHandler();
                              }}
                              sx={{
                                padding: '5px 8px',
                              }}
                            />
                          }
                          classes={{
                            label: 'radio-label',
                          }}
                          label={`${vat.ValueDetail.ValueCount} ${vat.ValueDetail.ValueUnit}${
                            typeof vat.Condition === 'string' ? ` ${vat.Condition}` : ''
                          }`}
                        />
                      </React.Fragment>
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
          )}

          <div className="additional-tax-item excise-tax-wrap">
            <span className="additional-tax-item__title">
              {t('CustomsPayments:ExciseGroupTitle')}
            </span>
            <div className="additional-tax-item__body">
              {!additionalTax.excises.length && (
                <span className="additional-tax-text">{t('CustomsPayments:ExciseNoTax')}</span>
              )}
              {!!additionalTax.excises.length && (
                <RadioGroup aria-label={'НДС'} name={'vat'}>
                  {additionalTax.excises.map((excise) => (
                    <FormControlLabel
                      key={'vat-' + excise.Value}
                      control={
                        <MRadio
                          size={'small'}
                          className={'radio'}
                          checked={selectedExcise.Value === excise.Value}
                          onChange={() => {
                            changeProductValueHandler('selectedExcise', excise);
                            resetFieldsHandler();
                          }}
                          sx={{
                            padding: '5px 8px',
                          }}
                        />
                      }
                      classes={{
                        label: 'radio-label',
                      }}
                      label={`${excise.Value.split('|')[0]}${
                        typeof excise.Condition === 'string' ? ` ${excise.Condition}` : ''
                      }`}
                    />
                  ))}
                </RadioGroup>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="result-wrap">
        <div className="result-item">
          <div className="result-item__row">
            <div className="result-item__col">
              {isResultVisible && !isExport && (
                <TextField
                  fullWidth
                  className="result-item__input"
                  variant={'standard'}
                  value={productsCount}
                  focused={checkErrorExisting(['productsCount'], errors)}
                  error={checkErrorExisting(['productsCount'], errors)}
                  onChange={(event) => {
                    changeProductValueHandler('productsCount', event.target.value);
                  }}
                  type={'number'}
                  InputProps={{
                    inputMode: 'numeric',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{ color: 'grey[700]', position: 'absolute', pointerEvents: 'none' }}
                          component={'span'}
                          variant={'h5'}
                        >
                          {t('CustomsPayments:ProductQuantityLabel')}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {isSpecialFieldsVisible && (
                <TextField
                  fullWidth
                  className="result-item__input"
                  variant={'standard'}
                  value={packagesCount}
                  focused={checkErrorExisting(['packagesCount'], errors)}
                  error={checkErrorExisting(['packagesCount'], errors)}
                  onChange={(event) =>
                    changeProductValueHandler('packagesCount', event.target.value)
                  }
                  type={'number'}
                  InputProps={{
                    inputMode: 'numeric',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{ color: 'grey[700]', position: 'absolute', pointerEvents: 'none' }}
                          component={'span'}
                          variant={'h5'}
                        >
                          {t('CustomsPayments:PackagesCount')}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>
            {isSpecialFieldsVisible && (
              <div className="result-item__col">
                <TextField
                  fullWidth
                  className="result-item__input"
                  variant={'standard'}
                  focused={checkErrorExisting(['maximumRetailPackagePrice'], errors)}
                  error={checkErrorExisting(['maximumRetailPackagePrice'], errors)}
                  value={maximumRetailPackagePrice}
                  onChange={(event) =>
                    changeProductValueHandler('maximumRetailPackagePrice', event.target.value)
                  }
                  type={'number'}
                  InputProps={{
                    inputMode: 'numeric',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{ color: 'grey[700]', position: 'absolute', pointerEvents: 'none' }}
                          component={'span'}
                          variant={'h5'}
                        >
                          {t('CustomsPayments:PackageMaxPriceLabel')}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          </div>
          {isAdditionalExpensesVisible && (
            <div className="result-item__row">
              {!isMobile && (
                <>
                  <div className="result-item__col">
                    <span className="result-item__cost-title result-item__cost">
                      {t('CustomsPayments:BorderShippingCosts')}
                    </span>
                    <span className="result-item__cost-title result-item__cost">
                      {t('CustomsPayments:OtherExpenses')}
                    </span>
                  </div>
                  <div className="result-item__col">
                    <span className="result-item__cost-value result-item__cost">
                      {borderDelivery?.cost} {borderDelivery?.currency?.encode}
                    </span>
                    <span className="result-item__cost-value result-item__cost">
                      {otherExpenses?.cost} {otherExpenses?.currency?.encode}
                    </span>
                  </div>
                </>
              )}

              {isMobile && (
                <>
                  <div className="result-item__col">
                    <span className="result-item__cost-title result-item__cost">
                      {t('CustomsPayments:BorderShippingCosts')}
                    </span>
                    <span className="result-item__cost-value result-item__cost">
                      {borderDelivery?.cost} {borderDelivery?.currency?.encode}
                    </span>
                  </div>
                  <div className="result-item__col">
                    <span className="result-item__cost-title result-item__cost">
                      {t('CustomsPayments:OtherExpenses')}
                    </span>
                    <span className="result-item__cost-value result-item__cost">
                      {otherExpenses?.cost} {otherExpenses?.currency?.encode}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        {isResultVisible && (
          <div className="result-item">
            <div className="result-item__table">
              {isMobile && <CustomsPaymentsTableMobile results={result} />}
              {!isMobile && (
                <DataGrid
                  rows={result}
                  columns={getResultTableColumns(
                    t('CustomsPayments:PaymentTypeColumntTitle'),
                    t('CustomsPayments:RateColumnTitle'),
                    t('CustomsPayments:RubPriceColumnTitle'),
                    t('CustomsPayments:CurrencyPriceColumnTitle')
                  )}
                  hideFooter
                  getRowClassName={getRowClassName}
                  getCellClassName={getCellClassName}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  disableDensitySelector
                  disableColumnSelector
                  showCellVerticalBorder
                  disableVirtualization
                  disableColumnFilter
                  showColumnVerticalBorder
                  getRowHeight={() => {
                    return 50;
                  }}
                  sx={{
                    border: '1px solid #dbe0ec',
                    borderTop: 0,
                    borderRadius: '0 0 20px 20px',
                    '& .MuiDataGrid-cell': {
                      borderColor: '#dbe0ec',
                    },
                    '& .MuiDataGrid-columnHeader': {
                      borderColor: '#dbe0ec',
                    },
                    '& .MuiDataGrid-row': {
                      borderBottomColor: '#dbe0ec',
                    },
                    '& .MuiDataGrid-withBorderColor': {
                      borderBottomColor: '#dbe0ec',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      position: 'static',
                    },
                    '& .MuiDataGrid-virtualScrollerContent': {
                      height: 'auto !important',
                    },
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <ProductHelp product={product} productIndex={productIndex} />
    </Container>
  );
}

export { CustomsPaymentsProductItem };
