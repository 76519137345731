import useWidget from '@pages/AdditionalServices/context';
import {ContractorsOptions} from '@pages/AdditionalServices/context/types';
import {handbookService} from '@services/requests/handbookService';
import {CurrencyData} from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import {locationsLoader} from '@services/requests/searchLoaders/locationsLoader';
import {ServicesProperty} from '@services/requests/servicesService/servicesServicesService/interface';
import {TaxData} from '@services/requests/taxLoader/TaxLoaderQuery';
import React, {useEffect, useState} from 'react';
import {styled} from "@mui/material";

const loadCurrentHandbookItems = async (handbookId: string, itemId: string) => {
    const filter = {
        where: {
            id: {
                _equals: handbookId,
            },
        },
    };
    const handBook = await handbookService().LoadPublishedListByParams(filter);

    if (handBook.length === 0) {
        return 'Пусто';
    }

    const itemName = handBook[0].items.find((i) => i.id === itemId)?.name;

    return itemName ? itemName : 'Пусто';
};

const getDefaultLocations = async (_in: string) => {
    const temp = await locationsLoader().LoadByParams({where: {id: {_in: [_in]}}});

    return temp[0].default_name;
};

const getLabel = async (
    property: ServicesProperty,
    id: number,
    data: any,
    currencies: CurrencyData[],
    taxes: TaxData[],
    contractors: ContractorsOptions
) => {
    switch (property.type) {
        case 'hidden':
        case 'switch':
        case 'number':
            if (data.format) {
                return data.format.replace("{{VALUE}}", id);
            }
            return id;
        case 'handbook':
            const handBookValue = await loadCurrentHandbookItems(data.handbook_id, String(id)).then((r) => r);
            if (data.format) {
                return data.format.replace("{{VALUE}}", handBookValue);
            }
            return handBookValue;
        case 'location':
            const locationValue = await getDefaultLocations(String(id)).then((r) => r);
            if (data.format) {
                return data.format.replace("{{VALUE}}", locationValue);
            }
            return locationValue;
        case 'tax':
            const taxValue = taxes.find((i) => i.id === String(id)).default_name;
            if (data.format) {
                return data.format.replace("{{VALUE}}", taxValue);
            }
            return taxValue;
        case 'currency':
            const currencyValue = currencies.find((i) => i.id === String(id)).default_name;
            if (data.format) {
                return data.format.replace("{{VALUE}}", currencyValue);
            }
            return currencyValue;
        case 'certification_contractor':
        case 'consulting_contractor':
        case 'customs_contractor':
        case 'inspection_contractor':
        case 'insurance_contractor':
            const [type] = property.type.split('_');
            const contractorValue = contractors[type].find((i) => i.value === String(id))?.label;
            if (data.format) {
                return data.format.replace("{{VALUE}}", contractorValue)
            }
            return contractorValue;
        default:
            return '';
    }
};

interface Props {
    item: {
        ordinal: number,
        value: number,
        label: string,
        property: ServicesProperty,
    };
}

interface LabelContainerProps {
    isNameVisible: boolean
    isNameBold: boolean
}

interface ValueContainerProps {
    isValueBold: boolean
}

const LabelContainer = styled('span')`
  display: ${({isNameVisible}: LabelContainerProps) => isNameVisible ? 'inline-block' : "none"};
  font-weight: ${({isNameBold}: LabelContainerProps) => isNameBold ? 'bold' : "400"};
`;

const ValueContainer = styled('span')`
  font-weight: ${({isValueBold}: ValueContainerProps) => isValueBold ? 'bold' : "400"};
`;

const PropertyItem = ({item}: Props) => {
    const [{currencies, taxes, contractors}] = useWidget().useAdditionalInfo().useState();
    const [fullValue, setFullValue] = useState<string>('');
    const {label, value, property} = item;
    const data = JSON.parse(property.settings)

    useEffect(() => {
        getLabel(item.property, value, data, currencies, taxes, contractors).then(setFullValue);
    }, []);

    return (
        <div className={'property'}>
            <LabelContainer isNameVisible={Boolean(data?.isNameVisible)} isNameBold={Boolean(data?.isNameBold)}>{label}: </LabelContainer>
            <ValueContainer isValueBold={Boolean(data?.isValueBold)}> {fullValue}</ValueContainer>
        </div>
    );
};

export default PropertyItem;
