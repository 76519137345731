import breakpoints from '@assets/theme/breakpoints';
import CardWrapper from '@components/UI/CardWrapper';
import { styled } from '@mui/material';

export const PriceWithTaxItemContainer = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  white-space: nowrap;

  &.--center {
    align-items: center;
    text-align: center;
  }
`;

const Container = styled(CardWrapper)`
  position: relative;
  padding: 25px 0 50px 0;

  .trigger {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 0;
    border: none !important;
    margin-top: 20px;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.palette.grey[800]};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 0 0 19px 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: ${breakpoints.values.md}px) {
      bottom: -16px;
    }

    svg {
      fill: ${({ theme }) => theme.palette.grey[800]};
    }

    &:hover {
      background-color: #4db28e;
      color: ${({ theme }) => theme.palette.common.white};

      svg {
        fill: ${({ theme }) => theme.palette.common.white};
      }
    }
  }

  .fields-wrapper {
    display: flex;
    flex-flow: row wrap;

    @media (max-width: ${breakpoints.values.xsm}px) {
      div {
        width: 100%;
      }
    }
  }

  .switch {
    border: none !important;
    margin-top: 20px;
    padding-bottom: 10px;
    color: #383838;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: 0 0 19px 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .top__content {
    display: grid;
    grid-template-columns: 1fr 110px;
    gap: 20px 60px;
    padding: 0 25px 0 25px;

    @media (max-width: ${breakpoints.values.md}px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 20px;
    }

    &--wrapper {
      max-width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
      width: 100%;
    }

    &--left {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      gap: 16px;
      max-width: 100%;
      flex: 1 1 auto;

      .name {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      @media (max-width: 1380px) {
        display: flex;
        flex-direction: column;
      }

      .steps {
        display: flex;
        flex-flow: column;
        gap: 20px;
      }

      .price-line {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;
        gap: 10px;

        .item {
          display: flex;
          gap: 20px;
          align-items: center;
        }

        @media (max-width: ${breakpoints.values.md}px) {
          align-items: start;
        }
      }

      @media (max-width: ${breakpoints.values.md}px) {
        display: flex;
        flex-direction: column;
      }

      .full__price {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        text-align: right;
        @media (max-width: ${breakpoints.values.md}px) {
          text-align: left;
        }
      }

      .taxes {
        color: ${({ theme }) => theme.palette.grey[700]};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        text-align: right;

        @media (max-width: ${breakpoints.values.md}px) {
          right: 0;
          line-height: 140%;
          letter-spacing: 0.28px;
        }
      }

      .taxes-wrapper {
        @media (max-width: 1380px) {
          text-align: left;
        }
        @media (max-width: ${breakpoints.values.md}px) {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }

      .taxes-price {
        color: ${({ theme }) => theme.palette.grey[700]};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: right;

        @media (max-width: ${breakpoints.values.md}px) {
          right: 0;
          line-height: normal;
        }
      }
    }

    &--right {
      display: flex;
      gap: 10px;
      flex-direction: column;

      @media (max-width: ${breakpoints.values.md}px) {
        order: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
      }

      .add-to-card-action {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 5px 10px;
        border-radius: 10px;
        color: ${({ theme }) => theme.palette.secondary.main};

        @media (max-width: ${breakpoints.values.md}px) {
          min-height: 45px;
        }
      }

      .in-card {
        color: ${({ theme }) => theme.palette.primary.main};
      }

      .offer-actions {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        white-space: nowrap;
        font-weight: 400;
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey[700]};
        padding: 5px 4px;
        border-radius: 10px;

        @media (max-width: ${breakpoints.values.md}px) {
          right: 0;
          width: fit-content;
          padding: 8px 14px;
          min-height: 45px;
        }
      }
    }

    .properties {
      display: grid;
      gap: 15px;
      flex: 0 0 100%;

      .property {
        color: black;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        span {
          color: #707070;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
        }
      }
    }
  }
`;

export default Container;
