import Typography from '@mui/material/Typography';
import React from 'react';

import { getCustomsPaymentWeight } from './helpers';
import { CodeItemProps } from './interface';

function CodeItem({ paymentData }: CodeItemProps) {
  const { code, total, selectedExcise, selectedFee, contractCurrency, contractCurrencyCost } =
    paymentData;

  const totalSum = Object.values(total).reduce((prev, item) => prev + item);

  const isSpecialFieldsVisible = typeof selectedExcise?.Prim === 'string';
  const isWeightFieldVisible =
    selectedFee && selectedExcise && selectedFee.Measure?.Razm !== selectedExcise.Measure?.Razm;

  return (
    <div className="customs-payments__wrap">
      <Typography variant={'h4'}>
        {code}: (
        {getCustomsPaymentWeight(isSpecialFieldsVisible, isWeightFieldVisible, paymentData)}),{' '}
        {contractCurrencyCost} {contractCurrency.encode}
      </Typography>
      <Typography variant={'body1'} className="price">
        {totalSum} RUB
      </Typography>
    </div>
  );
}

export { CodeItem };
