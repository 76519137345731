import HiddenContent from '@components/UI/HiddenContent';
import RangeSlider from '@components/UI/RangeSlider';
import {RadioGroup} from '@material-ui/core';
import useWidget from '@pages/AdditionalServices/context';
import {ContractorsOptions, FilterItem} from '@pages/AdditionalServices/context/types';
import GetItems
    from '@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceFilter/components/FilterComponent/GetItems';
import {CurrencyData} from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import {TaxData} from '@services/requests/taxLoader/TaxLoaderQuery';
import React from 'react';
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import MySwitch from "@components/MyMaterialUI/MySwitch";
import {ServicesPropertyValue} from "@services/requests/servicesService/servicesServicesService/interface";
import GetItemsForMultiple
    from "@pages/AdditionalServices/tabs/AdditionalForm/components/AdditionalServiceContainer/components/AdditionalServiceFilter/components/FilterComponent/GetItemsForMultiple";

interface Props {
    entity: FilterItem;
    currencies: CurrencyData[];
    taxes: TaxData[];
    contractors: ContractorsOptions;
}

const FilterComponent = (props: Props) => {
    const {currencies, taxes, contractors} = props;
    const {typeComponent, property, property_values} = props.entity;
    const {useListingFilter, useDefaultListingFilter} = useWidget();
    const [defaultFilter] = useDefaultListingFilter().useState();
    const [filterState, setFilterListing] = useListingFilter().useState();

    const resetItem = (key: string) => {
        setFilterListing((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                value: defaultFilter[key].value,
                handBookValue: defaultFilter[key].handBookValue,
                handBookValues: defaultFilter[key].handBookValues,
                switchValue: defaultFilter[key].switchValue,
            },
        }));
    };

    if (!property) {
        return <></>;
    }

    const deleteDuplicate: ServicesPropertyValue[] = property_values
        .sort((a, b) => Number(a.id) - Number(b.id))
        .reduce((acc, i) => {
            if (!acc.find((e) => e.value === i.value) && i.value !== 0) {
                acc.push(i);
            }

            return acc;
        }, []);

    const currentItem = filterState[property.id];

    if (typeComponent === 'multiple') {
        if (deleteDuplicate.length === 0) {
            return <></>;
        }

        return (
            <HiddenContent
                title={property.name}
                indicator={currentItem.handBookValues.length !== 0}
                defaultVisibility
                onReset={() => resetItem(property.id)}
            >
                <GetItemsForMultiple
                    entity={props.entity}
                    currencies={currencies}
                    taxes={taxes}
                    data={JSON.parse(property.settings)}
                    contractors={contractors}
                    options={deleteDuplicate}
                    values={currentItem.handBookValues}
                />
            </HiddenContent>
        );
    }

    if (typeComponent === 'handbook') {
        if (deleteDuplicate.length === 0) {
            return <></>;
        }
        return (
            <HiddenContent title={property.name} defaultVisibility onReset={() => resetItem(property.id)}>
                <RadioGroup aria-label={'RadioGroup'} name={'in-route-move'}>
                    {deleteDuplicate.map((value, index) => (
                        <GetItems
                            key={index}
                            entity={props.entity}
                            currencies={currencies}
                            data={JSON.parse(property.settings)}
                            taxes={taxes}
                            contractors={contractors}
                            currentItem={value.value}
                        />
                    ))}
                </RadioGroup>
            </HiddenContent>
        );
    }

    const minMaxValues = property_values
        .sort((a, b) => Number(a.id) - Number(b.id)).map((v) => v.value);

    const min = property.id === 'price' ? 0 : Math.min(...minMaxValues);
    const max = Math.max(...minMaxValues);

    if (typeComponent === 'switch') {
        return (
            <HiddenContent
                title={property.name}
                indicator={currentItem.switchValue !== null}
                defaultVisibility
                onReset={() => resetItem(property.id)}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant={'caption'}>Не включено</Typography>
                    <MySwitch value={currentItem.switchValue} onClick={() =>
                        setFilterListing((prev) => ({
                            ...prev,
                            [property.id]: {...prev[property.id], switchValue: currentItem.switchValue === 1 ? 0 : 1},
                        }))
                    }/>
                    <Typography variant={'caption'}>Включено</Typography>
                </Stack>
            </HiddenContent>
        )
    }

    if (!currentItem?.value) {
        return <></>;
    }

    return (
        <HiddenContent title={property.name} defaultVisibility onReset={() => resetItem(property.id)}>
            <RangeSlider
                ranges={{min, max}}
                value={currentItem.value}
                tooltipGenerator={(value, isToValue) => (isToValue ? max : min)}
                onChange={(value) =>
                    setFilterListing((prev) => ({
                        ...prev,
                        [property.id]: {...prev[property.id], value},
                    }))
                }
            />
        </HiddenContent>
    );
};

export default FilterComponent;
