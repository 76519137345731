import MIconButton from '@assets/mui/@material-extend/MIconButton';
import SettingIcon from '@components/icons/SettingIcon';
import CardWrapper from '@components/UI/CardWrapper';
import { Slide, useMediaQuery } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import VideoInstruction from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/VideoInstruction';
import { CurrencyItem } from '@services/requests/customsTaxService/interface';
import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomsPayments } from './context';
import { checkErrorExisting } from './context/helpers';
import { BaseCustomsPaymentConfig, Cost } from './context/types';
import { CustomsPaymentsDialogContainer, CustomsPaymentsStyledContainer } from './StyledComponents';

interface CustomsPaymentsFormWrapperProps {
  isOpened: boolean;
  onCollapse: () => void;
}

const Transition = forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

function CustomsPaymentsForm() {
  const { t } = useTranslation(['CustomsPayments']);
  const { useBaseCustomsPaymentData, useBaseCustomsPaymentConfig, useInvalidFields, actions } =
    useCustomsPayments();
  const [{ currencies }] = useBaseCustomsPaymentData().useState();
  const [invalidFields, setInvalidFields] = useInvalidFields().useState();
  const { onAddCustomsPaymentProduct } = actions;
  const [config, setConfig] = useBaseCustomsPaymentConfig().useState();
  const { contractCurrency, customsMode, borderDelivery, otherExpenses } = config;
  const errors = invalidFields[0] ?? [];

  const CUSTOMS_MODES = [
    {
      value: '1',
      label: t('CustomsPayments:Import'),
    },
    {
      value: '2',
      label: t('CustomsPayments:Export'),
    },
  ];

  const autocompleteFormattedCurrencies = currencies.map((currency) => ({
    value: currency,
    label: `${currency.encode}`,
  }));

  const currentCustomsMode = CUSTOMS_MODES.find((mode) => mode.value === customsMode);

  const currentCurrency = autocompleteFormattedCurrencies.find((currency) => {
    if (contractCurrency) {
      return currency.value === contractCurrency;
    }

    return currency.value.encode === 'USD';
  });

  const changeCurrencyHandler = (value: CurrencyItem) => {
    setConfig((prevState) => ({
      ...prevState,
      contractCurrency: value,
    }));
  };

  const changeCustomsModeHandler = (value: typeof currentCustomsMode) => {
    setConfig((prevState) => ({
      ...prevState,
      customsMode: value?.value,
    }));
  };

  const changeCostHandler = <
    U extends keyof Pick<BaseCustomsPaymentConfig, 'borderDelivery' | 'otherExpenses'>,
    T extends keyof Cost
  >(
    costKey: U,
    key: T,
    value: Cost[T]
  ) => {
    setInvalidFields((prevState) =>
      prevState.map((product) => product.filter((errorKey) => errorKey !== `${costKey}.${key}`))
    );

    setConfig((prevState) => {
      return {
        ...prevState,
        [costKey]: {
          ...prevState[costKey],
          [key]: value,
        },
      };
    });
  };

  useEffect(() => {
    if (contractCurrency) {
      return;
    }

    changeCurrencyHandler(currentCurrency?.value);
  }, [contractCurrency, currentCurrency]);

  const borderDeliveryCurrency =
    borderDelivery && borderDelivery.currency
      ? {
          value: borderDelivery.currency,
          label: `${borderDelivery.currency.name} (${borderDelivery.currency.encode})`,
        }
      : null;

  const otherExpensesCurrency =
    otherExpenses && otherExpenses.currency
      ? {
          value: otherExpenses.currency,
          label: `${otherExpenses.currency.name} (${otherExpenses.currency.encode})`,
        }
      : null;

  return (
    <CustomsPaymentsStyledContainer>
      <form id="customs-payment-form" autoComplete="off" className="customs-payment-form">
        <div className="customs-mode-wrap">
          <Autocomplete
            disablePortal
            id="customs-mode"
            className="customs-mode"
            disableClearable
            options={CUSTOMS_MODES}
            value={currentCustomsMode}
            onChange={(_, value) => changeCustomsModeHandler(value)}
            renderInput={(params) => (
              <TextField {...params} label={t('CustomsPayments:CustomsRegimePlaceholder')} />
            )}
          />
          <Autocomplete
            disablePortal
            id="contract-currency"
            className="contract-currency"
            disableClearable
            value={currentCurrency || null}
            options={autocompleteFormattedCurrencies}
            getOptionLabel={(option) => `${option.value.encode}`}
            onChange={(_, value) => changeCurrencyHandler(value?.value)}
            renderInput={(params) => (
              <TextField {...params} label={t('CustomsPayments:ContractCurrencyPlaceholder')} />
            )}
          />
        </div>

        <div className="delivery-cost-wrap">
          <div className="delivery-cost-fields-wrap">
            <Typography variant={'body1'} component={'div'} className="delivery-cost-title">
              <span>{t('CustomsPayments:BorderShippingCosts')}:</span>
            </Typography>

            <div className="cost-field-wrap">
              <TextField
                fullWidth
                size={'medium'}
                type={'number'}
                className="cost-field-input"
                label={t('CustomsPayments:AmountPlaceholder')}
                variant="outlined"
                error={checkErrorExisting(['borderDelivery.cost'], errors)}
                value={borderDelivery?.cost || ''}
                onChange={(event) =>
                  changeCostHandler('borderDelivery', 'cost', event.target.value)
                }
              />
            </div>
          </div>

          <Autocomplete
            disablePortal
            id="delivery-currency"
            className="contract-currency"
            isOptionEqualToValue={(option, value) => option.value.code === value.value.code}
            options={autocompleteFormattedCurrencies}
            value={borderDeliveryCurrency}
            onChange={(_, value) => changeCostHandler('borderDelivery', 'currency', value?.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={checkErrorExisting(['borderDelivery.currency'], errors)}
                label={t('CustomsPayments:CurrencyPlaceholder')}
              />
            )}
          />
        </div>

        <div className="other-expenses-wrap">
          <div className="other-cost-wrap">
            <div className="delivery-cost-fields-wrap">
              <Typography variant={'body1'} component={'div'} className="delivery-cost-title">
                <span>{t('CustomsPayments:OtherExpenses')}:</span>
              </Typography>

              <div className="cost-field-wrap">
                <TextField
                  fullWidth
                  size={'medium'}
                  type={'number'}
                  className="cost-field-input"
                  label={t('CustomsPayments:AmountPlaceholder')}
                  error={checkErrorExisting(['otherExpenses.cost'], errors)}
                  variant="outlined"
                  value={otherExpenses?.cost || ''}
                  onChange={(event) =>
                    changeCostHandler('otherExpenses', 'cost', event.target.value)
                  }
                />
              </div>
            </div>
            <Autocomplete
              disablePortal
              id="other-currency"
              className="contract-currency"
              options={autocompleteFormattedCurrencies}
              isOptionEqualToValue={(option, value) => option.value.code === value.value.code}
              value={otherExpensesCurrency}
              onChange={(_, value) => changeCostHandler('otherExpenses', 'currency', value?.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkErrorExisting(['otherExpenses.currency'], errors)}
                  label={t('CustomsPayments:CurrencyPlaceholder')}
                />
              )}
            />
          </div>
          <Button
            className={'add-products-btn'}
            variant={'contained'}
            color={'primary'}
            onClick={onAddCustomsPaymentProduct}
          >
            {t('CustomsPayments:AddProductBtn')}
          </Button>
        </div>
        <VideoInstruction />
      </form>
    </CustomsPaymentsStyledContainer>
  );
}

function CustomsPaymentsFormWrapper({ isOpened, onCollapse }: CustomsPaymentsFormWrapperProps) {
  const { t } = useTranslation(['CustomsPayments']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {!isMobile && (
        <CardWrapper sx={{ padding: '10px 20px', marginBottom: '20px' }}>
          <CustomsPaymentsForm />
        </CardWrapper>
      )}

      {isMobile && (
        <Dialog
          fullScreen
          open={isOpened}
          onClose={onCollapse}
          TransitionComponent={Transition}
          sx={{ m: 0 }}
        >
          <CustomsPaymentsDialogContainer>
            <div className={'mobile__title'}>
              <SettingIcon />
              <Typography variant="h3">{t('CustomsPayments:SearchOptions')}</Typography>
            </div>
            <MIconButton
              size={'small'}
              edge="end"
              color="inherit"
              onClick={onCollapse}
              aria-label="close"
            >
              <KeyboardArrowDownIcon sx={{ color: '#707070', fontSize: '25px' }} />
            </MIconButton>
          </CustomsPaymentsDialogContainer>
          <CustomsPaymentsForm />
        </Dialog>
      )}
    </>
  );
}

export { CustomsPaymentsFormWrapper };
