import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NoteItem } from '@services/requests/customsTaxService/interface';
import clsx from 'clsx';
import React from 'react';

interface ProductHelpItemProps {
  id: number;
  isExpanded: boolean;
  note: NoteItem;
  onToggleExpandItem: (item: number) => void;
}

function ProductHelpItem({ id, isExpanded, note, onToggleExpandItem }: ProductHelpItemProps) {
  const { title, description } = note;

  return (
    <div className="product-item">
      <div className="product-item__header" onClick={() => onToggleExpandItem(id)}>
        <span className="product-item__title">{title}</span>
        {isExpanded && <ExpandLessIcon fontSize="medium" />}
        {!isExpanded && <ExpandMoreIcon fontSize="medium" />}
      </div>
      <div className={clsx('product-item__body', { expanded: isExpanded })}>
        <p className="product-item__text" dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
    </div>
  );
}

export { ProductHelpItem };
