import { ServiceProductType } from '@services/requests/orderService/interface';
import { ProductSummaryProps } from '@services/requests/orderService/types';
import React, { FC } from 'react';

import AdditionalService from '../AdditionalService';
import CustomsPayments from '../CustomsPayments';

// Компонент вывода сводной информации по одной дополнительной услуге
const ServicesSummaryItem: FC<ProductSummaryProps> = ({
  product,
  langId,
  currencyCode,
  orderCurrency,
}) => {
  const serviceData = JSON.parse(product.data);
  const serviceType: ServiceProductType = Array.isArray(serviceData)
    ? serviceData[0].type
    : serviceData.type;

  switch (serviceType) {
    case 'additional-service':
      return (
        <AdditionalService
          product={product}
          langId={langId}
          currencyCode={currencyCode}
          orderCurrency={orderCurrency}
        />
      );
    case 'customs-payments':
      return (
        <CustomsPayments paymentData={Array.isArray(serviceData) ? serviceData : [serviceData]} />
      );
    default:
      return (
        <AdditionalService
          product={product}
          langId={langId}
          currencyCode={currencyCode}
          orderCurrency={orderCurrency}
        />
      );
  }
};

// Экспортируем компонент
export default ServicesSummaryItem;
