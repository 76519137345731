import TextField from '@mui/material/TextField';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { OverridableStringUnion } from '@mui/types';
import { FieldState } from '@pages/AdditionalServices/context/types';
import { useDebounceStream } from '@settings/core/stateContexts/useDebounceStream';
import React, { useEffect, useState } from 'react';

import { PropsGetField } from './GetFields';

const NumberField = (props: PropsGetField) => {
  const {
    field,
    setState,
    state,
    withLabel = true,
    realTimeValidation = false,
    size = 'medium',
  } = props;
  const value = state[field.code] ? state[field.code]?.value : field.value;
  const hasError = realTimeValidation ? value === undefined || value === null : false;
  const [debounceValue, simpleValue, setDebounceValue] = useDebounceStream<number>(value);
  const [firstRender, setFirstRender] = useState<boolean>(false);

  useEffect(() => {
    if (firstRender && state[field.code]?.value) {
      setFirstRender(true);
      return;
    }

    setState((prevState: FieldState) => ({
      ...prevState,
      [field.code]: {
        ...value[field.code],
        value: debounceValue,
      },
    }));
  }, [debounceValue]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/; // Регулярное выражение для чисел (целых и с плавающей точкой);

    if (value === '0' || value === '') {
      setDebounceValue(0);
      return;
    }

    if (!regex.test(value)) {
      // Если введенное значение не соответствует шаблону и не пустое, игнорируем его
      return;
    }

    setDebounceValue(Number(value));
  };

  return (
    <TextField
      fullWidth
      size={size as OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>}
      error={hasError}
      label={withLabel ? field.name : ''}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      value={simpleValue}
      onChange={onChange}
    />
  );
};

export default NumberField;
