import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomsPaymentProducts } from '../../context/types';
import { CustomsPaymentTotalContainer } from '../CustomsPaymentTotal/StyledComponents';
import { SummaryItemStyledContainer } from './StyledComponents';

interface CustomsPaymentsDetailingTotalProps {
  products: CustomsPaymentProducts;
}

function CustomsPaymentsDetailingTotal({ products }: CustomsPaymentsDetailingTotalProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const totalProducts = products.reduce(
    (prev, product) => {
      const { antiDumpingSum = 0, exciseSum = 0, feeSum = 0, vatSum = 0 } = product.total;
      return {
        feeSum: prev.feeSum + feeSum,
        exciseSum: prev.exciseSum + exciseSum,
        antiDumpingSum: prev.antiDumpingSum + antiDumpingSum,
        vatSum: prev.vatSum + vatSum,
      };
    },
    {
      feeSum: 0,
      exciseSum: 0,
      antiDumpingSum: 0,
      vatSum: 0,
    }
  );

  const isCalulationValid = Object.values(totalProducts).every((price) => isFinite(price));
  const totalResult = Object.values(totalProducts).reduce((prev, total) => prev + total, 0);

  if (!isCalulationValid) {
    return <></>;
  }

  return (
    <div>
      <Typography variant={'h2'} className="heading">
        {t('CustomsPayments:AllProductsTotalTitle')}
      </Typography>
      <CustomsPaymentTotalContainer className={'cargo-desc'}>
        <ul className="summary-list">
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsDuty')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.feeSum.toFixed(2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsExcise')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.exciseSum.toFixed(2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:AntidumpingDuty')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.antiDumpingSum.toFixed(2)} RUB
            </Typography>
          </li>
          <li className="summary-item --currency-detail">
            <Typography variant={'body1'} className={'summary-item__title'}>
              {t('CustomsPayments:CustomsVAT')}:
            </Typography>
            <Typography variant={'body1'} whiteSpace={'nowrap'}>
              {totalProducts.vatSum.toFixed(2)} RUB
            </Typography>
          </li>
        </ul>
        <SummaryItemStyledContainer>
          <span>
            {isMobile
              ? t('CustomsPayments:TotalProductsPriceMobile')
              : t('CustomsPayments:TotalProductsPriceDesktop')}
            :
          </span>
          <span className={'value'}>{totalResult.toLocaleString()} RUB</span>
        </SummaryItemStyledContainer>
      </CustomsPaymentTotalContainer>
    </div>
  );
}

export { CustomsPaymentsDetailingTotal };
