import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { customShadows } from '@assets/theme/shadows';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CartAsideBlockStyledContainer = styled(Box)`
  position: sticky;
  top: 20px;
  border-radius: 18px;
  background-color: ${palette.light.common.white};
  box-shadow: ${customShadows.light.z20};
  padding: 30px 25px;

  @media (max-width: ${breakpoints.values.xsm}px) {
    padding: 12px 30px 20px;
    border-radius: 20px 20px 0 0;
  }

  .controls {
    display: grid;
    gap: 30px;
    position: relative;

    @media (max-width: ${breakpoints.values.xsm}px) {
      gap: 20px;
    }
  }

  .button-group {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .links {
    display: grid;
    grid-gap: 10px;
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    transition: color 200ms ease;
    gap: 10px;
    color: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;

    svg {
      transition: color 200ms ease;
      font-size: 22px;
    }

    &:hover {
      background-color: transparent;
      color: ${palette.light.grey[400]};
    }
  }

  .cart-summary {
    display: grid;
    gap: 30px;

    @media (max-width: ${breakpoints.values.xsm}px) {
      gap: 20px;
    }

    &__header {
      display: grid;
      gap: 5px;
      color: ${palette.light.common.black};

      @media (max-width: ${breakpoints.values.xsm}px) {
        gap: 0;
      }
    }

    &__title {
      font-size: 14px;
    }

    &__list {
      display: grid;
      gap: 15px;

      &-item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        p:nth-of-type(2) {
          align-self: flex-end;
        }          


        @media (max-width: ${breakpoints.values.xsm}px) {
          flex-flow: column wrap;
          align-items: flex-start;

          p:nth-of-type(2) {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 146.6%; /* 23.456px */
            letter-spacing: 0.32px;
          }
        }
      }
    }

    &__sub-list {
      .cart-summary {
        &__list-item {
          flex-direction: row;

            &.--currency-item {
               justify-content: flex-end;
            }
        }
      }
    }
  }
`;

export default CartAsideBlockStyledContainer;
