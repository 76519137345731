import FullLogoIcon from '@components/icons/FullLogoIcon';
import { Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomsPayments } from '../../context';
import { CustomsPaymentsDetailingHeader } from './CustomsPaymentsDetailingHeader';
import { CustomsPaymentsDetailingMain } from './CustomsPaymentsDetailingMain';
import { CustomsPaymentsDetailingOverall } from './CustomsPaymentsDetailingOverall';
import { CustomsPaymentsDetailingProductHelp } from './CustomsPaymentsDetailingProductHelp';
import { CustomsPaymentsDetailingResult } from './CustomsPaymentsDetailingResult';
import { CustomsPaymentsDetailingTotal } from './CustomsPaymentsDetailingTotal';
import { CustomsPaymentsDetailingStyledContainer } from './StyledComponents';

interface CustomsPaymentsDetailingProps {
  isOpen: boolean;
  onCloseDetailing: () => void;
}

function CustomsPaymentsDetailing({ isOpen, onCloseDetailing }: CustomsPaymentsDetailingProps) {
  const { t } = useTranslation(['CustomsPayments']);
  const { useBaseCustomsPaymentData, useCustomsPaymentProducts, useBaseCustomsPaymentConfig } =
    useCustomsPayments();
  const [products] = useCustomsPaymentProducts().useState();
  const [{ countries, currencies }] = useBaseCustomsPaymentData().useState();
  const [{ customsMode, contractCurrency, borderDelivery, otherExpenses }] =
    useBaseCustomsPaymentConfig().useState();

  const CUSTOMS_MODES = [
    {
      value: '1',
      label: t('CustomsPayments:Import'),
    },
    {
      value: '2',
      label: t('CustomsPayments:Export'),
    },
  ];

  const formattedCustomsMode =
    customsMode === '1' ? CUSTOMS_MODES[0].label : CUSTOMS_MODES[1].label;

  return (
    <CustomsPaymentsDetailingStyledContainer
      fullWidth
      maxWidth={'lg'}
      open={isOpen}
      onClose={onCloseDetailing}
      scroll={'body'}
    >
      <Box className="close-button">
        <IconButton onClick={onCloseDetailing}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      {products && (
        <Suspense fallback={<LinearProgress />}>
          <div className="customs-payments-wrap">
            <div className="logo">
              <FullLogoIcon />
            </div>
            <CustomsPaymentsDetailingHeader />
            <CustomsPaymentsDetailingOverall
              customsRegime={formattedCustomsMode}
              contractCurrency={contractCurrency?.encode}
              borderDelivery={borderDelivery}
              otherExpenses={otherExpenses}
            />
            {products.map((product, index) => (
              <React.Fragment key={'product-detailing-' + index}>
                <CustomsPaymentsDetailingMain
                  product={product}
                  index={index}
                  currencies={currencies}
                  countries={countries}
                  contractCurrency={contractCurrency}
                  isExport={customsMode === '2'}
                />
                <CustomsPaymentsDetailingResult
                  isExport={customsMode === '2'}
                  isSpecialFieldsVisible={true}
                  product={product}
                  borderDelivery={borderDelivery}
                  otherExpenses={otherExpenses}
                  currencies={currencies}
                  contractCurrency={contractCurrency}
                />
              </React.Fragment>
            ))}
            <CustomsPaymentsDetailingTotal products={products} />
            <CustomsPaymentsDetailingProductHelp />
          </div>
        </Suspense>
      )}
    </CustomsPaymentsDetailingStyledContainer>
  );
}

export { CustomsPaymentsDetailing };
