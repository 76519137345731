import { TabsItemProps } from '@pages/AdditionalServices/context/types';

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: 'root-services-tab__tab',
  };
}

export const getLocalizedNames = (names: TabsItemProps, primaryLanguageId: string) => {
  return names.localized_names.find((n) => n.lang_id === primaryLanguageId)?.message || names.name;
};
