import getEnv from '@settings/getEnv';
import { RetryLink as ApolloRetryLink } from "apollo-link-retry";

const { REACT_APP_MAX_REFETCH_CALCULATION_NUMBER } = getEnv();

export const RetryLink = new ApolloRetryLink({
  delay: (count) => {
    return count * 1000;
  },
  attempts: (count) => {
	if (count <= REACT_APP_MAX_REFETCH_CALCULATION_NUMBER) {
		return true;
	}

	return false;
  }
});