import { ServicesProps } from '../../../../../../../../../../../services/requests/servicesService/servicesServicesService/interface';
import { BasketAdditionalService } from '../../../../../../../../../context/types';
import { getColumnsForAdditionalService } from './DataForTableGenerator';
import { getRowsForTemplate5 } from './RowGeneratorTemplate5';

export const getChildrenGridDataTemplate5 = (
  id: string,
  service: ServicesProps,
  basket: BasketAdditionalService,
  template: string
) => {
  const rows = getRowsForTemplate5(service, basket[id]);
  const columns = getColumnsForAdditionalService(service, template);

  return [{ rows, columns }];
};
