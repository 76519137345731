import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatPrice } from '@pages/OfferDetail/helpers';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

// AdditionCostCaptionProps описывает свойства компонента вывода
// заголовочной части доп услуг перевозки
type AdditionServiceCaptionProps = {
  title: string;
  cost: number;
  targetCurrency: CurrencyData;
};

// Компонент вывода заголовочной части доп услуг перевозки
const AdditionServiceCaption = ({ cost, targetCurrency, title }: AdditionServiceCaptionProps) => {
  return (
    <div className={'offer-group-header offer-group-header__service'}>
      <Box className="offer-group-header__title">
        <Typography variant={'subtitle1'} component={'div'}>
          {title}
        </Typography>
      </Box>
      <Typography variant={'subtitle1'} className={'price'} component={'div'}>
        {formatPrice(cost)} {targetCurrency.code}
      </Typography>
    </div>
  );
};

export default AdditionServiceCaption;
