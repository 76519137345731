import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #dbe0ec;
  border-radius: 8px;

  @media (min-width: ${breakpoints.values.xsm}px) {
    padding: 30px;
  }

  .product-header-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .main-config-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
    }
  }

  .origin-certificate {
    margin-top: 20px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      margin-top: 5px;
    }
  }

  .get-rates-btn {
    max-width: none;
    width: 100%;
    text-align: center;
    height: 45px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 10px;
    border-radius: 8px;
    margin-top: 30px;
    color: ${({ theme }) => theme.palette.secondary.main};

    @media (min-width: ${breakpoints.values.xsm}px) {
      max-width: 216px;
      margin-top: 10px;
    }
  }

  .product-help-expand {
    width: 100%;
    max-width: none;
    margin-top: 0;

    @media (min-width: ${breakpoints.values.xsm}px) {
      margin-top: 10px;
    }
  }

  .additional-tax-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      flex-direction: row;
    }
  }

  .additional-tax-item {
    width: 100%;
    margin-right: 32px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: ${breakpoints.values.xsm}px) {
      width: 33%;
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0px;
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: 0.02em;
    }

    &__body {
      margin-top: 12px;
    }
  }

  .additional-tax-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
  }

  .radio-wrap {
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      margin-bottom: 0;
    }
  }

  .radio {
    svg {
      fill: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }

  .radio-label {
    color: #707070;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .result-wrap {
    display: grid;
    gap: 20px;
    margin-top: 40px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      gap: 60px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .result-item {
    width: 100%;

    &__row {
      display: flex;
      flex-direction: column;
      gap: 0px;
      margin-bottom: 10px;

      @media (min-width: ${breakpoints.values.xsm}px) {
        flex-direction: row;
        gap: 60px;
        margin-bottom: 0px;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;

      @media (min-width: ${breakpoints.values.xsm}px) {
        margin-bottom: 0;
      }

      .MuiInput-input {
        color: rgb(56, 56, 56) !important;
        text-align: right !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 22px !important;
        padding: 4px 5px;
      }
      /* margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0px;
      } */
    }

    &__input {
      margin-bottom: 20px;
    }

    &__cost {
      margin-bottom: 10px;
    }

    &__cost-value {
      text-align: start;
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;

      @media (min-width: ${breakpoints.values.xsm}px) {
        text-align: end;
      }
    }

    &__cost-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
    }

    .result-item__table {
      width: 100%;
      border: 1px solid #dbe0ec;
      border-radius: 20px;
      overflow: hidden;

      .MuiDataGrid-virtualScrollerRenderZone {
        width: 100%;
      }

      .MuiDataGrid-columnHeadersInner > div {
        width: 100%;
      }

      &:not(.default) {
        .MuiDataGrid-row,
        .MuiDataGrid-virtualScrollerRenderZone > div,
        .MuiDataGrid-columnHeadersInner > div {
          width: 100% !important;
          display: grid !important;
          grid-template-columns:
            1fr
            1fr
            1fr
            1fr
            0 !important;
        }

        .MuiDataGrid-row .MuiDataGrid-cell {
          line-height: normal;
          width: unset !important;
          max-width: unset !important;
          min-width: unset !important;

          &:nth-of-type(n + 2) {
            padding: 10px 5px;
          }
        }

        .MuiDataGrid-row.MuiDataGrid-row--lastVisible {
          grid-template-columns:
            2fr
            1fr
            1fr
            0
            0 !important;
        }

        .MuiDataGrid-root .MuiDataGrid-columnHeader {
          padding: 0 5px;
          width: unset !important;
          max-width: unset !important;
          min-width: unset !important;
          color: ${({ theme }) => theme.palette.grey[900]};
        }

        .MuiDataGrid-root {
          border-radius: 0;
          border-bottom: 0;
        }
      }

      .MuiDataGrid-root .MuiDataGrid-columnHeader,
      .MuiDataGrid-root .MuiDataGrid-cell {
        padding: 10px 15px 10px 20px;
        justify-content: center;
        align-items: center;
      }
      .MuiDataGrid-columnHeadersInner {
        width: 100%;
      }

      .MuiDataGrid-root .MuiDataGrid-columnHeaders,
      .MuiDataGrid-root .MuiDataGrid-columnHeader {
        width: 100%;
        line-height: normal !important;
        border-radius: 0;
      }
      .MuiDataGrid-root .MuiDataGrid-columnHeader > div {
        width: 100%;
      }
      .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child {
        border-right: none !important;
      }

      .MuiDataGrid-root .MuiDataGrid-columnSeparator {
        display: none;
      }

      .MuiDataGrid-root .MuiDataGrid-withBorderColor {
        .MuiDataGrid-cellContent {
          color: black;
        }
      }

      .MuiDataGrid-columnHeaderTitle {
        text-overflow: initial;
        white-space: normal;
        text-align: center;
      }

      .total-text {
        font-weight: 600;
      }

      .MuiDataGrid-root {
        border: none;
      }

      .MuiDataGrid-root .MuiDataGrid-columnSeparator {
        display: none;
      }

      .customs-payments-table--last-cell.MuiDataGrid-cell--withRightBorder {
        border-right: 0;
      }

      .MuiDataGrid-columnHeaders {
        background: #eff3fb;
      }

      .MuiDataGrid-cell--withRightBorder {
        &:last-child {
          border-right: none;
        }
      }

      .MuiDataGrid {
        &-row,
        &-virtualScrollerRenderZone {
          min-width: 100%;
        }
        &-columnHeader {
          &:last-child {
            border-right: 0;
          }
        }
      }

      .MuiDataGrid-row:last-child .MuiDataGrid-cell {
        border-bottom: 0;
      }
      .default-table-container__last-cell.MuiDataGrid-cell--withRightBorder {
        border-right: 0;
      }
    }
  }

  .product-help-wrap {
    margin-top: 20px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      margin-top: 70px;
    }
  }

  .product-help-control {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    @media (min-width: ${breakpoints.values.xsm}px) {
      justify-content: flex-start;
      margin-right: 20px;
    }
  }

  .product-help-control-btn {
    display: flex;
    align-items: center;
    margin-right: 0px;
    cursor: pointer;
    color: ${palette.light.blue[400]};
    border-bottom: 1px solid transparent;
    transition: 400ms;
    font-size: 14px;

    svg {
      color: ${palette.light.blue[400]};
    }

    @media (min-width: ${breakpoints.values.xsm}px) {
      margin-right: 20px;
      font-size: 12px;
    }

    &:hover {
      color: #224179;
      border-bottom: 1px solid ${palette.light.blue[400]};
    }
  }

  .product-help-list {
    margin-top: 20px;
  }

  .product-item {
    background: #fff;
    border-radius: 18px;
    box-shadow: 0px 0px 10px 0px #0005730d;

    padding: 20px;
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.values.xsm}px) {
      padding: 6px 50px 6px 36px;
    }

    &__notes-wrap {
      display: flex;
      flex-direction: column;
    }

    &__header {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 23.46px;
      letter-spacing: 0.02em;
      color: #000;
    }

    &__body {
      display: none;
      padding: 20px 0;

      @media (min-width: ${breakpoints.values.xsm}px) {
        padding: 20px 70px 30px 70px;
      }

      &.expanded {
        display: block;
      }
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 23.46px;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
      color: #000;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
