import { Filter } from '@services/types';
import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Параметры для получения дополнительной услуги по фильтрам
 */
export interface LoadLocationsParams {
  limit?: number;
  offset?: number;
  where?: {
    default_name?: Filter;
    id?: Filter;
  };
}

/**
 * Запрос загрузки данных по локациям
 */
export class LocationsLoaderByParamsQuery implements GraphQLQuery<LoadLocationsParams> {
  readonly query: any;

  readonly variables: LoadLocationsParams;

  constructor(params: LoadLocationsParams) {
    this.variables = {
      ...params,
    };
    this.query = gql`
      query LoadLocations(
        $limit: Int = 60
        $offset: Int = 0
        $where: locations_query_where_object
      ) {
        items: locations_list(
          limit: $limit
          offset: $offset
          where: $where
          order: [{ by: id, direction: desc, priority: 1 }]
        ) {
          id
          default_name
          localized_names
          symbol_code
        }
      }
    `;
  }
}
